import React from "react";
import "./page-requisites-tabs.scss";

type PageRequisitesTabsProps = {
  children: React.ReactNode;
};


export const PageRequisitesTabs: React.FC<PageRequisitesTabsProps> = ({
  children,
}) => (
  <div className="page-requisites-tabs">
    {children}
  </div>
);