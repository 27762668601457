import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { IRequisite } from "../../models/IRequisite"

export interface ICountsByFilterType {
    numberDeleted: string,
    numberActive: string,
    numberOff: string
}
type requestDataType = {
    data: IRequisite[],
    countsByFilter: ICountsByFilterType
}
const initialState: requestDataType = {
    data: [],
    countsByFilter: {
        numberDeleted: "0",
        numberActive: "0",
        numberOff: "0"
    }
}

export const requestDataSlice = createSlice({
    name: "requestData",
    initialState,
    reducers: {
        updateData: (state, action) => {
            state.data = action.payload
        },
        updateCountsByFilter: (state, action) => {
            state.countsByFilter = action.payload
        },
    }
})

export const { updateData, updateCountsByFilter } = requestDataSlice.actions

export const requestData = (state: RootState) => state.requestData.data
export const requestCountsByFilter = (state: RootState) => state.requestData.countsByFilter

export default requestDataSlice.reducer