import React from "react";
import "./icon-text.scss";
import classNames from "classnames";

type IconTextProps = {
  children?: React.ReactNode;
  gap?: Gap;
  align?: Align;
  sizeIcon?: SizeIcon;
};

type Gap = "esm" | "sm" | "m" | "lg" | "xl" | "2xl";
type Align = "center" | "start" | "end" | "normal";
type SizeIcon = "base" | "sm" | "md" | "normal";

export const IconText: React.FC<IconTextProps> = ({
  gap = "m",
  children,
  align = "center",
  sizeIcon = "base",
}) => (
  <div className={`icon-text ${classNames(
    gap ? `icon-text--gap-${gap}` : "",
    align ? `icon-text--align-${align}` : "",
    sizeIcon ? `icon-text--size-${sizeIcon}` : "",
  )}`}>
    {children}
  </div>
);
