import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ICurrency } from "../../models/ICurrency";
import { IDetailFields } from "../../models/IDetailFields";
import { IMyRequisitesFilterComponents } from "../../models/IMyRequisitesFilterComponents";
import { IOptionsDetailsFields, IPaySystem } from "../../models/IPaySystem";
import { IRequisite } from "../../models/IRequisite";
import { IReservePS, IReserves } from "../../models/IReserves";

export interface IPaySystemRedux {
  list: { [index: string]: IPaySystem };
  fields: { [index: string]: IDetailFields[] };
  mobileOperators: { [index: string]: { id: number; name: string } };
  activePaySystems: { [index: string]: boolean };
  optionsDetailsFields: { [index: string]: IOptionsDetailsFields[] };
  deposit: boolean;
  data: IRequisite;
  filterComponents: IMyRequisitesFilterComponents;
  currency: ICurrency[];
  globalCommission: { deposit: number; withdraw: number };
  globalPercentDepositP2P: number,
  globalPercentWithdrawP2P: number;
  baseCurrency?: ICurrency;
  baseCurrencyList: ICurrency[];
  getMatchingReserves: {
    paymentsSystems: IReservePS;
    reserves: IReserves
  };
  withdrawEnabled: boolean,
  depositEnabled: boolean,
}

const initialState: IPaySystemRedux = {
  list: {},
  fields: {},
  mobileOperators: {},
  activePaySystems: {},
  deposit: true,
  optionsDetailsFields: {},
  filterComponents: {
    orderType: "2",
    filterType: "1",
    start: "0",
    paymentSystemId: "",
    length: "25",
  },
  currency: [],
  globalCommission: { deposit: 0, withdraw: 0 },
  globalPercentDepositP2P: 0,
  globalPercentWithdrawP2P: 0,
  // baseCurrency: ,
  baseCurrencyList: [],
  withdrawEnabled: true,
  depositEnabled: true,
  data: {
    amountLimit: 10000000,
    amountLimitDay: 330000,
    countLimit: 50,
    countLimitShort: 6,
    amountMin: 100,
    amountMax: 150000,
    priority: 0,
    publicDetails: {},
    id: 0,
    name: "",
    amount: 0,
    amountDay: 0,
    count: 0,
    countShort: 0,
    currencyId: 0,
    deleted: false,
    enable: true,
    exhausted: false,
    fromUserLevel: 0,
    orderType: 2,
    paymentSystemId: 0,
    percent: 0,
    referenceTo: null,
    sumOnAccount: 0,
    timeCreate: 0,
    timeout: 900,
    userEnable: true,
    percentWithdrawP2P: 0,
    percentDepositP2P: 0,
  },
  getMatchingReserves: {
    paymentsSystems: {},
    reserves: {}
  }
};

export const paySystemsSlice = createSlice({
  name: "paySystems",
  initialState,
  reducers: {
    savePaySystems: (state, action) => {
      state.list = action.payload.list;
      state.fields = action.payload.fields;
      state.mobileOperators = action.payload.mobileOperators;
      state.globalCommission = action.payload.globalCommission;
      state.globalPercentDepositP2P = action.payload.globalPercentDepositP2P;
      state.globalPercentWithdrawP2P = action.payload.globalPercentWithdrawP2P;
      state.optionsDetailsFields = action.payload.optionsDetailsFields;
      state.depositEnabled = action.payload.depositEnabled;
      state.withdrawEnabled = action.payload.withdrawEnabled;
      const activeSystem: { [index: string]: boolean } = {};
      for (const key in action.payload.list) {
        activeSystem[key] = false;
      }
      if (action.payload.paymentSystemId) {
        state.activePaySystems = {
          ...activeSystem,
          [action.payload.paymentSystemId]: true,
        };
      } else {
        state.activePaySystems = activeSystem;
      }
    },
    changeData: (state, action) => {
      state.data = action.payload;
    },
    clearData: (state) => {
      state.data = initialState.data;
    },
    changeOrderType: (state, action) => {
      state.data.orderType = action.payload

    },
    changeDataPaymentSystemId: (state, action) => {
      state.data.paymentSystemId = action.payload;
    },
    changeDeposit: (state, action) => {
      state.deposit = action.payload;
    },
    changeFilterComponents: (state, action) => {
      state.filterComponents = action.payload;
    },
    changeOptionsDetailsFields: (state, action) => {
      state.optionsDetailsFields = action.payload;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
    },
    changeBaseCurrency: (state, action) => {
      state.baseCurrency = action.payload;
    },
    changeBaseCurrencyList: (state, action) => {
      state.baseCurrencyList = action.payload;
    },
    changeReserves: (state, action) => {
      state.getMatchingReserves = action.payload.data
    },
    changeGlobalPercentDepositP2P: (state, action) => {
      state.globalPercentDepositP2P = action.payload
    },
    changeGlobalPercentWithdrawP2P: (state, action) => {
      state.globalPercentWithdrawP2P = action.payload
    },
    depWithEnableChange: (state, action) => {
      state.depositEnabled = action.payload.depositEnabled
      state.withdrawEnabled = action.payload.withdrawEnabled
    }
  },
});

export const {
  savePaySystems,
  changeData,
  clearData,
  changeOrderType,
  changeDeposit,
  changeFilterComponents,
  changeDataPaymentSystemId,
  changeOptionsDetailsFields,
  changeCurrency,
  changeBaseCurrency,
  changeBaseCurrencyList,
  changeReserves,
  changeGlobalPercentDepositP2P,
  changeGlobalPercentWithdrawP2P,
  depWithEnableChange
} = paySystemsSlice.actions;

export const paySystemList = (state: RootState) => state.paySystems.list;
export const paySystemFields = (state: RootState) => state.paySystems.fields;
export const paySystemMobileOperators = (state: RootState) =>
  state.paySystems.mobileOperators;
export const paySystemActive = (state: RootState) =>
  state.paySystems.activePaySystems;
export const paySystemData = (state: RootState) => state.paySystems.data;
export const paySystemDeposit = (state: RootState) => state.paySystems.deposit;
export const paySystemFilterComponents = (state: RootState) =>
  state.paySystems.filterComponents;
export const paySystemOptionsDetailsFields = (state: RootState) =>
  state.paySystems.optionsDetailsFields;
export const paySystemCurrency = (state: RootState) =>
  state.paySystems.currency;
export const paySystemBaseCurrency = (state: RootState) =>
  state.paySystems.baseCurrency;
export const paySystemBaseCurrencyList = (state: RootState) =>
  state.paySystems.baseCurrencyList;
export const paySystemGlobalCommission = (state: RootState) =>
  state.paySystems.globalCommission;
export const paySystemGlobalPercentDepositP2P = (state: RootState) =>
  state.paySystems.globalPercentDepositP2P;
export const paySystemGlobalPercentWithdrawP2P = (state: RootState) =>
  state.paySystems.globalPercentWithdrawP2P;
export const paySystemReserves = (state: RootState) => state.paySystems.getMatchingReserves;
export const depositEnabled = (state: RootState) => state.paySystems.depositEnabled;
export const withdrawEnabled = (state: RootState) => state.paySystems.withdrawEnabled;

export default paySystemsSlice.reducer;
