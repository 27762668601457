import React, { useContext, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ListForm, ListFormItem } from "../../ListForm";
import { ButtonBase, ButtonOutline } from "../../UI/Button";
import { Input } from "../../UI/Input";
import { ButtonText } from "../../UI/Text";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper";
import MyApplication from "../../../services/MyApplicationService";
import { toast } from "react-toastify";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ParamContext } from "../../../context/paramReducer";
import {
  FormModal,
  FormModalBody,
  FormModalFooter,
} from "../../Form/FormModal";
import { IconBasicTrashFull } from "../../UI/Icon";

type ModalChangeClientNameProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
  fio: string;
  usersPhoneId: number;
  paymentsSystemId: number;
  orderId: number;
  getData: () => void;
};

export const ModalChangeClientName: React.FC<ModalChangeClientNameProps> = ({
  isOpen,
  title,
  size,
  fio,
  usersPhoneId,
  paymentsSystemId,
  orderId,
  getData,
}) => {
  const { onDismiss } = useContext(ModalContext);
  const { lang } = useContext(ParamContext);
  const [newFio, setNewFio] = useState<string>(fio);
  const langConstants = useAppSelector(languageConstants);

  const sendHandler = () => {
    onDismiss();
    toast.promise(
      MyApplication.insertPhoneFio(
        newFio,
        usersPhoneId,
        paymentsSystemId,
        lang
      ).then((res) => {
        if (res.data.type === "success") {
          getData();
          return res.data.text;
        } else {
          throw res.data.text;
        }
      }),
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data;
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      }
    );
  };
  const resetHandler = () => {
    onDismiss();
    toast.promise(
      MyApplication.resetPhoneFio(
        orderId,
        lang
      ).then((res) => {
        if (res.data.type === "success") {
          getData();
          return res.data.text;
        } else {
          throw res.data.text;
        }
      }),
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data;
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      }
    );
  }
  const nameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewFio(e.target.value);
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onDismiss} title={title} size={size}>
      <FormModal onSubmit={sendHandler}>
        <FormModalBody>
          <ListForm>
            <ListFormItem>
              <Input
                label={langConstants["L_FIRST_NAME"]}
                name="input_name"
                placeholder={langConstants["L_FIRST_NAME_EMPTY"]}
                type="text"
                value={newFio}
                onChange={nameHandler}
              />
            </ListFormItem>
            <ListFormItem>
              <ButtonOutline onClick={resetHandler}>
                <IconBasicTrashFull />
                <ButtonText>{langConstants["L_DELETE"]}</ButtonText>
              </ButtonOutline>
            </ListFormItem>
          </ListForm>
        </FormModalBody>

        <FormModalFooter>
          <ButtonOutline onClick={onDismiss}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>
              {langConstants["L_LIGHT_WITHDRAW_COUNT_CHANGE"]}
            </ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper>
  );
};
