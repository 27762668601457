import React from "react";
import "./page-reports-options.scss";

type PageReportsOptionsProps = {
  children: React.ReactNode;
};


export const PageReportsOptions: React.FC<PageReportsOptionsProps> = ({
  children,
}) => (
  <div className="page-reports-options">
    {children}
  </div>
);