import React from "react";
import { Icon, Size } from "./Icon";
import IconArrowCaretDownBase from "../../../assets/icon/icon--arrow-caret-down.svg";
import IconArrowCaretUpBase from "../../../assets/icon/icon--arrow-caret-up.svg";
import IconArrowChevronDownBase from "../../../assets/icon/icon--arrow-chevron-down.svg";
import IconArrowChevronLeftBase from "../../../assets/icon/icon--arrow-chevron-left.svg";
import IconArrowChevronRightBase from "../../../assets/icon/icon--arrow-chevron-right.svg";
import IconArrowLongBottomDownBase from "../../../assets/icon/icon--arrow-long-bottom-down.svg";
import IconArrowLongBottomUpBase from "../../../assets/icon/icon--arrow-long-bottom-up.svg";
import IconArrowShortRightBase from "../../../assets/icon/icon--arrow-short-right.svg";
import IconArrowsCircleBase from "../../../assets/icon/icon--arrows-circle.svg";
import IconAttentionInfoCircleOutlineBase from "../../../assets/icon/icon--attention-info-circle-outline.svg";
import IconAttentionInfoCircleBase from "../../../assets/icon/icon--attention-info-circle.svg";
import IconBasicTrashFullBase from "../../../assets/icon/icon--basic-trash-full.svg";
import IconCalendarBase from "../../../assets/icon/icon--calendar.svg";
import IconCheckBase from "../../../assets/icon/icon--check.svg";
import IconCircleCheckBase from "../../../assets/icon/icon--circle-check.svg";
import IconClipBase from "../../../assets/icon/icon--clip.svg";
import IconCloseBase from "../../../assets/icon/icon--close.svg";
import IconCopyBase from "../../../assets/icon/icon--copy.svg";
import IconEditBase from "../../../assets/icon/icon--edit.svg";
import IconExitBase from "../../../assets/icon/icon--exit.svg";
import IconFileBlankBase from "../../../assets/icon/icon--file-blank.svg";
import IconHelpBase from "../../../assets/icon/icon--help.svg";
import IconMenuCloseSmallBase from "../../../assets/icon/icon--menu-close-small.svg";
import IconHamburgerBase from "../../../assets/icon/icon--menu-hamburger.svg";
import IconMessageBase from "../../../assets/icon/icon--message.svg";
import IconNotebookBase from "../../../assets/icon/icon--notebook.svg";
import IconPlusCircleBase from "../../../assets/icon/icon--plus-circle.svg";
import IconSliderBase from "../../../assets/icon/icon--slider.svg";
import IconTelegramBase from "../../../assets/icon/icon--telegram.svg";
import IconUserBase from "../../../assets/icon/icon--user.svg";
import IconWalletBase from "../../../assets/icon/icon--wallet.svg";
import IconDocumentBase from "../../../assets/icon/icon--document.svg";
import IconHistoryBase from "../../../assets/icon/icon--history.svg";
import IconReportBase from "../../../assets/icon/icon--report.svg";
import IconSettingsBase from "../../../assets/icon/icon--settings.svg";
import IconSupportBase from "../../../assets/icon/icon--support.svg";
import IconPlusCircleOutlineBase from "../../../assets/icon/icon--plus-circle-outline.svg";
import IconChatBase from "../../../assets/icon/icon--chat.svg";
import IconMenuBase from "../../../assets/icon/icon--menu.svg";
import IconOutlineReportBase from "../../../assets/icon/icon--outline-report.svg";
import IconObmenBase from "../../../assets/icon/icon--obmen.svg"
import IconNaObmenBase from "../../../assets/icon/icon--na-obmen.svg"
import IconSExchangeBase from "../../../assets/icon/icon--s-exchange.svg"

type IconUIProps = {
  size?: Size;
};
export const IconSExchange: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSExchangeBase} name="icon--s-exchange" size={size} />
);
export const IconNaObmen: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconNaObmenBase} name="icon--na-obmen" size={size} />
);
export const IconObmen: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconObmenBase} name="icon--obmen" size={size} />
);
export const IconOutlineReport: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconOutlineReportBase} name="icon--outline-report" size={size} />
);
export const IconMenu: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconMenuBase} name="icon--menu" size={size} />
);
export const IconChat: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconChatBase} name="icon--chat" size={size} />
);
export const IconPlusCircleOutline: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconPlusCircleOutlineBase} name="icon--plus-circle-outline" size={size} />
);
export const IconSupport: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSupportBase} name="icon--support" size={size} />
);
export const IconSettings: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSettingsBase} name="icon--settings" size={size} />
);
export const IconReport: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconReportBase} name="icon--report" size={size} />
);
export const IconHistory: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconHistoryBase} name="icon--history" size={size} />
);
export const IconDocument: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconDocumentBase} name="icon--document" size={size} />
);
export const IconWallet: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconWalletBase} name="icon--wallet" size={size} />
);
export const IconUser: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconUserBase} name="icon--user" size={size} />
);
export const IconTelegram: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconTelegramBase} name="icon--telegram" size={size} />
);
export const IconSlider: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconSliderBase} name="icon--slider" size={size} />
);
export const IconPlusCircle: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconPlusCircleBase} name="icon--plus-circle" size={size} />
);
export const IconNotebook: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconNotebookBase} name="icon--notebook" size={size} />
);
export const IconMessage: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconMessageBase} name="icon--message" size={size} />
);
export const IconHamburger: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconHamburgerBase} name="icon--menu-hamburger" size={size} />
);
export const IconMenuCloseSmall: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconMenuCloseSmallBase} name="icon--menu-close-small" size={size} />
);
export const IconHelp: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconHelpBase} name="icon--help" size={size} />
);
export const IconFileBlank: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconFileBlankBase} name="icon--file-blank" size={size} />
);
export const IconExit: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconExitBase} name="icon--exit" size={size} />
);
export const IconEdit: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconEditBase} name="icon--edit" size={size} />
);
export const IconCopy: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCopyBase} name="icon--copy" size={size} stroke="primary" fill="none" />
);
export const IconClose: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCloseBase} name="icon--close" size={size} />
);
export const IconClip: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconClipBase} name="icon--clip" size={size} />
);
export const IconCircleCheck: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCircleCheckBase} name="icon--circle-check" size={size} />
);
export const IconCheck: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCheckBase} name="icon--check" size={size} />
);
export const IconCalendar: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCalendarBase} name="icon--calendar" size={size} />
);
export const IconBasicTrashFull: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconBasicTrashFullBase} name="icon--basic-trash-full" size={size} />
);
export const IconAttentionInfoCircle: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconAttentionInfoCircleBase} name="icon--attention-info-circle" size={size} />
);
export const IconAttentionInfoCircleOutline: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconAttentionInfoCircleOutlineBase} name="icon--attention-info-circle-outline" size={size} />
);
export const IconArrowsCircle: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowsCircleBase} name="icon--arrows-circle" size={size} />
);
export const IconArrowShortRight: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowShortRightBase} name="icon--arrow-short-right" size={size} />
);
export const IconArrowLongBottomUp: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowLongBottomUpBase} name="icon--arrow-long-bottom-up" size={size} />
);
export const IconArrowLongBottomDown: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowLongBottomDownBase} name="icon--arrow-long-bottom-down" size={size} />
);
export const IconArrowCaretDown: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowCaretDownBase} name="icon--arrow-caret-down" size={size} />
);
export const IconArrowCaretUp: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowCaretUpBase} name="icon--arrow-caret-up" size={size} />
);
export const IconArrowChevronDown: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowChevronDownBase} name="icon--arrow-chevron-down" size={size} />
);
export const IconArrowChevronLeft: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowChevronLeftBase} name="icon--arrow-chevron-left" size={size} />
);
export const IconArrowChevronRight: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconArrowChevronRightBase} name="icon--arrow-chevron-right" size={size} />
);

