import axios from "axios";
import { APP_ENV } from "../app/enviroment";
import Auth from "../services/AuthService";

const defaultApi = window.location.origin.replace(/in./, "lk.") 

export const API_URL = APP_ENV.REACT_APP_API_URL || defaultApi

export const api = axios.create({
  baseURL: API_URL,
});
export const apiNoToken = axios.create({
  baseURL: API_URL,
});

let promise: Promise<any> | null = null;

async function refreshPromise(): Promise<any> {
  if (promise === null) {
    promise = new Promise(async (resolve, reject) => {
      const response = await Auth.getNewToken("ru")
      if(response.data.data){
        localStorage.setItem('accessToken', response.data.data.accessToken)
        localStorage.setItem('refreshToken', response.data.data.refreshToken)
        resolve(response)
      }else{
        reject(response.data)
      }
    }).then((data) => {
      promise = null
      return data
    }).catch(err => console.log(err))
  }
  return promise
}
api.interceptors.request.use(async (config) => {
  if (config.headers === undefined) config.headers = {}
  const token = localStorage.getItem('accessToken')
  if(token){
    config.headers["access-token"] = token
    config.headers["Cache-Control"] = 'no-cache'
    config.headers["Pragma"] = 'no-cache'
    config.headers["Expires"] = '0'
  }else {
    await refreshPromise()
  const secondToken = localStorage.getItem('accessToken')
  config.headers["access-token"] = secondToken ? secondToken : ""
  }
  return config;
}, async (error) => {
  const originalRequest = error.config
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    try {
      const ok = await refreshPromise()
      if (ok) {
        originalRequest._isRetry = true;
        return api.request(originalRequest);
      }
    } catch (e) {
      console.log('НЕ АВТОРИЗОВАН')
    }
  }
})

api.interceptors.response.use(async function (response) {
  if (response.data.errorCode === "401") {
    const ok = await refreshPromise()
    if (ok) {
      return api.request(response.config);
    }
  }
  return response
}, function (error) {
  console.log(error);
  return error
})



