import React from "react";
import "./switcher.scss";
import classNames from "classnames";
import { ButtonText } from "../Text";
import { IconArrowLongBottomDown, IconArrowLongBottomUp } from "../Icon";

type SwitcherProps = {
  active?: boolean;
  activeTwo?: boolean;
  onClick: ()=> void;
  arrow?: boolean;
  firstButton: string;
  secondButton: string
};


export const Switcher: React.FC<SwitcherProps> = ({
  active,
  activeTwo = false,
  onClick,
  arrow = false,
  firstButton,
  secondButton
}) => {

  return (
  <div className="switcher" onClick={onClick}>
    <button className={`switcher__button ${classNames(active ? "switcher__button--active" : "")}`} type="button">
      {arrow && <IconArrowLongBottomDown />}
      <ButtonText>{firstButton}</ButtonText>
    </button>
    <button className={`switcher__button ${classNames(activeTwo ? "switcher__button--active" : "")}`} type="button">
      {arrow && <IconArrowLongBottomUp />}
      <ButtonText>{secondButton}</ButtonText>
    </button>
  </div>
)};