import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Body } from "../components/Body";
import { Main } from "../components/Main";
import { Header } from "../components/Header"
import { Sidebar } from "../components/Sidebar";
import { PageMyApplications } from "./PageMyApplications";
import { PageRequisites } from "./PageRequisites/PageRequisites";
import { PageReports } from "./PageReports";
import { PageSupport } from "./PageSupport";
import { PageHistoryOperation } from "./PageHistoryOperation";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ParamContext } from "../context/paramReducer";
import { toast, ToastContainer } from "react-toastify";
import MyRequistes from "../services/MyRequisitesService";
import { updateCountsByFilter, updateData } from "../features/requisit/requestDataSlice";
import { changeBaseCurrencyList, changeCurrency, changeReserves, paySystemFilterComponents, savePaySystems } from "../features/requisit/paySystemsSlice";
import { IMyRequisitesGlobeRequest } from "../models/IMyRequisitesGlobeRequest";
import { AxiosResponse } from "axios";
import { ModalLouder } from "../components/Modal/ModalLouder";
import Auth from "../services/AuthService";
import { PageCreateAd } from "./PageCreateAd";
import { IMyRequisitesFilterComponents } from "../models/IMyRequisitesFilterComponents";
import CommonUpdater from "../services/CommonUpdaterService";
import { ICurrency } from "../models/ICurrency";
import useSound from 'use-sound';
import textMessageSound from '../../src/data/sounds/text_message.mp3';
import useModal from "../hooks/useModal";
import { ModalRelocation } from "../components/Modal/ModalRelocation";
import { languageConstants, updateConstants } from "../features/settings/settingsSlice";
import Language from "../services/LanguageServices";

type PageMainProps = {

};

function baseCurrencyFilter(arr: ICurrency[]) {
  const answer = arr.filter(el => (el.enabled && el.type === 1))
  return answer
}

export const PageMain: React.FC<PageMainProps> = () => {
  const { sideBarOpen, lang, loader, setLoader } = useContext(ParamContext)
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true)
  const filterComponentsState = useAppSelector(paySystemFilterComponents)
  const willMount = useRef(true)
  const [messageNumber, setMessageNumber] = useState(0)
  const [preMessageNumber, setPreMessageNumber] = useState(0)
  const [activeOrders, setActiveOrders] = useState(0)
  const [checkAuth, setCheckAuth] = useState<boolean>(false)
  const [countRequisites, setCountRequisites] = useState(1)
  const [play] = useSound(textMessageSound, { playbackRate: 1 })
  const langConstants = useAppSelector(languageConstants);

  useEffect(() => {
    document.title = Object.keys(langConstants).length > 0 ?
      langConstants["L_PROJECT_NAME"] + " - " + langConstants["L_PAGE_INDEX_TITLE"] : "Matbea"
  }, [langConstants])

  const [relocationModalOpen] = useModal(
    (<ModalRelocation isOpen={false} title="Нет Авторизации" size="sm" />
    ), true, false)

  useEffect(() => {
    if (messageNumber > 0 && messageNumber > preMessageNumber) {
      play()
    }
    setPreMessageNumber(messageNumber)
  }, [messageNumber, play, preMessageNumber])

  const getLanguageConstants = useCallback((lang: "en" | "ru") => {
    toast.promise(Language.getConstants(lang)
      .then(res => {
        if (res.data.type === "success") {
          dispatch(updateConstants(res.data.message))
          setLoader(false)
        } else {
          throw res.data.text
        }
      })
      ,
      {
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }, [dispatch, setLoader])

  useEffect(() => {
    if (!willMount.current) {
      getLanguageConstants(lang)
    }
  }, [getLanguageConstants, lang])

  useEffect(() => {
    const refresh = window.location.search.replace(/\?refresh=/, "")
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (refresh) {
      localStorage.setItem("refreshToken", refresh)
      toast.promise(Auth.getNewToken("ru")
        .then(res => {
          if (res.data.type === "success") {
            localStorage.setItem('accessToken', res.data.data.accessToken)
            localStorage.setItem('refreshToken', res.data.data.refreshToken)
            window.history.replaceState(null, "", "/")
            setCheckAuth(true)
          } else {
            relocationModalOpen()
            throw Object.assign(
              new Error("token не валиден"),
              { code: 401 }
            )
          }
        })
        ,
        {
          error: {
            render({ data }) {
              return data
            }
          }
        })
    } else if (!accessToken || !refreshToken) {
      relocationModalOpen()
    } else {
      setCheckAuth(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataUPDS: (filter?: IMyRequisitesFilterComponents) => Promise<AxiosResponse<IMyRequisitesGlobeRequest>> = useCallback((filter: IMyRequisitesFilterComponents = filterComponentsState) => {
    setLoader(true)
    return MyRequistes.getData(filter, lang)
      .then(res => {
        dispatch(updateData(res.data.data))
        dispatch(updateCountsByFilter(res.data.countsByFilterAndStatus))
        const idPaySystem = filterComponentsState.paymentSystemId
        dispatch(savePaySystems({
          list: res.data.paySystems,
          fields: res.data.detailFields,
          mobileOperators: res.data.mobileOperators,
          paymentSystemId: idPaySystem,
          optionsDetailsFields: res.data.optionsDetailsFields,
          globalCommission: res.data.globalCommission,
          globalPercentDepositP2P: res.data.globalPercentDepositP2P,
          globalPercentWithdrawP2P: res.data.globalPercentWithdrawP2P,
          withdrawEnabled: res.data.withdrawEnabled,
          depositEnabled: res.data.depositEnabled
        }))
        setCountRequisites(+res.data.recordsFiltered)
        return res
      })
      .catch(err => {
        console.log(err);
        return err
      })

  }, [dispatch, filterComponentsState, lang, setLoader])

  useEffect(() => {
    if (checkAuth) {
      if (willMount.current) {
        getDataUPDS()
          .then(res => {
            if (res.data) {
              setLoading(false)
            }
          })
      }
      willMount.current = false
    }
  }, [getDataUPDS, checkAuth, setLoader])

  const commonUpdater = useCallback(() => {
    CommonUpdater.getData(lang)
      .then(res => {
        if (res.data.tasks) {
          setActiveOrders(res.data.tasks.getCountActiveOrders.data.totalCount)
          setMessageNumber(res.data.tasks.ajaxUpdateStatus.data.newSupportMessagesNumber + res.data.tasks.ajaxUpdateStatus.data.newDialogsMessagesNumber)
          const baseCurrencyFiltered = baseCurrencyFilter(res.data.tasks.getCurrencies.data)
          dispatch(changeCurrency(res.data.tasks.getCurrencies.data))
          dispatch(changeBaseCurrencyList(baseCurrencyFiltered))
          dispatch(changeReserves(res.data.tasks.getMatchingReserves))
        } else if (res.data.errorCode === "401") {
          setCheckAuth(false)
          relocationModalOpen()
        }
      })
      .catch(err => {
        console.log(err);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lang])

  useEffect(() => {
    if (checkAuth) {

      commonUpdater()
      const getDataInterval = setInterval(() => {
        commonUpdater()
      }, 20000);
      return () => {
        clearInterval(getDataInterval)
      }
    }
  }, [commonUpdater, checkAuth])


  return (
    <BrowserRouter>
      {checkAuth && <Header getLanguage={getLanguageConstants} />}
      <Body>
        {checkAuth && <Sidebar open={sideBarOpen} numberMessage={messageNumber} activeOrders={activeOrders} />}
        <ToastContainer />
        <Main>
          {loader &&
            <ModalLouder />
          }
          <Routes>
            {!loading && checkAuth && Object.keys(langConstants).length > 0 &&
              <>
                <Route index element={<PageMyApplications />} />
                <Route path="/report" element={<PageReports />} />
                <Route path="/marketio_upds" element={<PageRequisites getMainData={getDataUPDS} count={countRequisites} />} />
                <Route path="/create_ad" element={<PageCreateAd getMainData={getDataUPDS} />} />
                <Route path="/support" element={<PageSupport />} />
                <Route path="/history" element={<PageHistoryOperation />} />
              </>
            }
          </Routes>
        </Main>
      </Body>
    </BrowserRouter>
  )
};
