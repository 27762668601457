import cn from "classnames";
import "./input-pin-code.scss"
import { useAppSelector } from "../../../app/hooks";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ButtonFlat, ButtonOutline } from "../Button";
import { IconCheck, IconClose } from "../Icon"
import { IconCancel } from "../IconImg";
import { Input } from "../Input/Input"
import { BodySmallText, ButtonText, SubTitleModal } from "../Text";
import { Dispatch, SetStateAction } from "react";

type InputPinCodeProps = {
  openPinCode: boolean
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
}

export const InputPinCode: React.FC<InputPinCodeProps> = ({ openPinCode, inputValue, setInputValue }) => {
  const langConstants = useAppSelector(languageConstants)
  const arrayButtons = Array.from(Array(10).keys())

  const clickButtonNumber = (count: number) => {
    setInputValue(preValue => preValue + count)
  }
  const clickZero = () => {
    clickButtonNumber(0)
  }
  const deleteOne = () => {
    const newValue = inputValue.split("")
    newValue.pop()
    setInputValue(newValue.join(""))
  }

  return (
    <div className="input-pin-code">
      <div className={cn("input-pin-code__password",
        openPinCode ? "input-pin-code__password--open" : ""
      )}>
        <Input
          label={langConstants["L_CODE_WORD"] + " *"}
          name="settings_form_password"
          placeholder={langConstants["L_APPROVE_CODE_PLACEHOLDER_3"]}
          type="password"
          value={inputValue}
          iconChek={
            <IconCheck />
          }
        />

        <div className={cn("input-pin-code__pin-code",
          openPinCode ? "input-pin-code__pin-code--open" : ""
        )}>
          <div className="input-pin-code__pin-code-mob-close">
            <ButtonFlat>
              <IconClose />
            </ButtonFlat>
          </div>


          <div className="input-pin-code__pin-code-mob-info">
            <div className="input-pin-code__pin-code-title">
              <SubTitleModal>{langConstants["L_CODE_WORD"]}</SubTitleModal>
            </div>
            <Input
              name="settings_form_password"
              placeholder={langConstants["L_CODE_WORD_EMPTY"]}
              type="password"
              value={inputValue}
            />
          </div>
          {openPinCode ?
            <div className="input-pin-code__pin-code-input-panel">
              {arrayButtons.map((i) => {
                if (i > 0) {
                  const clickButton = () => clickButtonNumber(i)
                  return (
                    <ButtonOutline key={i} onClick={clickButton}>
                      <ButtonText>{i}</ButtonText>
                    </ButtonOutline>)
                } else {
                  return null
                }

              })}

              <ButtonOutline type="submit">
                <IconCheck />
              </ButtonOutline>

              <ButtonOutline onClick={clickZero}>
                <ButtonText>0</ButtonText>
              </ButtonOutline>

              <ButtonOutline onClick={deleteOne}>
                <IconCancel />
              </ButtonOutline>
            </div>
            :
            null
          }
        </div>
      </div>
      {!openPinCode ?
        <BodySmallText color="secondary">{langConstants["L_REQUIRED_FIELDS"]}</BodySmallText>
        :
        null
      }

    </div>
  )
}