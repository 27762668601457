import React, { SetStateAction } from "react";
import "./chat-document.scss";
import { IconText } from "../../../UI/IconText";
import { IconFileBlank, IconMenuCloseSmall } from "../../../UI/Icon";
import { BodyLightText } from "../../../UI/Text";
import { ButtonFlatSecondary } from "../../../UI/Button";

type ChatDocumentProps = {
  name: string
  setFiles: (e: SetStateAction<FileList | undefined>) => void,
  files: FileList,
};


export const ChatDocument: React.FC<ChatDocumentProps> = ({
  children,
  name,
  setFiles,
  files
}) => {
  const delFile = () => {
    const fileList: any = {}

    for (const key in files) {
      if (files[key].name !== name) {
        fileList[key] = files[key]
      }
    }
    setFiles(fileList as FileList)
  }
  return (
    <div className="chat-document">
      <IconText gap="lg">
        <IconFileBlank />
        <BodyLightText>{name}</BodyLightText>
      </IconText>

      <ButtonFlatSecondary onClick={delFile}>
        <IconMenuCloseSmall />
      </ButtonFlatSecondary>
    </div>
  )
};