import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import "./base-currency.scss";
import { IconText } from "../UI/IconText";
import { BodyText } from "../UI/Text";
import { DropdownIndicator } from "../Langs/Langs"
import { useAppSelector } from "../../app/hooks";
import { paySystemBaseCurrency, paySystemBaseCurrencyList } from "../../features/requisit/paySystemsSlice";
import { IconsAllCoins } from "../UI/IconImg/IconImgs";
import { baseCountry, languageConstants } from "../../features/settings/settingsSlice";
import { ParamContext } from "../../context/paramReducer";
import SettingService from "../../services/settingService";
import { toast } from "react-toastify";


type CurrencyProps = {
};

type CurrencyType = {
  value: number,
  label: React.ReactNode
}

export const BaseCurrency: React.FC<CurrencyProps> = () => {
  const currency = useAppSelector(paySystemBaseCurrency)
  const currencyList = useAppSelector(paySystemBaseCurrencyList)
  const country = useAppSelector(baseCountry)
  const { lang } = useContext(ParamContext)
  const [defaultCurrency, setDefaultCurrency] = useState<CurrencyType>()
  const [optionCurrency, setOptionCurrency] = useState<CurrencyType[]>([])
  const langConstants = useAppSelector(languageConstants)

  useEffect(() => {
    const option = currencyList.map(el => {
      return {
        value: el.id,
        label:
          <IconText gap="lg">
            <IconsAllCoins url={el.linkToIcon} />
            <BodyText color="secondary">{el.shortDisplayName ? el.shortDisplayName : el.shortName}</BodyText>
          </IconText>
      }
    })
    setOptionCurrency(option)
    setDefaultCurrency(option.find(el => el.value === currency?.id))

  }, [currencyList, currency?.id])

  const changeCurrency = (obj: CurrencyType) => {
    const baseCurrency = currencyList.find(el => el.id === obj.value)
    if (baseCurrency) {
      toast.promise(SettingService.setSettings( country, baseCurrency.shortName, lang)
        .then(res => {
          if (res.data.type === "success") {
            setDefaultCurrency(optionCurrency.find(el => el.value === obj.value))
            return res.data.text
          } else {
            throw res.data.text
          }
        })
        ,
        {
          pending: langConstants["L_LOADING_DATA"],
          success: {
            render({ data }) {
              return data
            }
          },
          error: {
            render({ data }) {
              return data
            }
          }
        })
    }
  }

  return (
    <div className="base-currency">
      <Select
        isSearchable={false}
        className="base-currency__body"
        options={optionCurrency}
        value={defaultCurrency}
        classNamePrefix="base-currency"
        components={{ DropdownIndicator }}
        onChange={(value) => changeCurrency(value as CurrencyType)}
      />
    </div>
  )
};
