import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { IObjectString } from "../../models/IObjectString"

export interface validationState {
    inputsValue: IObjectString,
    errorsValue: IObjectString
}

const initialState: validationState = {
    inputsValue: {},
    errorsValue: {}
}

export const validationSlice = createSlice({
    name: "checkInputs",
    initialState,
    reducers: {
        createInputsValue: (state, action) => {
            state.inputsValue = action.payload
        },
        updateInputValue: (state, action) => {
            state.inputsValue[action.payload.id] = action.payload.value
        },
        createErrorsValue: (state, action) => {
            state.errorsValue = action.payload
        },
        updateErrorsValue: (state, action) => {
            state.errorsValue[action.payload.id] = action.payload.value
        },
        deleteErrorItem: (state, action) => {
            delete state.errorsValue[action.payload]
        }
    }
})

export const { createInputsValue, updateInputValue, createErrorsValue, updateErrorsValue, deleteErrorItem } = validationSlice.actions

export const inputsValue = (state: RootState) => state.validation.inputsValue
export const errorsValue = (state: RootState) => state.validation.errorsValue

export default validationSlice.reducer