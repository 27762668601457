import React from "react";
import "./icon-img.scss";
import classNames from "classnames";

type IconImgProps = {
  icons?: string;
  name: string;
  size?: Size;
};

export type Size = "base" | "big";

export const IconImg: React.FC<IconImgProps> = ({
  icons,
  name,
  size = "base",
}) => (
  <svg className={`icon-img ${classNames(name, size ? `icon-img--size-${size}` : "")}`}>
    <use xlinkHref={`${icons}#${name}`} />
  </svg>
);

type IconImgUrlProps = {
  icons: string;
  size?: Size;
};
export const IconImgUrl: React.FC<IconImgUrlProps> = ({
  icons,

  size = "base",
}) => (
  <>
    {icons.length > 0 &&
      <img src={icons} alt={icons} className={`icon-img ${classNames(size ? `icon-img--size-${size}` : "")}`} />
    }
  </>
);

type IconImgListProps = {
  items: {
    icon: React.ReactNode;
    name: string,
  }[]
}

export const IconImgList: React.FC<IconImgListProps> = ({
  items = [],

}) => {
  return (
    <ul className="icon-img__list">
      {items.map((item, index) => (
        <li className="icon-img__list-item" key={index}>
          {item.icon}
          <span className="icon-img__list-item-name">{item.name}</span>
        </li>
      ))}
    </ul>
  )
}