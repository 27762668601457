import React, { useContext, useState } from "react";
import "./modal-confirm-action.scss"
import { useAppSelector } from "../../../app/hooks";
import { ButtonBase, ButtonOutline } from "../../UI/Button";
import { BodyText, ButtonText } from "../../UI/Text";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper"
import { toast } from "react-toastify";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ParamContext } from "../../../context/paramReducer";
import { InputPinCode } from "../../UI/InputPinCode/InputPinCode";
import MyApplication, { settingsActionOrder } from "../../../services/MyApplicationService";
import { IObjectString } from "../../../models/IObjectString";
import { Input } from "../../UI/Input";
import { Timer } from "../../Timer";
import { IconArrowsCircle } from "../../UI/Icon";
import { FormModal, FormModalBody, FormModalFooter } from "../../Form/FormModal";

type ModalConfirmActionProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
  id: string;
  getData: () => void;
};

export const ModalConfirmAction: React.FC<ModalConfirmActionProps> = ({ isOpen, title, size, id, getData }) => {
  const { onDismiss } = useContext(ModalContext);
  const { lang } = useContext(ParamContext)
  const [confirmCode, setConfirmCode] = useState(false)
  const [inputConfirmCode, setInputConfirmCode] = useState("")
  const [googleCode, setGoogleCode] = useState(false)
  const [inputGoogleCode, setInputGoogleCode] = useState("")
  const [inputPinCodeValue, setInputPinCodeValue] = useState("")
  const [pinCode, setPinCode] = useState(false)
  const [email, setEmail] = useState(false)
  const [inputEmail, setInputEmail] = useState("")
  const [telegram, setTelegram] = useState(false)
  const [inputTelegram, setInputTelegram] = useState("")
  const [flashCall, setFlashCall] = useState(false)
  const [inputFlashCall, setInputFlashCall] = useState("")
  const [timerStart, setTimerStart] = useState(true)
  const [resendUrl, setResendUrl] = useState("")
  const langConstants = useAppSelector(languageConstants)

  const getOptions = () => {
    const answer: IObjectString = {}
    if (inputConfirmCode) answer["approveCode_1"] = inputConfirmCode
    if (inputGoogleCode) answer["approveCode_2"] = inputGoogleCode
    if (inputPinCodeValue) answer["approveCode_3"] = inputPinCodeValue
    if (inputEmail) answer["approveCode_4"] = inputEmail
    if (inputTelegram) answer["approveCode_9"] = inputTelegram
    if (inputFlashCall) answer["approveCode_10"] = inputFlashCall
    return answer
  }

  const changeConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputConfirmCode(e.target.value)
  }
  const changeGoogle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputGoogleCode(e.target.value)
  }
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(e.target.value)
  }
  const changeTelegram = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTelegram(e.target.value)
  }
  const changeFlashCall = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFlashCall(e.target.value)
  }
  const getUrlForResend = (settings: settingsActionOrder) => {
    let url = "";
    if (settings.canResend && settings.handlers.indexOf(1) !== -1) {
      url = "/api/front/resend-code"
    }
    if (settings.canRecall && settings.handlers.indexOf(1) !== -1) {
      url = "/api/front/recall-sms-code"
    }
    if (settings.handlers.indexOf(4) !== -1) {
      url = "/api/front/resend-email-code"
    }
    if (settings.handlers.indexOf(10) !== -1) {
      url = "/api/front/resend-flashcall-code"
    }
    return url;
  }

  const approveHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const options = getOptions()
    toast.promise(MyApplication.actionToOrderPay(id, "confirm", lang, options)
      .then(res => {
        if (res.data.type === "success") {
          getData()
          onDismiss()
          return res.data.text
        } else if (res.data.type === "sendConfirmCode") {
          setResendUrl(getUrlForResend(res.data.data.settings))
          if (res.data.data.settings.handlers.includes(3)) {
            setPinCode(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_PIN"]
          } else if (res.data.data.settings.handlers.includes(4)) {
            setEmail(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_EMAIL"]
          } else if (res.data.data.settings.handlers.includes(2)) {
            setGoogleCode(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_GOOGLE"]
          } else if (res.data.data.settings.handlers.includes(1)) {
            setConfirmCode(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_SMS"]
          } else if (res.data.data.settings.handlers.includes(9)) {
            setTelegram(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_TELEGRAM"]
          } else if (res.data.data.settings.handlers.includes(10)) {
            setFlashCall(true)
            return langConstants["L_CONFIRM_PLACEHOLDER_FLASHCALL"]
          }
        } else {
          onDismiss()
          throw res.data.text
        }
      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  const resendHandler = () => {
    toast.promise(MyApplication.resendCode(resendUrl, lang)
      .then(res => {
        if (res.data.type === "sendConfirmCode") {
          setTimerStart(true)
          return res.data.text
        } else {
          throw res.data.text
        }
      }),
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      title={title}
      size={size}
    >
      <FormModal className="modal-confirm-action" onSubmit={approveHandler}>
        <FormModalBody>
          <div className="modal-confirm-action__content">
            <BodyText>{langConstants["L_CONFIRM_ACTION"] + ": " + langConstants["L_CONFIRM_PAYMENT"]}</BodyText>
            {confirmCode &&
              <Input
                label={langConstants["L_CONFIRM_HEADER_SMS"]}
                name="input_confirm"
                placeholder={langConstants["L_CONFIRM_PLACEHOLDER_SMS"]}
                type="text"
                value={inputConfirmCode}
                onChange={changeConfirm}
              />
            }
            {googleCode &&
              <Input
                label={langConstants["L_CONFIRM_HEADER_GOOGLE"]}
                name="input_google"
                placeholder={langConstants["L_CONFIRM_PLACEHOLDER_GOOGLE"]}
                type="text"
                value={inputGoogleCode}
                onChange={changeGoogle}
              />
            }
            {pinCode &&
              <InputPinCode
                openPinCode={true}
                inputValue={inputPinCodeValue}
                setInputValue={setInputPinCodeValue}
              />
            }
            {email &&
              <Input
                label={langConstants["L_CONFIRM_HEADER_EMAIL"]}
                name="input_email"
                placeholder={langConstants["L_CONFIRM_PLACEHOLDER_EMAIL"]}
                type="text"
                value={inputEmail}
                onChange={changeEmail}
              />
            }
            {telegram &&
              <Input
                label={langConstants["L_CONFIRM_HEADER_TELEGRAM"]}
                name="input_telegram"
                placeholder={langConstants["L_CONFIRM_PLACEHOLDER_TELEGRAM"]}
                type="text"
                value={inputTelegram}
                onChange={changeTelegram}
              />
            }
            {flashCall &&
              <Input
                label={langConstants["L_CONFIRM_SUB_HEADER_FLASHCALL"]}
                name="input_telegram"
                placeholder={langConstants["L_CONFIRM_PLACEHOLDER_FLASHCALL"]}
                type="text"
                value={inputFlashCall}
                onChange={changeFlashCall}
              />
            }
          </div>
        </FormModalBody>

        <FormModalFooter>
          {resendUrl &&
            <>
              {timerStart ?
                <ButtonOutline disabled>
                  <ButtonText><Timer time={60} changeState={setTimerStart} /></ButtonText>
                </ButtonOutline>
                :
                <ButtonOutline onClick={resendHandler}>
                  <IconArrowsCircle />
                </ButtonOutline>
              }
            </>
          }
          <ButtonOutline onClick={onDismiss}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>{langConstants["L_CONFIRM"]}</ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper >
  )
};