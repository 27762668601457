import React, { useContext } from "react";
import "./menu.scss";
import { NavLink } from "react-router-dom";
import { ParamContext } from "../../context/paramReducer";

type MenuProps = {
  children: React.ReactNode;
};

export const Menu: React.FC<MenuProps> = ({ children }) => {
  return (
    <nav className="menu">
      <ul className="menu__list">
        {children}
      </ul>
    </nav>
  );
};

type MenuItemProps = {
  href: string;
  children: React.ReactNode;
  active?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  href,
  children,
}) => {
  const {setSideBarOpen} = useContext(ParamContext)

  const closeSideBarHandler = () => {
    setSideBarOpen(false)
  };
  return (
    <li className="menu__item" onClick={closeSideBarHandler}>
      <NavLink to={href} className="menu__links">
        {children}
      </NavLink>
    </li>
  );
};

type MenuItemLinkProps = {
  href: string;
  children: React.ReactNode;
  type?: Type;
  target?: "_blank";
};

type Type = "mailto" | "tel" | "href";

export const MenuItemLink: React.FC<MenuItemLinkProps> = ({
  href,
  children,
  type = "href",
  target,
}) => {
  return (
    <>
      {type === "href" && (
        <li className="menu__item">
          <a href={href} className="menu__links" target={target}>
            {children}
          </a>
        </li>
      )}

      {type === "tel" && (
        <li className="menu__item">
          <a href={`${type}:${href}`} className="menu__links">
            {children}
          </a>
        </li>
      )}

      {type === "mailto" && (
        <li className="menu__item">
          <a href={`${type}:${href}`} className="menu__links">
            {children}
          </a>
        </li>
      )}
    </>
  );
};
