import React from "react";
import "./toggle.scss";

type ToggleProps = {
  name: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (c: boolean)=>void;
}

export const Toggle: React.FC<ToggleProps> = ({
  name = "",
  value = "",
  checked = false,
  disabled = false,
  onChange,
}) => {
 const checkHandler = () => {
   onChange(!checked)
 }
  return (
    <div className="toggle">
      <label className="toggle__label" htmlFor={name}>
        <input
          className="toggle__input"
          type="checkbox"
          name={name}
          id={name}
          value={value}
          onChange={checkHandler}
          checked={checked}
          disabled={disabled}
        />
        <span className="toggle__visual"></span>
      </label>
    </div>
  )
}