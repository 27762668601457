import React, { useEffect, useState } from "react";
import "./select-custom.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { IconArrowChevronDown } from "../Icon";
import { BodySmallText, NotificationInputText } from "../Text";

type SelectCustomProps = {
  label?: string;
  options: React.ReactNode[];
  defaultValue: React.ReactNode;
  onChange: (id: number) => void;
  icon?: React.ReactNode;
  notification?: string;
  disabled?: boolean
};

type SelectItemType = {
  value: string | number,
  label: string | number,
  id: number
}


export const SelectCustom: React.FC<SelectCustomProps> = ({
  label,
  options,
  defaultValue,
  onChange,
  icon,
  notification,
  disabled = false
}) => {
  const [value, setValue] = useState(defaultValue)
  const selectHandler = (value: SelectItemType) => {
    onChange(value.id)
  }
useEffect(()=>{
  setValue(defaultValue)
},[defaultValue])

  return (
    <div className="select-custom">
      <div className="select-custom__label">
        <BodySmallText>{label}</BodySmallText>

        {icon &&
          <div className="select-custom__icon">{icon}</div>
        }
      </div>

      <Select
        className="select-custom__body"
        options={options}
        value={value}
        // defaultValue={value}
        classNamePrefix="select-custom"
        isDisabled={disabled}
        components={{ DropdownIndicator }}
        onChange={(value) => selectHandler(value as SelectItemType)}
      />

      <div className="select-custom__noti">
        <NotificationInputText>{notification}</NotificationInputText>
      </div>
    </div>
  )
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => (
  <components.DropdownIndicator {...props}>
    <IconArrowChevronDown />
  </components.DropdownIndicator>
)