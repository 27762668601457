import React from "react";
import "./modal-wrapper.scss";
import className from "classnames";
import Modal from "react-modal";
import { ButtonFlatSecondary } from "../../UI/Button";
import { IconClose } from "../../UI/Icon";
import { TitleModal } from "../../UI/Text";

type ModalWrapperProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  size?: Size;
  center?: boolean;
};

export type Size = "base" | "sm" | "md";

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpen,
  title,
  onClose,
  children,
  size = "base",
  center = false,
}) => (
  <Modal
    className={`modal-wrapper ${className(
      size ? `modal-wrapper--size-${size}` : "",
      typeof onClose !== "function" ? "modal-wrapper--no-close" : "",
      center ? "modal-wrapper--center" : "",
    )}`}
    shouldCloseOnOverlayClick={true}
    isOpen={isOpen}
    contentLabel={title}
    ariaHideApp={false}
    onRequestClose={onClose}
    bodyOpenClassName={"modal-body"}
    overlayClassName={"modal-overlay"}
    preventScroll={true}
  >
    <div
      className={`modal-wrapper__content ${className(
        size ? `modal-wrapper__content--size-${size}` : ""
      )}`}
    >
      <div className="modal-wrapper__header">
        <TitleModal>{title}</TitleModal>
      </div>
      <div className="modal-wrapper__header-close">
        <ButtonFlatSecondary onClick={onClose}>
          <IconClose />
        </ButtonFlatSecondary>
      </div>

      <div className="modal-wrapper__body">{children}</div>
    </div>
  </Modal>
);
