import React, { useContext } from "react";
import "./history-table-operations.scss";
import { CardText, ButtonText, } from "../UI/Text";
import { Status } from "../UI/Status";
import { IOperationHistory } from "../../models/IHistory";
import { ICurrency } from "../../models/ICurrency";
import Services from "../../services/Services";
import { useAppSelector } from "../../app/hooks";
import { languageConstants } from "../../features/settings/settingsSlice";
import { ButtonOutline } from "../UI/Button";
import { toast } from "react-toastify";
import { Msg } from "../Bids/Bids";
import { ModalContext } from "../Modal/ModalProvider";
import History from "../../services/HistoryService";
import { ParamContext } from "../../context/paramReducer";
import { OperationIconNumberState } from "../OperationNumberState";

type HistoryTableOperationsProps = {
  children: React.ReactNode;
};

export const HistoryTableOperations: React.FC<HistoryTableOperationsProps> = ({
  children,
}) => (
  <div className="history-table-operations">
    {children}
  </div>
);

type HistoryTableOperationsItemProps = {
  item: IOperationHistory
  currency: ICurrency
};

function getStatus(status: number) {
  if (status === 1) return "L_IN_PROGRESS"
  else if (status === 2) return "L_PERFORMED"
  else if (status === 8) return "L_HANDLING"
  else if (status === 9) return "L_TO_CANCEL"
  else if (status === 10) return "L_TO_CHECK"
  else return "L_CANCELED"
}

export const HistoryTableOperationsItem: React.FC<HistoryTableOperationsItemProps> = ({
  item,
  currency
}) => {
  const { lang } = useContext(ParamContext)
  const date = Services.getFormatData(item.date)
  const langConstants = useAppSelector(languageConstants)
  const status = getStatus(item.status)
  const operationType: {name: string, type: "down" | "up" | "na-obmen" | "obmen" | "exchange"} = {name: `L_TRANSACTION_TYPE_${item.type}`, type: "exchange"}
  if(item.type === 1) operationType.type = "up"
  else if(item.type === 2) operationType.type = "down"
  else if(item.type === 4) operationType.type = "obmen"
  else if(item.type === 6) operationType.type = "na-obmen"
  const { onDismiss } = useContext(ModalContext);
  

  const sumWithCommission = Services.getCurrencyFormat((item.sum + item.commission), currency.prec)

  const cancelHandler = () => {
    toast.promise(History.cancelOperation(item.id, lang)
      .then(res => {
        if (res.data.type === "success") {
          return res.data.text
        } else {
          throw res.data.text
        }
      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  const openToastQuestion = () => {
    toast(<Msg action="cancel" closeToast={onDismiss} actionToOrder={cancelHandler} />, { autoClose: false })
  }

  return (
    <div className="history-table-operations__item">
      <div className="history-table-operations__item-header">
        <div className="history-table-operations__item-header-left">
          <OperationIconNumberState
            state={operationType.type}
            operationName={langConstants[operationType.name]}
            value={`${item.id}`}
          />

          <div className="history-table-operations__date-item">
            <CardText>{date.day}</CardText>
            <CardText>{date.time}</CardText>
          </div>
        </div>

        <Status status={status}>{langConstants[status]}</Status>
      </div>

      <div className="history-table-operations__item-body">
        <div className="history-table-operations__item-body-item">
          <CardText>
            {langConstants["L_AMOUNT"] + ":"}
          </CardText>
          <CardText>
            {`${item.sum} ${currency.shortName}`}
          </CardText>
        </div>

        <div className="history-table-operations__item-body-item">
          <CardText>
            {langConstants["L_WITH_COMMISSION"] + ":"}
          </CardText>
          <CardText>
            {`${sumWithCommission} ${currency.shortName}`}
          </CardText>
        </div>

        <div className="history-table-operations__item-body-item">
          {item.info.address ?
            <div className="history-table-operations__info-item">
              <CardText>{item.info.address}</CardText>
            </div>
            :
            <div className="history-table-operations__info-item">
              <CardText>
                <div dangerouslySetInnerHTML={{ __html: item.infoString }} />
              </CardText>
            </div>
          }
        </div>

        {item.status === 1 && item.cashMethodId === 4 &&
          <div className="history-table-operations__item-list-item">
            <ButtonOutline size="sm" onClick={openToastQuestion}>
              <ButtonText>{langConstants["L_CANCEL_VERB"]}</ButtonText>
            </ButtonOutline>
          </div>
        }
      </div>
    </div>
  )
};
