import { createContext } from 'react'

type countType = {
    value: string,
    label: number
}
type stateType = {
    countInPageMyApplications: countType,
    setCountInPageMyApplications: (c: countType) => void,
    countInPageRequisite: countType,
    setCountInPageRequisite: (c: countType) => void,
    countInPageHistory: countType,
    setCountInPageHistory: (c: countType) => void,
    sideBarOpen: boolean,
    setSideBarOpen: (c: boolean) => void,
    lang: "ru" | "en",
    setLang: (c: "ru" | "en") => void,
    loader: boolean,
    setLoader: (c: boolean) => void,
    chatBarOpen: boolean,
    setChatBarOpen: (c: boolean) => void
}

const initialState: stateType = {
    countInPageMyApplications: { value: "25", label: 25 },
    setCountInPageMyApplications: () => { },
    countInPageRequisite: { value: "25", label: 25 },
    setCountInPageRequisite: () => { },
    countInPageHistory: { value: "25", label: 25 },
    setCountInPageHistory: () => { },
    sideBarOpen: false,
    setSideBarOpen: () => { },
    lang: "ru",
    setLang: () => { },
    loader: false,
    setLoader: () => { },
    chatBarOpen: false,
    setChatBarOpen: () => {}
}


export const ParamContext = createContext(initialState)