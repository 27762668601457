import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store"


export interface AuthState {
    accessToken: string
    nickName: string
    refreshing: boolean
}

const initialState: AuthState = {
    accessToken: "",
    nickName: "",
    refreshing: false
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        saveToken: (state, action) => {
            state.accessToken = action.payload
        },
        changeNickname: (state, action) => {
            state.nickName = action.payload
        },
        changeRefresh: (state, action) => {
            state.refreshing = action.payload
        }
    }
})

export const { saveToken, changeNickname, changeRefresh } = authSlice.actions

export const authAccessToken = (state: RootState) => state.auth.accessToken
export const authNickname = (state: RootState) => state.auth.nickName
export const isRefreshing = (state: RootState) => state.auth.refreshing

export default authSlice.reducer