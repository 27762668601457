import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar-custom.scss";
import { IconArrowChevronLeft, IconArrowChevronRight } from "../UI/Icon";
import { LabelInputText } from "../UI/Text";
import Services from "../../services/Services";
import { IDateSearch } from "../../models/IGlobe";

type CalendarProps = {
  label?: string;
  setDateState?: Dispatch<SetStateAction<IDateSearch>>
  initDate?: Date[] | null
  setInitDate?: Dispatch<SetStateAction<Date[] | null>>
};


export const CalendarCustom: React.FC<CalendarProps> = ({
  label,
  setDateState,
  initDate,
  setInitDate
}) => {

  const [date, setDate] = useState([new Date()]);
  useEffect(() => {
    if(setDateState){
      if(initDate && initDate[0] && initDate[1]){
        const dateFrom = Services.getDataForSearch(initDate[0])
        const dateTo = Services.getDataForSearch(initDate[1])
        setDateState({ dateFrom, dateTo })
      }
      if (date[0] && date[1]) {
        const dateFrom = Services.getDataForSearch(date[0])
        const dateTo = Services.getDataForSearch(date[1])
        setDateState({ dateFrom, dateTo })
      }
    }
  }, [date, setDateState, initDate])

  return (
    <div className="calendar-custom">
      <div className="calendar-custom__label">
        <LabelInputText>{label}</LabelInputText>
      </div>
      <Calendar
        className="calendar-custom__calendar"
        onChange={setInitDate ? setInitDate : setDate}
        selectRange={true}
        value={initDate}
        showFixedNumberOfWeeks={true}
        tileClassName={"calendar-custom__day"}
        prevLabel={<IconArrowChevronLeft />}
        prev2Label={null}
        nextLabel={<IconArrowChevronRight />}
        next2Label={null}
      />
    </div>
  );
};
