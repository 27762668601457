import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
// import settingsReducer from '../features/requisit/settingsSlice';
import authReducer from '../features/auth/authSlice'
import paySystemsReducer from '../features/requisit/paySystemsSlice'
import validationReducer from '../features/requisit/validationSlice'
import requestDataReducer from '../features/requisit/requestDataSlice'
import settingSliceReducer from '../features/settings/settingsSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    // settings: settingsReducer,
    auth: authReducer,
    paySystems: paySystemsReducer,
    validation: validationReducer,
    requestData: requestDataReducer,
    setting: settingSliceReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
