import { AxiosResponse } from "axios";
import {api} from "../api";
import { IAppConfig } from "../models/IAppConfig";
import { IPaySystem } from "../models/IPaySystem";
import Services from "./Services";

type PaySystemAnswer = {
  data: IPaySystem[],
  dataP2P: IPaySystem[],
  type: string
}

type dataSettingsType = {
  type: string,
  data: {
    additionDocPhotoLoaded: string[],
    baseCountry: number,
    baseCurrency: string,
    lang: "ru" | "en",
    baseCurrencyObj: {
      bestchangeDepositEnable: boolean,
      bestchangeWithdrawEnable: boolean,
      crossExchangeCurrencyId: null | number,
      displayName: string,
      enabled: boolean,
      estimateNetFee: null | number,
      estimateParentCurrencyNetFee: null | number,
      exchangeWeight: number,
      id: number,
      info: null | string,
      inputEnabled: boolean,
      isMain: boolean,
      linkToIcon: string,
      medianNetFee: null | string,
      minConfirmationCount: null | string,
      name: string,
      netFeeHighPriorityFactor: number,
      netFeeMiddlePriorityFactor: number,
      netFeeNormalPriorityFactor: number,
      outputEnabled: boolean,
      parentCurrencyId: null | number,
      prec: number,
      shortDisplayName: null | string,
      shortName: string,
      showInUserWallets: boolean,
      sort: number,
      type: number,
      urlScheme: null | string,
      withdrawHighPriorityFactor: number,
      withdrawMiddlePriorityFactor: number,
      withdrawNormalPriorityFactor: number,
    }
    birthday: null | string,
    blockLiteDetails: number,
    bonusLevel: number,
    cards: string[],
    cardsWithId: { [index: number]: string },
    codeSendHandlerId: number,
    codeWordsBlackList: string[],
    documentPhotoLoaded: boolean,
    donationCurrency: null | boolean,
    donationSettingsEnabled: boolean,
    email: string,
    emailDisplay: string,
    enabledMailingLetters: boolean,
    firstName: string,
    forwardState: number,
    isExistLinkWithTelegram: boolean,
    lastName: string,
    location: { countryId: number, city: string, postalCode: string }
    massivePaymentsEnabled: boolean,
    merchantSettings: { isAfk: boolean }
    middleName: string,
    passport: {
      date: null | string,
      isApprove: boolean,
      isSumsubApprove: boolean,
      number: string,
      registrationAddress: string,
      whomIssued: string
    }
    passportName: string,
    propsTemplates: string[],
    word: string
  }
}

export default class SettingService {


  static async getPaySystems(
    lang: "en" | "ru"
  ): Promise<AxiosResponse<PaySystemAnswer>> {
    const url = Services.getURL("/api/account/get-payments-systems", lang)
    return api.get(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  };
  static async geyAppConfig(
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IAppConfig>> {
    const url = Services.getURL("/api/front/get-app-config", lang)
    return api.get<IAppConfig>(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async changeAfk(
    afk: boolean,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/set-trusted-afk", lang)
    const dataURL = new FormData();
    if (afk) dataURL.append("afk", "false")
    else dataURL.append("afk", "true")

    return api.post(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async getSettings(
    lang: "en" | "ru"
  ): Promise<AxiosResponse<dataSettingsType>> {
    const url = Services.getURL("/api/account/get-settings-data", lang)
    return api.get<dataSettingsType>(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async setSettings(
    baseCountry: number,
    baseCurrency: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/set-base-settings", lang)
    const dataURL = new FormData();
    dataURL.append("baseCountry", baseCountry.toString())
    dataURL.append("baseCurrency", baseCurrency)
    dataURL.append("baseLanguage", lang)

    return api.post(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
}