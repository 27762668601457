import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import classNames from "classnames";
import { Menu, MenuItem, MenuItemLink } from "../Menu";
import { IconText } from "../UI/IconText";
import {
  IconDocument,
  IconExit,
  IconHelp,
  IconHistory,
  IconNotebook,
  IconPlusCircle,
  IconReport,
  IconSupport,
} from "../UI/Icon";
import { BodySmallText, MenuText, ButtonText, H2 } from "../UI/Text";
import { VerticalOffset } from "../Grid/VerticalOffset";
import { languageConstants } from "../../features/settings/settingsSlice";
import { useAppSelector } from "../../app/hooks";
import { ButtonOutline } from "../UI/Button";
import {API_URL} from "../../api";
import { CounterRound } from "../UI/CounterRound";
import { SidebarList, SidebarListItemReserves } from "./components/SidebarList";
import {
  paySystemCurrency,
  paySystemReserves,
} from "../../features/requisit/paySystemsSlice";
import { IReserves } from "../../models/IReserves";
import {toast} from "react-toastify";
import MyApplication from "../../services/MyApplicationService";

type SidebarProps = {
  open?: boolean;
  numberMessage: number;
  activeOrders: number;
};
const getKeys = (reserves: IReserves, amount = 0) => {
  const newReserveList = Object.keys(reserves);
  if ( amount !== 0){
    newReserveList.length = amount;}
    return newReserveList
}

export const Sidebar: React.FC<SidebarProps> = ({
  open = false,
  numberMessage,
  activeOrders,
}) => {
  const langConstants = useAppSelector(languageConstants);
  const [showReserves, setShowReserves] = useState<boolean>(false);
  const { reserves, paymentsSystems } = useAppSelector(paySystemReserves);

  const [reserveList, setReserveList] = useState<string[]>([]);
  const currency = useAppSelector(paySystemCurrency);
  const goToOldArea = () => {
    toast.promise(MyApplication.toggleActiveCabinet()
      .then(res => {
        if (res.data.type === "success" && res.data.activeCabinet === 1) {
          window.location.href = API_URL
        } else {
          throw res.data.text
        }
      }),
  {
      pending: langConstants["L_LOADING_DATA"],
      error: {
        render({ data }) {
          return data
        }
      }
    })
  };
  useEffect(() => {
    const defaultReserveList = getKeys(reserves, 3)
    setReserveList(defaultReserveList);
  }, [reserves]);

  const changeShowReserves = () => {
    if (showReserves) {
      const shortListReserves = getKeys(reserves, 3)
      setReserveList(shortListReserves);
    } else {
      setReserveList(Object.keys(reserves));
    }
    setShowReserves(!showReserves);
  };

  return (
    <aside className={`sidebar ${classNames(open ? "sidebar--open" : "")}`}>
      <div className="sidebar__body">
        <div className="sidebar__top">
          <VerticalOffset
            offset="4m"
            breakpoints={{
              lg: {
                offset: "xl",
              },
            }}
          >
            {Object.keys(langConstants).length > 0 && (
              <>
              <Menu>
                <ButtonOutline size="md" onClick={goToOldArea}>
                  <IconExit />
                  <ButtonText>Personal</ButtonText>
                </ButtonOutline>
                <MenuItem href="/">
                  <IconText gap="2xl" align="start">
                    <IconNotebook />
                    <MenuText>{langConstants["L_MY_DEALS"]}</MenuText>
                    {activeOrders ? (
                      <CounterRound counter={activeOrders} />
                    ) : null}
                  </IconText>
                </MenuItem>

                <MenuItem href="/marketio_upds">
                  <IconText gap="2xl" align="start">
                    <IconDocument />
                    <MenuText>
                      {langConstants["L_MY_DETAILS_ANNOUNCEMENT"]}
                    </MenuText>
                  </IconText>
                </MenuItem>

                <MenuItem href="/create_ad">
                  <IconText gap="2xl" align="start">
                    <IconPlusCircle />
                    <MenuText>
                      {langConstants["L_MY_DETAILS_CREATE_AD"]}
                    </MenuText>
                  </IconText>
                </MenuItem>

                <MenuItem href="/report">
                  <IconText gap="2xl" align="start">
                    <IconReport />
                    <MenuText>{langConstants["L_REPORT"]}</MenuText>
                  </IconText>
                </MenuItem>

                <MenuItem href="/support">
                  <IconText gap="2xl" align="start">
                    <IconSupport />
                    <MenuText>{langConstants["L_CHATS"]}</MenuText>
                    {numberMessage ? (
                      <CounterRound counter={numberMessage} />
                    ) : null}
                  </IconText>
                </MenuItem>

                <MenuItem href="/history">
                  <IconText gap="2xl" align="start">
                    <IconHistory />
                    <MenuText>{langConstants["L_HISTORY"]}</MenuText>
                  </IconText>
                </MenuItem>

                <MenuItemLink
                  href="https://docs.google.com/document/d/1hU_3Q2S2eYfZdxPc9x_73pztjBf1ixsUiO3_vpmyh3I/edit"
                  target="_blank"
                >
                  <IconText gap="2xl" align="start">
                    <IconHelp />
                    <MenuText>FAQ</MenuText>
                  </IconText>
                </MenuItemLink>

                {/* <MenuItem href="/settings">
                <IconText gap="2xl" align="start">
                  <IconSettings />
                  <MenuText>{langConstants["L_SETTINGS"]}</MenuText>
                </IconText>
              </MenuItem> */}
              </Menu>
            <VerticalOffset
              offset="3s"
              breakpoints={{
                lg: {
                  offset: "m",
                },
              }}
            >
              <H2>{langConstants["L_MY_RESERVES"]}</H2>
              <SidebarList
                gap="2s"
                button={Object.keys(reserves).length > 3 ? true : false}
                setShow={changeShowReserves}
                show={showReserves}
              >
                {reserveList.length > 0 &&
                  Object.keys(paymentsSystems).length > 0 &&
                  reserveList.map((el) => {
                    const bankName = paymentsSystems[el]
                      ? paymentsSystems[el].name
                      : "";
                    const currencyItem =
                      paymentsSystems[el] &&
                      currency.find(
                        (item) => item.id === paymentsSystems[el].currencyId
                      );
                    const currencyShortName = currencyItem
                      ? currencyItem.displayName
                      : "";
                    return (
                      <SidebarList key={el} title={bankName}>
                        {reserves[+el] && reserves[+el][2] && (
                          <>
                            <SidebarListItemReserves
                              name={langConstants["L_SALE"]}
                              value={
                                reserves[+el][2].amountOnAccount +
                                " " +
                                currencyShortName
                              }
                            />
                            <SidebarListItemReserves
                              name={langConstants["L_SELL_LIMIT"]}
                              value={
                                reserves[+el][2].minReserv +
                                " " +
                                currencyShortName
                              }
                            />
                          </>
                        )}
                        {reserves[+el] && reserves[+el][1] && (
                          <>
                            <SidebarListItemReserves
                              name={langConstants["L_TRADE_BUY"]}
                              value={
                                reserves[+el][1].amountOnAccount +
                                " " +
                                currencyShortName
                              }
                            />

                            <SidebarListItemReserves
                              name={langConstants["L_BUY_LIMIT"]}
                              value={
                                reserves[+el][1].minReserv +
                                " " +
                                currencyShortName
                              }
                            />
                          </>
                        )}
                      </SidebarList>
                    );
                  })}
              </SidebarList>
            </VerticalOffset>
              </>
            )}
          </VerticalOffset>
        </div>

        <div className="sidebar__bottom">
          <BodySmallText color="secondary">© 2014 - {new Date().getFullYear()} MATBEA</BodySmallText>
        </div>
      </div>
    </aside>
  );
};
