import React from "react";
import "./block-content.scss";
import classNames from "classnames";

type BlockContentProps = {
  bgColor?: BgColor;
  children: React.ReactNode;
};

type BgColor = "base" | "secondary" | "three" | "four" | "five";

export const BlockContent: React.FC<BlockContentProps> = ({
  bgColor = "secondary",
  children,
}) => (
  <div className={`block-content ${classNames(
    bgColor ? `block-content--${bgColor}` : "",
  )}`}>
    {children}
  </div>
);