import { AxiosResponse } from "axios";
import { api } from "../api";
import { IBalance, ICurrency } from "../models/ICurrency";
import Services from "./Services";


type ExchangeRatesType = {
  [index: string]: {
    buy: number,
    cashMethodId: number,
    currencyIdFrom: number,
    currencyIdTo: number,
    date: number,
    isEnabled: boolean,
    pair: string,
    rateStock: number,
    sell: number
  }
}
type DataCommonUpdater = {
  OK: number,
  tasks: {
    getCurrencies: {
      data: ICurrency[]
    },
    ajaxUpdateStatus: {
      data: {
        isWorkingTime: boolean,
        newDialogsMessagesNumber: number,
        newSupportMessagesNumber: number,
        notifications: string[],
        workTime: {
          end: { h: number, m: number }
          start: { h: number, m: number }
        }
      }
    },
    getBalance: {
      sumByAllWallets: number,
      data: {
        [index: number]: IBalance
      }
    },
    getCountActiveOrders: {
      data: {
        totalCount: number,
        totalForNotice: number
      }
    },
    getExchangeRates: {
      data: ExchangeRatesType
    },
    getExchangeRatesForHeader: {
      data: ExchangeRatesType
    },
    getMatchingReserves: {
      data: {
        paymentsSystems: {
          [index: number]: { name: string, currencyId: number }
        },
        reserves: {
          [index: number]: {
            [index: number]: { minReserv: number, amountOnAccount: number }
          },
          isP2P: boolean,
          nickName: string,
        },
        totalReserves: {
          [index: number]: {
            [index: number]: {
              [index: number]: { minReserv: number, amountOnAccount: number }
            }
          }
        }
      }
    }
  },
  data: null,
  errorCode: string,
  moveTo: null,
  text: string,
  type: "error" | "success"
}


export default class CommonUpdater {

  static async getData(
    lang: "en" | "ru"
  ): Promise<AxiosResponse<DataCommonUpdater>> {
    const url = Services.getURL("/api/front/common-updater", lang)
    const dataURL = new FormData();
    dataURL.append("ajaxUpdateStatus[data][send]", "1")
    dataURL.append("getBalance[data][send]", "1")
    dataURL.append("getMatchingReserves[data][send]", "1")
    dataURL.append("getCurrencies[data][send]", "1")
    dataURL.append("getExchangeRates[data][send]", "1")
    dataURL.append("getExchangeRatesForHeader[data][send]", "1")
    dataURL.append("getCountActiveOrders[data][send]", "1")
    return api.post<DataCommonUpdater>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
}