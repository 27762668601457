import React, { ChangeEvent, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import "./chat-text-area.scss";
import { ButtonFlat, ButtonIcon } from "../../../UI/Button";
import { IconClip, IconTelegram } from "../../../UI/Icon";
import Support from "../../../../services/SuppotrService";
import { useAppSelector } from "../../../../app/hooks";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import SettingService from "../../../../services/settingService";
import { ParamContext } from "../../../../context/paramReducer";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
type ChatTextAreaProps = {
  setFiles: (e: SetStateAction<FileList | undefined>) => void,
  files?: FileList,
  activeChat: string,
  getChatMessages: (id: string) => void
};


export const ChatTextArea: React.FC<ChatTextAreaProps> = ({ setFiles, activeChat, getChatMessages, files }) => {
  const { lang } = useContext(ParamContext)
  const [acceptFormat, setAcceptFormat] = useState<string[]>([".pdf", ".png", ".jpeg", ".jpg"])
  const [disabledButton, setDisabledButton] = useState(false)
  const langConstants = useAppSelector(languageConstants)
  const inputFile = useRef() as React.MutableRefObject<HTMLInputElement>
  const [text, setText] = useState<string>("")
  const addFileHandler = () => {
    inputFile.current.click()
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList
    const fileList: any = {}

    for (const key in files) {
      if (files[key].name && files[key].size > 0) {
        if (files[key].size < 3000000) {
          fileList[key] = files[key]
        } else {
          const message = langConstants["L_PHOTO_UPLOAD_FORM_INFO6"].replace(/:maxSize/, "3")
          toast.error(<>
            <div>{message}</div>
            <div>{langConstants["L_FILE"] + ": " + files[key].name}</div>
          </>)
        }
      }
    }
    setFiles(fileList as FileList)
  }
  const textareaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }
  const onKeyDownTextarea = (e: any) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
      sendMessage()
    }

    if (keyCode === 13 && !(e.shiftKey || e.ctrlKey)) {
      e.preventDefault();
    }
    if (e.target && keyCode === 13 && e.ctrlKey) {
      setText(e.target.value += "\r\n");
    }
  }
  const sendMessage = () => {
    setDisabledButton(true)
    if (text) {
      let entityType = ""
      let entityId = ""
      if (activeChat !== "0") {
        entityType = "10"
        entityId = activeChat
      }
      Support.sendMessage(text, entityType, entityId, lang, files)
        .then(res => {
          if (res.data.type === "success") {
            setText("")
            setFiles(undefined)
            getChatMessages(entityId)
            setDisabledButton(false)
          }
        })
    } else {
      toast.error(<>{langConstants["L_ENTER_YOUR_MESSAGE"]}</>)
      setDisabledButton(false)
    }
  }

  useEffect(() => {
    SettingService.geyAppConfig(lang)
      .then(res => {
        setAcceptFormat(res.data.document_types)
      })
  }, [lang])

  return (
    <div className="chat-text-area">
      <div className="chat-text-area__body">
        <textarea
          className="chat-text-area__area"
          id="massage"
          name="message"
          placeholder={langConstants["L_ENTER_MESSAGE"]}
          value={text}
          onChange={textareaHandler}
          onKeyDown={onKeyDownTextarea}
        >

        </textarea>
        <input type="file" onChange={handleChange} ref={inputFile} accept={acceptFormat ? acceptFormat.join(",") : ""} hidden multiple />
        <div className="chat-text-area__button">
          <ButtonFlat onClick={addFileHandler}>
            <IconClip />
          </ButtonFlat>
        </div>
      </div>

      <ButtonIcon disabled={disabledButton} onClick={sendMessage}>
        <IconTelegram />
      </ButtonIcon>
    </div>
  )
};