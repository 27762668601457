import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { paySystemFields, paySystemMobileOperators, paySystemOptionsDetailsFields } from "../../features/requisit/paySystemsSlice"
import { Input } from "../UI/Input"
import { TextArea } from "../UI/TextArea"
import { IPaySystemField } from "../../models/IPaySystemField"
import dataAllFields from "../../data/paySystemFields.json"
import { SelectCustom } from "../UI/SelectCustom"
import { useEffect } from "react"
import { IObjectString } from "../../models/IObjectString"
import { deleteErrorItem, errorsValue, inputsValue, updateErrorsValue, updateInputValue } from "../../features/requisit/validationSlice"
import { IOptionsDetailsFields } from "../../models/IPaySystem"
import { languageConstants } from "../../features/settings/settingsSlice"
import { InputCalendar } from "../UI/InputCalendar"
import Services from "../../services/Services"

type FieldPaySystemProps = {
  createNew: boolean;
  paySystemId: number;
  value?: string;
  publicDetails: IObjectString;
  isSell?: boolean
}

export const FieldPaySystem: React.FC<FieldPaySystemProps> = ({ createNew, paySystemId, value = "", publicDetails, isSell = true }) => {
  const allFieldsForPaySystems = useAppSelector(paySystemFields)
  const allMobileOperators = useAppSelector(paySystemMobileOperators)
  //TODO похоже нигде не используется можно выпилить из проекта
  // const paySystemsDeposit = useAppSelector(paySystemDeposit)
  const paySystemsOptionsDetailsFields = useAppSelector(paySystemOptionsDetailsFields)
  const dispatch = useAppDispatch()
  const errorsDetailValue = useAppSelector(errorsValue)
  const publicDetailsState = useAppSelector(inputsValue)
  const fields = allFieldsForPaySystems[paySystemId]
  const allFields: { [index: string]: IPaySystemField } = dataAllFields
  const langConstants = useAppSelector(languageConstants)

  const allMobileOperatorsUpdate = Object.values(allMobileOperators).map((el: { id: number, name: string }) => {
    return { ...el, value: el.name, label: el.name }
  });
  const paySystemsOptionsDetailsFields53Update = paySystemsOptionsDetailsFields[53] && Object.values(paySystemsOptionsDetailsFields[53]).map((el: IOptionsDetailsFields) => {
    return { ...el, value: el.data.ru, label: el.data.ru, id: el.optionId }
  })
  useEffect(() => {
    if (publicDetailsState[11] && publicDetailsState[11].length > 2) {
      const operator = allMobileOperatorsUpdate.find(el => el.name === publicDetailsState[11])
      if (operator) dispatch(updateInputValue({ id: "11", value: operator.id.toString() }))
    }
  }, [publicDetailsState, allMobileOperatorsUpdate, dispatch])
  useEffect(() => {
    if (publicDetailsState[53] && publicDetailsState[53].length > 2) {
      const bank = paySystemsOptionsDetailsFields[53].find(el => el.data.ru === publicDetailsState[53])
      if (bank) dispatch(updateInputValue({ id: "53", value: bank.optionId.toString() }))
    }
  }, [publicDetailsState, paySystemsOptionsDetailsFields, dispatch])

  const calendarHandler = (date: string) => {
    dispatch(updateInputValue({ id: "4", value: date }))
  }

  const inputHandler = (arg: React.ChangeEvent<HTMLInputElement>) => {
    const fieldNumber = arg.target.id.split("_")
    const numberDetails = fieldNumber[fieldNumber.length - 1]
    const mask = allFields[numberDetails].mask
    if (numberDetails === "47") arg.target.value = arg.target.value.toUpperCase()
    if (mask) {
      const regExp = new RegExp(mask)
      if (numberDetails === "4") {
        const checkDate = Services.checkDataStr(arg.target.value)
        if (checkDate) {
          dispatch(updateInputValue({ id: numberDetails, value: arg.target.value }))
          dispatch(deleteErrorItem(numberDetails))
        } else {
          dispatch(updateInputValue({ id: numberDetails, value: arg.target.value }))
          dispatch(updateErrorsValue({ id: numberDetails, value: langConstants[`L_PS_DETAILS_FIELD_ERROR_${numberDetails}`] }))
          console.log("Ошибка при валидации поля: ", allFields[numberDetails].label, allFields[numberDetails].errorMessage);
        }
      } else if (regExp.test(arg.target.value)) {
        dispatch(updateInputValue({ id: numberDetails, value: arg.target.value }))
        dispatch(deleteErrorItem(numberDetails))
      } else {
        dispatch(updateInputValue({ id: numberDetails, value: arg.target.value }))
        dispatch(updateErrorsValue({ id: numberDetails, value: langConstants[`L_PS_DETAILS_FIELD_ERROR_${numberDetails}`] }))
        console.log("Ошибка при валидации поля: ", allFields[numberDetails].label, allFields[numberDetails].errorMessage);
      }
    } else if (!allFields[numberDetails].mask) {
      dispatch(updateInputValue({ id: numberDetails, value: arg.target.value }))
    }
    if (arg.target.value.length === 0) {
      dispatch(deleteErrorItem(numberDetails))
    }
  }

  const textAreaHandler = (arg: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateInputValue({ id: "9", value: arg.target.value }))
  }
  const selectOperatorsHandler = (id: number) => {
    const operator = allMobileOperatorsUpdate.find(el => el.id === id)
    if (operator) dispatch(updateInputValue({ id: "11", value: operator.id.toString() }))
  }
  const selectBankHandler = (id: number) => {

    const bank = paySystemsOptionsDetailsFields[53].find(el => el.optionId === id)

    if (bank) dispatch(updateInputValue({ id: "53", value: bank.optionId.toString() }))
  }

  return (
    <>
      {fields && fields.map(el => {
        if ((isSell && el.forDeposit) || (!isSell && el.forWithdraw)) {
          if (el.fieldId === 4) {
            return (
              <InputCalendar
                key={el.fieldId}
                label={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                name={allFields[el.fieldId].name}
                placeholder={langConstants[`L_PS_DETAILS_FIELD_PLACEHOLDER_${el.fieldId}`]}
                type={allFields[el.fieldId].type}
                value={publicDetailsState[el.fieldId]}
                error={errorsDetailValue[el.fieldId] ? true : false}
                notification={errorsDetailValue[el.fieldId] && errorsDetailValue[el.fieldId]}
                onChange={inputHandler}
                onChangeStr={calendarHandler}
                required={true}
                maxDate={new Date()}
              />
            )
          } else if (el.fieldId === 11) {
            const defValue = allMobileOperatorsUpdate.find(el => el.id === +publicDetailsState[11])
            return (
              <SelectCustom
                key={el.fieldId}
                label={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                options={allMobileOperatorsUpdate}
                defaultValue={defValue ? defValue : allMobileOperatorsUpdate[0]}
                onChange={selectOperatorsHandler}
              />
            )
          } else if (el.fieldId === 53) {
            const defValue = paySystemsOptionsDetailsFields53Update.find(el => el.optionId === +publicDetailsState[53])
            return (
              <SelectCustom
                key={defValue ? el.fieldId + defValue.optionId : el.fieldId}
                label={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                options={paySystemsOptionsDetailsFields53Update}
                defaultValue={defValue ? defValue : paySystemsOptionsDetailsFields53Update[0]}
                onChange={selectBankHandler}
              />
            )
          } else if (el.fieldId !== 9) {
            return (
              <Input
                key={el.fieldId}
                label={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                name={allFields[el.fieldId].name}
                placeholder={langConstants[`L_PS_DETAILS_FIELD_PLACEHOLDER_${el.fieldId}`] ? langConstants[`L_PS_DETAILS_FIELD_PLACEHOLDER_${el.fieldId}`] : ""}
                type={allFields[el.fieldId].type}
                value={publicDetailsState[el.fieldId]}
                required={true}
                error={errorsDetailValue[el.fieldId] ? true : false}
                notification={errorsDetailValue[el.fieldId] && errorsDetailValue[el.fieldId]}
                onChange={inputHandler}
              />
            )
          } else { return null }
        } else { return null }
      })
      }

      {fields &&
          <TextArea
              label={langConstants["L_PS_DETAILS_FIELD_9"]}
              name="textArea_9"
              rows={3}
              placeholder={langConstants["L_ENTER_TEXT"]}
              value={String(publicDetailsState[9] ? publicDetailsState[9] : "")}
              onChange={textAreaHandler}
              notification={langConstants["L_NOT_ALLOW_TRANSFER_PERSONAL_CONTACTS"]}
          />
      }
    </>
  )
}