import { AxiosResponse } from "axios";
import { api, apiNoToken } from "../api";
import Services from "./Services";

type authRequest = {
  accessToken: string;
  expiresAt: number;
  lifeTime: number;
  moveTo: string;
  refreshExpiresAt: number;
  refreshLifeTime: number;
  refreshToken: string;
  type: string;
};

type refreshRequest = {
  data: {
    accessToken: string;
    expiresAt: number;
    lifeTime: number;
    refreshExpiresAt: number;
    refreshLifeTime: string;
    refreshToken: string;
  };
  errorCode: null | string;
  moveTo: null | string;
  text: null | string;
  type: "success";
};
type logoutRequest = {
  type: string;
  moveTo: string;
};

export default class Auth {
  static async testLogin(): Promise<AxiosResponse<authRequest>> {
    const dataURL = "email=matbea-trusted%40test.com&password=testtest";
    return apiNoToken.post<authRequest>("/api/auth/login-by-email", dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async getNewToken(
    lang: "en" | "ru"
  ): Promise<AxiosResponse<refreshRequest>> {
    const url = Services.getURL("/api/auth/refresh-token", lang);
    const dataURL = new FormData();
    const token = localStorage.getItem("refreshToken");
    dataURL.append("refreshToken", token as string);
    return apiNoToken.post<refreshRequest>(url, dataURL, {
      headers: {
        "Content-Type": "'text/html; charset=utf-8'",
      },
    });
  }
  static async logout(): Promise<AxiosResponse<logoutRequest>> {
    const dataURL = new FormData();
    dataURL.append("logout", "true");
    return api.post<logoutRequest>("/api/auth/json-logout", dataURL, {
      headers: {
        "Content-Type": "'text/html; charset=utf-8'",
      },
    });
  }
}
