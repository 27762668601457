import React, { useState } from "react";
import { LabelInputText, NotificationInputText } from "../Text/Text";
import "./text-area.scss";

type TextAreaProps = {
  name: string;
  label: string;
  value?: string;
  cols?: number;
  rows?: number;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  notification?: string;
  required?: boolean
};


export const TextArea: React.FC<TextAreaProps> = ({
  label,
  cols,
  rows,
  name,
  value = "",
  placeholder,
  onChange,
  notification,
  required = false
}) => {
  const [valueInput, setValueInput] = useState(value);

  return (
    <div className="text-area">
      <label htmlFor={name} className="text-area__label">
        <LabelInputText>{label}</LabelInputText>
      </label>

      <textarea id={name}
        value={valueInput}
        name={name}
        className="text-area__area"
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        required={required}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
          setValueInput(e.target.value)
        }}
      />

      <div className="text-area__noti">
        <NotificationInputText>{notification}</NotificationInputText>
      </div>
    </div>
  )
};