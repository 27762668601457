import React, { useContext, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ListForm, ListFormItem } from "../../ListForm";
import { ButtonBase, ButtonOutline } from "../../UI/Button";
import { ButtonText } from "../../UI/Text";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper"
import MyApplication from "../../../services/MyApplicationService"
import { toast } from "react-toastify";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ParamContext } from "../../../context/paramReducer";
import { SelectCustom } from "../../UI/SelectCustom";
import { FormModal, FormModalBody, FormModalFooter } from "../../Form/FormModal";

type ModalComplaintActionProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
  id: string;
  getData: () => void;
};


export const ModalComplaintAction: React.FC<ModalComplaintActionProps> = ({ isOpen, title, size, id, getData }) => {
  const { onDismiss } = useContext(ModalContext);
  const { lang } = useContext(ParamContext)
  const langConstants = useAppSelector(languageConstants)
  const optionDropDown = [...Array(7)].map((el, index) => { return { id: index + 1, value: (index + 1).toString(), label: langConstants[`L_COMPLAINT_MESSAGE_TITLE_TYPE_${index + 1}`] } })
  const [detail, setDetail] = useState(optionDropDown[0])


  const sendHandler = () => {
    onDismiss()
    toast.promise(MyApplication.actionToOrderPay(id, "complaint", lang, { "details": `${detail.id}` })
      .then(res => {
        if (res.data.type === "success") {
          getData()
          return res.data.text
        } else {
          throw res.data.text
        }

      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })

  }
  const selectComplaint = (item: number) => {
    const newDetail = optionDropDown.find(el => el.id === item)
    setDetail(newDetail ? newDetail : optionDropDown[0])
  }


  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      title={title}
      size={size}
    >
      <FormModal className="modal-complaint-action" onSubmit={sendHandler}>
        <FormModalBody>
          <ListForm
          >
            <ListFormItem >
              <SelectCustom
                label={langConstants["L_COMPLAINT_DETAILS"]}
                options={optionDropDown}
                defaultValue={detail}
                onChange={selectComplaint}
              />
            </ListFormItem>
          </ListForm>
        </FormModalBody>

        <FormModalFooter>
          <ButtonOutline onClick={onDismiss}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>{langConstants["L_SEND"]}</ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper >
  )
};
