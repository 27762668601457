import React from "react";
import "./tabs.scss";
import classNames from "classnames";
import { TabText } from "../Text";

type TabsProps = {

};


export const Tabs: React.FC<TabsProps> = ({
  children,
}) => (
  <div className="tabs">
    {children}
  </div>
);

type TabsListProps = {
  children: React.ReactNode;
};


export const TabsList: React.FC<TabsListProps> = ({
  children,
}) => (
  <div className="tabs-list">
    {children}
  </div>
);

export const TabsHeaderSelect: React.FC<TabsListProps> = ({
  children,
}) => (
  <div className="tabs-header-select">
    {children}
  </div>
);

type TabProps = {
  count?: number;
  active?: boolean;
  disable?: boolean;
  tabOnClick?: () => void;
  children: React.ReactNode;
  countOn?: boolean
};

export const Tab: React.FC<TabProps> = ({
  count = 0,
  active = false,
  disable = false,
  tabOnClick,
  children,
  countOn = false
}) => (
  <div className={`tab ${classNames(
    active ? "tab--active" : "",
   ( (count === 0 && countOn )|| disable) ? "tab--disable" : ""
  )}`}
    onClick={tabOnClick}
  >
    <TabText>{children} {countOn && <span className="tab__count">({count})</span>}</TabText>
  </div>
);

type TabPanelProps = {
  selected?: boolean;
  children: React.ReactNode;
};

export const TabPanel: React.FC<TabPanelProps> = ({
  selected = false,
  children,
}) => (
  <div className={`tab-panel ${classNames(selected ? "tab-panel--selected" : "")} `}>
    {children}
  </div>
);