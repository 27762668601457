import React from "react";
import classNames from "classnames";
import "./vertical-offset.scss";

type VerticalOffsetProps = {
  offset?: OffsetSize;
  children?: React.ReactNode;
  breakpoints?: {
    md?: VerticalOffsetTypes;
    lg?: VerticalOffsetTypes;
    xl?: VerticalOffsetTypes;
    xxl?: VerticalOffsetTypes;
  }
};

type VerticalOffsetTypes = {
  offset?: OffsetSize;
}

type OffsetSize =
  | "xs"
  | "2xs"
  | "s"
  | "2s"
  | "3s"
  | "m"
  | "2m"
  | "3m"
  | "4m"
  | "l"
  | "2l"
  | "xl";

export const VerticalOffset: React.FC<VerticalOffsetProps> = ({
  offset,
  children,
  breakpoints,
}) => {

  let breakpointsValue = "";

  breakpoints && Object.keys(breakpoints).forEach((key) => {

    const isKeyCorrected = key === "md" || key === "lg" || key === "xl" || key === "xxl";

    if (isKeyCorrected && breakpoints[key]?.offset) {
      breakpointsValue += `vertical-offset--${key}-${breakpoints[key]?.offset} `;
    }
  });

  return (
    <div
      className={`vertical-offset ${classNames(
        `vertical-offset--${offset}`,
        breakpointsValue
      )}`}
    >
      {children}
    </div>
  );
};
