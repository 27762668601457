import React, { useCallback, useEffect, useState } from "react";
import "./pagination.scss";
import classNames from "classnames";
import { ButtonIconOutline } from "../Button";
import { IconArrowChevronLeft, IconArrowChevronRight } from "../Icon";
import { BodyText } from "../Text";

type PaginationProps = {
  numOfPages: number;
  currentPage: number;
  onPageChange: (currentPage: number) => void;
};

export const Pagination: React.FC<PaginationProps> = ({
  numOfPages,
  currentPage,
  onPageChange,
}) => {

  const [disableForward, setDisableForward] = useState(false);
  const [disableBack, setDisableBack] = useState(false);
  const [numbersShow, setNumbersShow] = useState<(number|"...")[]>([])

  useEffect(() => {
    if (currentPage === numOfPages) {
      setDisableForward(true);
    } else {
      setDisableForward(false);
    }
    if (currentPage === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }
  }, [currentPage, numOfPages]);

  const createPagination = useCallback(() => {
    const numbers: number[] = [];
    for (let i = 0; i < numOfPages; ++i) {
      numbers.push(i + 1);
    }
    const lastItem = numbers.length-1
    if(numbers.length<=9){
      return numbers
    }else{
      const newNumbers: (number|"...")[] = []
      numbers.forEach((el, i) => {
        if( el === 1 || el === numbers[lastItem] || (el <= 7 && currentPage <= 4) || ( el >= numbers[lastItem] - 6 && currentPage >= numbers[lastItem] - 3) || (el >= currentPage-2 && el <= currentPage+2)) newNumbers.push(el)
        else if (newNumbers[newNumbers.length-1] !== "...") newNumbers.push("...")
        
      })
      return newNumbers
    }
  },[currentPage, numOfPages])
  
  useEffect(()=>{
    setNumbersShow(createPagination())
  },[createPagination])

  return (
    <div className="pagination">
      <ButtonIconOutline size="sm" onClick={() => onPageChange(currentPage - 1)} disabled={disableBack}>
        <IconArrowChevronLeft />
      </ButtonIconOutline>

      {numbersShow.map((num, i) => (
        <PaginationNumber key={`${num}` + i} isCurrent={currentPage === num} count={num === "..." ? undefined :num} onPageChange={onPageChange}>
          {num}
        </PaginationNumber>)
      )}

      <ButtonIconOutline size="sm" onClick={() => onPageChange(currentPage + 1)} disabled={disableForward}>
        <IconArrowChevronRight />
      </ButtonIconOutline>
    </div>
  )
};

type PaginationNumberProps = {
  isCurrent: boolean;
  children: React.ReactNode;
  onPageChange: (count: number) => void;
  count?: number;
};

export const PaginationNumber: React.FC<PaginationNumberProps> = ({
  isCurrent = false,
  children,
  onPageChange,
  count
}) => {
  const onClick = () => {
    if (count) onPageChange(count)
  }
  return (
    <div className={`pagination__number ${classNames(isCurrent ? "pagination__number--current" : "")}`} onClick={onClick}>
      <BodyText tag="span" color="secondary">
        {children}
      </BodyText>
    </div>
  )
}

