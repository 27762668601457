import React from "react";
import "./chips.scss";
import classNames from "classnames";
import { ChipsText } from "../Text";

type ChipsProps = {
  active?: boolean;
  disable?: boolean;
  onClick: () => void;
  children: string;
};


export const Chips: React.FC<ChipsProps> = ({
  active = false,
  disable = false,
  onClick,
  children,
}) => (
  <div className={`chips ${classNames(
    active ? "chips--active" : "",
    disable ? "chips--disable" : "",
  )}`} onClick={onClick}>
    <ChipsText>{children}</ChipsText>
  </div>
);