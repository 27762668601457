import React, { useContext, useEffect, useState } from "react";
import "./requisite.scss";
import { BodySmallText } from "../UI/Text";
import { ButtonFlatSecondary } from "../UI/Button";
import { IconArrowsCircle, IconBasicTrashFull, IconEdit } from "../UI/Icon";
import { IRequisite } from "../../models/IRequisite";
// import { ModalRequisiteCreat } from "../Modal/ModalRequisiteCreat";
import MyRequistes from "../../services/MyRequisitesService";
import { useAppSelector } from "../../app/hooks";
import { ParamContext } from "../../context/paramReducer";
// import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
// import { ModalContext } from "../Modal/ModalProvider";
import { languageConstants } from "../../features/settings/settingsSlice";
import { AxiosResponse } from "axios";
import { IMyRequisitesGlobeRequest } from "../../models/IMyRequisitesGlobeRequest";
import { IPaySystem } from "../../models/IPaySystem";
import { paySystemCurrency, paySystemGlobalCommission, paySystemGlobalPercentDepositP2P, paySystemGlobalPercentWithdrawP2P } from "../../features/requisit/paySystemsSlice";
import { Checkbox } from "../UI/Checkbox";
import { DropdownActions } from "../DropdownActions";
import { OperationState } from "../OperationState";
import { useNavigate } from "react-router-dom";

type RequisiteProps = {
  paySystem: IPaySystem
  data: IRequisite,
  currencyMessage?: string,
  getMainData: () => Promise<AxiosResponse<IMyRequisitesGlobeRequest>>
};

export const Requisite: React.FC<RequisiteProps> = ({ data, paySystem, currencyMessage, getMainData }) => {
  const { lang, setLoader } = useContext(ParamContext)
  const langConstants = useAppSelector(languageConstants);
  const currency = useAppSelector(paySystemCurrency);
  const commission = useAppSelector(paySystemGlobalCommission);
  const globalPercentDeposit = useAppSelector(paySystemGlobalPercentDepositP2P)
  const globalPercentWithdraw = useAppSelector(paySystemGlobalPercentWithdrawP2P)
  const currencyItem = currency.find(el => el.id === paySystem.currencyId);
  const [fullPrice, setFullPrice] = useState<number>()
  const navigate = useNavigate()

  useEffect(()=>{
    let fullCommission: number
    if (data.orderType === 1) {
      fullCommission = paySystem.percentWithdrawP2P + commission.withdraw + globalPercentWithdraw/100 + data.percent
      setFullPrice(+(1-fullCommission).toFixed(4))
    } else {
      fullCommission = paySystem.percentDepositP2P + commission.deposit + globalPercentDeposit/100 + data.percent
      setFullPrice(+(1/(1-fullCommission)).toFixed(4))
    }
  },[paySystem.percentWithdrawP2P, paySystem.percentDepositP2P, commission.withdraw, commission.deposit, globalPercentWithdraw, globalPercentDeposit, data.percent, data.orderType])

  const editHandler = () => {
    navigate('/create_ad', {
      state: data
    })
  }

  const deleteHandler = () => {
    toast.promise(MyRequistes.delRequisite(data.id, lang)
      .then(res => {
        if (res.data.type === "success") {
          getMainData().then(res => {
            if (res.data) setLoader(false)
          })
          return res.data.text
        } else {
          throw res.data.text
        }
      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  const restoreHandler = () => {
    toast.promise(MyRequistes.restoreRequisite(data.id, lang)
      .then(res => {
        if (res.data.type === "success") {
          getMainData().then(res => {
            if (res.data) setLoader(false)
          })
          return res.data.text
        } else {
          throw res.data.text
        }
      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  const userToggleRequisiteHandler = (value: boolean) => {
    toast.promise(MyRequistes.toggleRequisite(data.id, lang)
      .then(res => {
        if (res.data.type === "success") {
          getMainData().then(res => {
            if (res.data) setLoader(false)
          })
          return res.data.text
        } else {
          throw res.data.text
        }
      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })
  }

  return (
    <OperationState
      state={data.orderType}
      empty={data.sumOnAccount === 0}
    >
      <div className="requisite">
        <div className="requisite__name">
          <RequisiteInfo
            name={langConstants["L_TITLE_STRING"]}
            value={
              <BodySmallText title={data.name.length > 12 ? data.name : ""} color="primary">{data.name}</BodySmallText>
            }
          />
        </div>

        <div className="requisite__direction">
          <RequisiteInfo
            name={langConstants["L_BONUSES_LABEL"]}
            value={
              <BodySmallText color="primary">{fullPrice}</BodySmallText>
            }
          />
        </div>

        <div className="requisite__currency">
          <RequisiteInfo
            name={langConstants["L_CURRENCY"]}
            value={
              <BodySmallText color="primary">{currencyItem?.shortDisplayName}</BodySmallText>
            }
          />
        </div>

        <div className="requisite__payment-system">
          <RequisiteInfo
            name={langConstants["L_PAYMENTS_SYSTEM"]}
            value={
              <BodySmallText color="primary">{`${paySystem?.name} (${currencyItem?.shortName})` || ""}</BodySmallText>
            }
          />
        </div>
        <div className="requisite__applications-month">
          <RequisiteInfo
            name={langConstants["L_ORDERS_PER_MONTH"]}
            value={
              <BodySmallText color="primary">
                <span className="requisite__turnover-month-value">{data.count}</span>
                <span className="requisite__turnover-month-value">/</span>
                <span className="requisite__turnover-month-value">{data.countLimit}</span>
              </BodySmallText>
            }
          />

        </div>

        <div className="requisite__turnover-month">
          <RequisiteInfo
            name={langConstants["L_TURNOVER_PER_MONTH"]}
            value={
              <BodySmallText color="primary">
                <span className="requisite__turnover-month-value">{data.amount}</span>
                <span className="requisite__turnover-month-value">/</span>
                <span className="requisite__turnover-month-value">{data.amountLimit}</span>
              </BodySmallText>
            }
          />
        </div>

        <div className="requisite__actions">
          <div className="requisite-info">
            <div className="requisite-info__name">
              <BodySmallText color="secondary">
                {langConstants["L_ACTIONS"]}
              </BodySmallText>
            </div>

            <div className="requisite-info__value">
              <div className="requisite-actions">
                <Checkbox name={`requisiteActions${data.id}`} value="requisiteActions" checked={data.userEnable} onChange={userToggleRequisiteHandler} />
                <DropdownActions>
                  <ButtonFlatSecondary onClick={editHandler}>
                    <IconEdit />
                    <BodySmallText>{langConstants["L_EDIT"]}</BodySmallText>
                  </ButtonFlatSecondary>
                  {data.deleted ?
                    <ButtonFlatSecondary onClick={restoreHandler}>
                      <IconArrowsCircle />
                      <BodySmallText>{langConstants["L_RECOVER"]}</BodySmallText>
                    </ButtonFlatSecondary>
                    :
                    <ButtonFlatSecondary onClick={deleteHandler}>
                      <IconBasicTrashFull />
                      <BodySmallText>{langConstants["L_DELETE"]}</BodySmallText>
                    </ButtonFlatSecondary>
                  }
                </DropdownActions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OperationState>
  );
}

type RequisiteHeadProps = {

};

export const RequisiteHead: React.FC<RequisiteHeadProps> = () => {
  const langConstants = useAppSelector(languageConstants)

  return (
    <div className="requisite-head">
      <div className="requisite-head__name">
        <BodySmallText color="secondary">
          {langConstants["L_TITLE_STRING"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__direction">
        <BodySmallText color="secondary">
          {langConstants["L_PRICE"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__currency">
        <BodySmallText color="secondary">
          {langConstants["L_CURRENCY"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__payment-system">
        <BodySmallText color="secondary">
          {langConstants["L_PAYMENT_SYSTEM"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__applications-month">
        <BodySmallText color="secondary">
          {langConstants["L_ORDERS_PER_MONTH"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__turnover-month">
        <BodySmallText color="secondary">
          {langConstants["L_TURNOVER_PER_MONTH"]}
        </BodySmallText>
      </div>
      <div className="requisite-head__actions">
        <BodySmallText color="secondary">
          {langConstants["L_ACTIONS"]}
        </BodySmallText>
      </div>
    </div>
  );
}

type RequisiteListProps = {
  children: React.ReactNode;
};

export const RequisiteList: React.FC<RequisiteListProps> = ({
  children
}) => (
  <div className="requisite-list">
    {children}
  </div>
);


type RequisiteInfoProps = {
  name: string;
  value: React.ReactNode;
};

const RequisiteInfo: React.FC<RequisiteInfoProps> = ({
  name,
  value,
}) => (
  <div className="requisite-info">
    <div className="requisite-info__name">
      <BodySmallText color="secondary">{name}</BodySmallText>
    </div>

    <div className="requisite-info__value">
      {value}
    </div>
  </div>
);
