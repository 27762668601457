import React from "react";
import "./button.scss";
import classNames from "classnames";


type ButtonsProps = {
  children?: React.ReactNode;
  size?: SizeBtn;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
};
type ButtonFlatProps = {
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
};
type ButtonIconProps = {
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
};
type ButtonIconOtlineProps = {
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
  size?: "sm" | "md";
};
type ButtonLinkProps = {
  children?: React.ReactNode;
  url?: string;
  target?: "_blank";
  size?: SizeBtn;
};

export const ButtonLinkBase: React.FC<ButtonLinkProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link">
    {children}
  </Button>
);


export const ButtonLinkOutline: React.FC<ButtonLinkProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link" variant="outline">
    {children}
  </Button>
);

export const ButtonBase: React.FC<ButtonsProps> = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
);

export const ButtonSecondary: React.FC<ButtonsProps> = ({
  children, ...props
}) => (
  <Button variant="secondary" {...props}>
    {children}
  </Button>
);

export const ButtonDanger: React.FC<ButtonsProps> = ({
  children,
  ...props
}) => (
  <Button variant="danger" {...props}>
    {children}
  </Button>
);

export const ButtonDangerSecondary: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="danger-secondary" {...props}>
    {children}
  </Button>
);

export const ButtonOutline: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="outline" {...props}>
    {children}
  </Button>
);

export const ButtonFlat: React.FC<ButtonFlatProps> = ({ children, ...props }) => (
  <Button variant="flat" {...props}>
    {children}
  </Button>
);

export const ButtonFlatSecondary: React.FC<ButtonFlatProps> = ({ children, ...props }) => (
  <Button variant="flat-secondary" {...props}>
    {children}
  </Button>
);

export const ButtonIcon: React.FC<ButtonIconProps> = ({ children, ...props }) => (
  <Button variant="icon" {...props}>
    {children}
  </Button>
);

export const ButtonIconOutline: React.FC<ButtonIconOtlineProps> = ({ children, ...props }) => (
  <Button variant="icon-outline" {...props}>
    {children}
  </Button>
);

type ButtonProps = {
  children?: React.ReactNode;
  variant?: Variant;
  size?: SizeBtn;
  type?: "button" | "submit" | "reset" | "link";
  disabled?: boolean;
  onClick?: () => void;
  url?: string;
  target?: "_blank";
};

type SizeBtn = "block" | "sm" | "md";
type Variant = "outline" | "icon" | "flat" | "flat-secondary" | "secondary" | "icon-outline" | "danger" | "danger-secondary";

export const Button: React.FC<ButtonProps> = ({
  children,
  size,
  onClick,
  variant,
  type = "button",
  url = "#",
  target,
  disabled = false,
  ...props
}) => (
  <>
    {type === "button" && (
      <button
        className={`btn ${classNames(
          size ? `btn--${size}` : "",
          variant ? `btn--${variant}` : ""
        )}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )}

    {type === "submit" && (
      <button
        className={`btn ${classNames(
          size ? `btn--${size}` : "",
          variant ? `btn--${variant}` : ""
        )}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )}

    {
      type === "link" && (
        <a
          href={url}
          className={`btn ${classNames(
            size ? `btn--${size}` : "",
            variant ? `btn--${variant}` : ""
          )}`}
          role="button"
          target={target}
          {...props}
        >
          {children}
        </a>
      )
    }
  </>
);
