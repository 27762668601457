import { AxiosResponse } from "axios";
import {api} from "../api";
import { IDateSearch } from "../models/IGlobe";
import { IReportsRequest, IReportsStartRequest } from "../models/IReport";
import Services from "./Services";

export default class Report {
    static async getData(
        paySystems: string[],
        checkNames: string[],
        date: IDateSearch,
        allPaySystems: boolean,
        lang: "en" | "ru"
    ): Promise<AxiosResponse<IReportsRequest>> {
        const url = Services.getURL("/api/market/get-report-data?", lang)
        let dataURL = `fromDate=${date.dateFrom}&toDate=${date.dateTo}`;
        if (paySystems.length > 0) {
            for (const el of paySystems) {
                dataURL = dataURL + `&pss[]=${el}`
            }
        }
        if (checkNames.length > 0) {
            for (const el of checkNames) {
                dataURL = dataURL + `&upds[]=${el}`
            }
        }
        if(allPaySystems) dataURL = dataURL + `&updAll=on`
        const searchParams = new URLSearchParams(dataURL);
        return api.get<IReportsRequest>(`${url}${searchParams}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
    static async getUpds(
        lang: "en" | "ru"
        ): Promise<AxiosResponse<IReportsStartRequest>> {
        const url = Services.getURL("/api/market/get-report-data?", lang)
        const dataURL = "withUpd=true&withPaymentSystems=true"
        const searchParams = new URLSearchParams(dataURL);
        return api.get<IReportsStartRequest>(`${url}${searchParams}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
}