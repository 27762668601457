import React, { useContext, useEffect, useState } from "react";
import "./page-reports-info.scss";
import { ListChips } from "../../../../components/ListChips";
import { Chips } from "../../../../components/UI/Chips";
import { CalendarCustom } from "../../../../components/CalendarCustom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ButtonBase } from "../../../../components/UI/Button";
import { ButtonText } from "../../../../components/UI/Text";
import { IDateSearch } from "../../../../models/IGlobe";
import Report from "../../../../services/ReportService";
import Services from "../../../../services/Services";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { ParamContext } from "../../../../context/paramReducer";

type PageReportsInfoProps = {
  onOpen: () => void;
  open: boolean;
  getData: (
    paySystems: string[],
    checkNames: string[],
    date: IDateSearch,
    allPaySystems: boolean,
    langConstantsInto: { [index: string]: string }
  ) => void;
};

export const PageReportsInfo: React.FC<PageReportsInfoProps> = ({
  getData,
  onOpen,
  open,
}) => {
  const { lang } = useContext(ParamContext);
  const [paySystems, setPaySystems] = useState<{ id: string; name: string }[]>(
    []
  );
  const [allPaySystemActive, setAllPaySystemActive] = useState<boolean>(true);
  const [allAccountNamesActive, setAllAccountNamesActive] =
    useState<boolean>(true);
  const [accountNames, setAccountNames] = useState<
    { id: number; name: string; paymentSystemId: number }[]
  >([]);

  const [filterPaySystems, setFilterPaySystems] = useState<string[]>([]);
  const [filterAccountNames, setFilterAccountNames] = useState<string[]>([]);
  const dateFromStart = new Date();
  dateFromStart.setMonth(dateFromStart.getMonth() - 1);
  const [date, setDate] = useState<Date[] | null>(null);
  const dateFrom = date?.[0] ? Services.getDataForSearch(date[0]) : null;
  const dateTo = date?.[1] ? Services.getDataForSearch(date[1]) : null;
  const [filterDate, setFilterDate] = useState<IDateSearch>({
    dateFrom,
    dateTo,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const langConstants = useAppSelector(languageConstants);
  const [firstRequest, setFirstRequest] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const choseAllPaySystems = () => {
    const paySystemNumbers: string[] = [];
    if (filterPaySystems.length === paySystems.length) {
      setFilterPaySystems(paySystemNumbers);
    } else {
      paySystems.forEach((el) => paySystemNumbers.push(el.id));
      setFilterPaySystems(paySystemNumbers);
    }
  };
  const choseAllAccountNames = () => {
    const accountNamesNumbers: string[] = [];
    if (filterAccountNames.length === accountNames.length) {
      setFilterAccountNames(accountNamesNumbers);
    } else {
      accountNames.forEach((el) => accountNamesNumbers.push(el.id.toString()));
      setFilterAccountNames(accountNamesNumbers);
    }
  };
  useEffect(() => {
    if (filterPaySystems && paySystems) {
      if (filterPaySystems.length === paySystems.length) {
        setAllPaySystemActive(true);
      } else {
        setAllPaySystemActive(false);
      }
    }
  }, [filterPaySystems, paySystems]);

  useEffect(() => {
    if (filterAccountNames && accountNames) {
      if (filterAccountNames.length === accountNames.length) {
        setAllAccountNamesActive(true);
      } else {
        setAllAccountNamesActive(false);
      }
    }
  }, [filterAccountNames, accountNames]);

  const showHandler = () => {
    getData(
      filterPaySystems,
      filterAccountNames,
      filterDate,
      allPaySystemActive,
      langConstants
    );
    onOpen();
  };

  const paySystemHandler = (id: string) => {
    const itemInclude = filterPaySystems.indexOf(id);
    const newFilterPaySystems: string[] = [...filterPaySystems];
    if (itemInclude !== -1) {
      newFilterPaySystems.splice(itemInclude, 1);
    } else {
      newFilterPaySystems.push(id);
    }
    setFilterPaySystems(newFilterPaySystems);
  };

  const accountNamesHandler = (id: string) => {
    const itemInclude = filterAccountNames.indexOf(id);
    const newFilterAccountName: string[] = [...filterAccountNames];
    if (itemInclude !== -1) {
      newFilterAccountName.splice(itemInclude, 1);
    } else {
      newFilterAccountName.push(id);
    }
    setFilterAccountNames(newFilterAccountName);
  };

  useEffect(() => {
    if (firstRequest) {
      if (Object.values(langConstants).length > 0) {
        Report.getUpds(lang).then((res) => {
          setPaySystems(res.data.paymentSystems);
          const paySystemNumbers: string[] = [];
          if (res.data.paymentSystems)
            res.data.paymentSystems.forEach((el) =>
              paySystemNumbers.push(el.id)
            );
          setFilterPaySystems(paySystemNumbers);
          setAccountNames(res.data.upds);
          const accountNamesNumbers: string[] = [];
          if (res.data.upds)
            res.data.upds.forEach((el) =>
              accountNamesNumbers.push(el.id.toString())
            );
          setFilterAccountNames(accountNamesNumbers);
          const dateFrom = date?.[0]
            ? Services.getDataForSearch(date[0])
            : null;
          const dateTo = date?.[1] ? Services.getDataForSearch(date[1]) : null;
          const newDate = { dateFrom, dateTo };
          getData(
            paySystemNumbers,
            accountNamesNumbers,
            newDate,
            true,
            langConstants
          );
          setLoaded(true);
          setFirstRequest(false);
        });
      }
    }
  }, [getData, lang, dispatch, date, langConstants, open, firstRequest]);

  return (
    <>
      {open && (
        <div className="page-reports-info">
          <div className="page-reports-info__top">
            <CalendarCustom
              setDateState={setFilterDate}
              setInitDate={setDate}
              initDate={date}
              label={langConstants["L_SELECT_DATE_OPERATIONS"]}
            />
          </div>
          {loaded && (
            <div className="page-reports-info__body">
              <ListChips title={langConstants["L_BANKS"]}>
                <Chips onClick={choseAllPaySystems} active={allPaySystemActive}>
                  {langConstants["L_ALL"]}
                </Chips>
                {paySystems &&
                  paySystems.length > 0 &&
                  paySystems.map((el) => {
                    const itemActive = filterPaySystems.includes(el.id);
                    const clickHandler = () => {
                      paySystemHandler(el.id);
                    };
                    return (
                      <Chips
                        key={el.id}
                        onClick={clickHandler}
                        active={itemActive}
                      >
                        {el.name}
                      </Chips>
                    );
                  })}
              </ListChips>

              <ListChips title={langConstants["L_NAMES_ACCOUNTS"]}>
                <Chips
                  active={allAccountNamesActive}
                  onClick={choseAllAccountNames}
                >
                  {langConstants["L_ALL"]}
                </Chips>
                {accountNames &&
                  accountNames.length > 0 &&
                  accountNames.map((el) => {
                    const showItem = filterPaySystems.includes(
                      el.paymentSystemId.toString()
                    );
                    if (showItem) {
                      const itemActive = filterAccountNames.includes(
                        el.id.toString()
                      );
                      const clickHandler = () => {
                        accountNamesHandler(el.id.toString());
                      };
                      return (
                        <Chips
                          key={el.id}
                          active={itemActive}
                          onClick={clickHandler}
                        >
                          {el.name}
                        </Chips>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ListChips>
              <div className="page-history-operation-search-options__form-bottom">
                <ButtonBase size="sm" type="button" onClick={showHandler}>
                  <ButtonText>{langConstants["L_SHOW"]}</ButtonText>
                </ButtonBase>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
