import React, { useContext, useEffect, useState } from "react";
import "./page-create-ad-form-sell-buy.scss";
import { BodyLightText, ButtonText } from "../../../../components/UI/Text";
import { Toggle } from "../../../../components/UI/Toggle";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeData,
  changeDataPaymentSystemId,
  clearData,
  paySystemCurrency,
  paySystemData,
  paySystemDeposit,
  paySystemFields,
  paySystemFilterComponents,
  paySystemGlobalCommission,
  paySystemGlobalPercentDepositP2P,
  paySystemGlobalPercentWithdrawP2P,
  paySystemList,
} from "../../../../features/requisit/paySystemsSlice";
import { ButtonBase, ButtonOutline } from "../../../../components/UI/Button";
import { ListForm, ListFormItem } from "../../../../components/ListForm";
import { ParamContext } from "../../../../context/paramReducer";
import { Input } from "../../../../components/UI/Input";
import { SelectCustom } from "../../../../components/UI/SelectCustom";
import { Tooltips } from "../../../../components/UI/Tooltips";
import { IconHelp } from "../../../../components/UI/Icon";
import { IRequisite } from "../../../../models/IRequisite";
import { useNavigate } from "react-router-dom";
import {
  createErrorsValue,
  createInputsValue,
  errorsValue,
  inputsValue,
} from "../../../../features/requisit/validationSlice";
import { IObjectString } from "../../../../models/IObjectString";
import { IPaySystem } from "../../../../models/IPaySystem";
import { FieldPaySystem } from "../../../../components/FieldPaySystem";
import { toast } from "react-toastify";
import MyRequistes from "../../../../services/MyRequisitesService";
import { AxiosResponse } from "axios";
import { IMyRequisitesGlobeRequest } from "../../../../models/IMyRequisitesGlobeRequest";
import { ICurrency } from "../../../../models/ICurrency";

type PageCreateAdFormSellBuyProps = {
  data: IRequisite | null;
  isSell: boolean;
  getMainData: () => Promise<AxiosResponse<IMyRequisitesGlobeRequest>>;
};

// const verifyUsersRu = [
//   { id: 0, value: 0, label: "нет" },
//   { id: 1, value: 1, label: "да" },
// ];
// const verifyUsersEn = [
//   { id: 0, value: 0, label: "no" },
//   { id: 1, value: 1, label: "yes" },
// ];

export const PageCreateAdFormSellBuy: React.FC<PageCreateAdFormSellBuyProps> =
  ({ isSell, data, getMainData }) => {
    const { lang, setLoader } = useContext(ParamContext);
    const langConstants = useAppSelector(languageConstants);
    const allFieldsForPaySystems = useAppSelector(paySystemFields);
    const paySystemsList = useAppSelector(paySystemList);
    const currency = useAppSelector(paySystemCurrency);
    const paySystemsDeposit = useAppSelector(paySystemDeposit);
    const errorsDetailValue = useAppSelector(errorsValue);
    const commission = useAppSelector(paySystemGlobalCommission);
    const globalPercentDeposit = useAppSelector(
      paySystemGlobalPercentDepositP2P
    );
    const globalPercentWithdraw = useAppSelector(
      paySystemGlobalPercentWithdrawP2P
    );
    const filterComponents = useAppSelector(paySystemFilterComponents);
    const publicDetailsState = useAppSelector(inputsValue);
    const dataRedux = useAppSelector(paySystemData);
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const [percent, setPercent] = useState<number | string>(0);
    const [timeout, setTimeout] = useState(15);
    const [timeoutNoti, setTimeoutNoti] = useState("");
    const dataState = data ? data : dataRedux;
    const [changeCurrency, setChangeCurrency] = useState(false);
    let fullCommission: number;
    let finalCommission: number;
    let price: number;

    // const verifyUsers = lang === "en" ? verifyUsersEn : verifyUsersRu;

    const updatePaySystemList = Object.values(paySystemsList).map(
      (el: IPaySystem) => {
        const currencyItem = currency.find((item) => item.id === el.currencyId);
        return {
          ...el,
          value: el.name,
          label: `${el.name} (${currencyItem?.shortName})`,
        };
      }
    );
    const [paySystemShowList, setPaySystemShowList] =
      useState(updatePaySystemList);

    const getCurrencies = () => {
      const currencyIdArr: ICurrency[] = [];
      Object.values(paySystemsList).forEach((el) => {
        const isDouble = currencyIdArr.find(
          (item) => item.id === el.currencyId
        );
        if (!isDouble) {
          const currencyItem = currency.find(
            (item) => item.id === el.currencyId
          );
          if (currencyItem) currencyIdArr.push(currencyItem);
        }
      });
      return currencyIdArr;
    };

    const updateCurrencyList = getCurrencies().map((el: ICurrency) => {
      return { ...el, value: el.name, label: el.name };
    });

    const [paySystemElement, setPaySystemElement] = useState(
      updatePaySystemList.find((el) => el.id === dataState.paymentSystemId) ||
        updatePaySystemList[0]
    );
    dispatch(changeDataPaymentSystemId(paySystemElement.id));

    if (!isSell) {
      fullCommission =
        (paySystemsList[paySystemElement.id].percentWithdrawP2P +
          commission?.withdraw) *
        100;
      finalCommission =
        Math.round(
          ((paySystemsList[paySystemElement.id].percentWithdrawP2P +
            commission?.withdraw) *
            100 +
            globalPercentWithdraw +
            +percent) *
            1000
        ) / 1000;
        price = +(1-finalCommission/100).toFixed(4)
    } else {
      fullCommission =
        (paySystemsList[paySystemElement.id].percentDepositP2P +
          commission?.deposit) *
        100;
      finalCommission =
        Math.round(
          ((paySystemsList[paySystemElement.id].percentDepositP2P +
            commission?.deposit) *
            100 +
            globalPercentDeposit +
            +percent) *
            1000
        ) / 1000;
        price = +(1/(1-finalCommission/100)).toFixed(4)
      }
    const [currencyElement, setCurrencyElement] = useState(
      updateCurrencyList.find((el) => el.id === paySystemElement.currencyId)
    );

    const optionDropDown = [
      {
        value: "1",
        label:
          currencyElement?.shortDisplayName ||
          langConstants["L_SELECT_CURRENCY"],
      },
      {
        value: "2",
        label: langConstants["L_SELECT_COUNTRY"],
      },
      {
        value: "3",
        label: langConstants["L_SELECT_CURRENCY"],
      },
    ];

    useEffect(() => {
      if (data) {
        setPercent(Math.floor(data.percent * 100000)/1000);
        setTimeout(data.timeout / 60);
        dispatch(changeData(data));
        dispatch(createInputsValue(data.publicDetails));
      }
      return () => {
        dispatch(createErrorsValue({}));
        dispatch(clearData());
      };
    }, [dispatch, data]);

    useEffect(() => {
      if (!data) {
        const fields = allFieldsForPaySystems[dataRedux.paymentSystemId];
        const newPublicDetails: IObjectString = {};
        for (const element in fields) {
          if (
            (paySystemsDeposit && fields[element].forDeposit) ||
            (!paySystemsDeposit && fields[element].forWithdraw)
          ) {
            newPublicDetails[fields[element].fieldId] = "";
          }
        }
        dispatch(createInputsValue(newPublicDetails));
      }
    }, [
      dispatch,
      dataRedux.paymentSystemId,
      allFieldsForPaySystems,
      paySystemsDeposit,
      data,
    ]);

    const nameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeData({ ...dataRedux, name: e.target.value }));
    };
    const currencyHandler = (id: number) => {
      setChangeCurrency(true);
      setCurrencyElement(updateCurrencyList.find((el) => el.id === id));
      const newPaySystemShow = updatePaySystemList.filter(
        (el) => el.currencyId === id
      );
      setPaySystemShowList(newPaySystemShow);
      setPaySystemElement(newPaySystemShow[0]);
    };
    const paySystemIdHandler = (id: number) => {
      dispatch(
        changeData({ ...dataRedux, paymentSystemId: id, publicDetails: {} })
      );
      const newPaySystemChose = updatePaySystemList.find((el) => el.id === id);
      if (newPaySystemChose) {
        setPaySystemElement(newPaySystemChose);
        setCurrencyElement(
          updateCurrencyList.find(
            (el) => el.id === newPaySystemChose.currencyId
          )
        );
        optionDropDown[0].value =
          updateCurrencyList.find(
            (el) => el.id === newPaySystemChose.currencyId
          )?.shortDisplayName || langConstants["L_SELECT_CURRENCY"];
      }
    };

    const userEnableHandler = (value: boolean) => {
      dispatch(changeData({ ...dataRedux, userEnable: value }));
    };
    // const selectFromUserLevelHandler = (id: number) => {
    //   dispatch(changeData({ ...dataRedux, fromUserLevel: id }));
    // };
    const timeoutHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const timeout = e.target.value.replace(/[^0-9]/g, '')
      if (+timeout > 120) setTimeoutNoti(langConstants["L_TIME_LIMIT_CANNOT_BE_MORE_THAN_HOURS"].replace(/:value/, "120"))
      else setTimeoutNoti("")
      setTimeout(+timeout);
    };
    const percentHandlerBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPercent(+e.target.value);
    };
    const percentHandlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPercent(e.target.value.replace(/[^0-9.-]/g, ''));
    };

    const amountLimitHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amountLimit = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, amountLimit: +amountLimit }));
    };
    const amountLimitDayHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amountLimitDay = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, amountLimitDay: +amountLimitDay }));
    };
    const countLimitHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const countLimit = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, countLimit: +countLimit }));
    };
    const countLimitShortHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const countLimitShort = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, countLimitShort: +countLimitShort }));
    };
    const sumOnAccountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const sumOnAccount = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, sumOnAccount: +sumOnAccount }));
    };
    const amountMinHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amountMin = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, amountMin: +amountMin }));
    };
    const amountMaxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amountMax = e.target.value.replace(/[^0-9]/g, '')
      dispatch(changeData({ ...dataRedux, amountMax: +amountMax }));
    };
    const cancelHandler = () => {
      getMainData().then((res) => {
        if (res.data) setLoader(false);
      });
      history("/marketio_upds");
    };
    const successCloseHandler = () => {
      dispatch(clearData());
      dispatch(createInputsValue({}));
      getMainData().then((res) => {
        if (res.data) setLoader(false);
      });
      history("/marketio_upds");
    };
    const saveHandler = (e: React.FormEvent) => {
      e.preventDefault();
      if (Object.keys(errorsDetailValue).length === 0 && !timeoutNoti) {
        if (!data) {
          toast.promise(
            MyRequistes.createRequisite(
              {
                ...dataRedux,
                publicDetails: publicDetailsState,
                percent: +percent / 100,
                timeout: timeout * 60,
              },
              lang
            ).then((res) => {
              if (res.data.type === "success") {
                successCloseHandler();
                return res.data.text;
              } else {
                throw res.data.text;
              }
            }),
            // .catch(err => {
            //   console.log(err);
            // })
            {
              pending: langConstants["L_LOADING_DATA"],
              success: {
                render({ data }) {
                  return data;
                },
              },
              error: {
                render({ data }) {
                  return data;
                },
              },
            }
          );
        } else {
          toast.promise(
            MyRequistes.updateRequisite(
              {
                ...dataRedux,
                publicDetails: publicDetailsState,
                percent: +percent / 100,
                timeout: timeout * 60,
              },
              filterComponents,
              lang
            ).then((res) => {
              if (res.data.type === "success") {
                successCloseHandler();
                return res.data.text;
              } else {
                throw res.data.text;
              }
            }),
            //   .catch(err => {
            //   console.log(err);
            // })
            {
              pending: langConstants["L_LOADING_DATA"],
              success: {
                render({ data }) {
                  return data;
                },
              },
              error: {
                render({ data }) {
                  return data;
                },
              },
            }
          );
        }
      } else {
        toast.error("Проверьте правильность заполнения полей");
      }
    };

    return (
      <form
        className="page-create-ad-form-sell-buy"
        onSubmit={saveHandler}
        action=""
        method="post"
      >
        <div className="page-create-ad-form-sell-buy__top">
          {dataRedux.userEnable ? (
            <BodyLightText>{langConstants["L_ON"]}</BodyLightText>
          ) : (
            <BodyLightText>{langConstants["L_TURNED_OFF"]}</BodyLightText>
          )}
          <Toggle
            onChange={userEnableHandler}
            checked={dataRedux.userEnable}
            name="toggleOne"
          />
        </div>

        <div className="page-create-ad-form-sell-buy__body">
          <ListForm>
            {isSell && (
              <ListFormItem>
                <Input
                  label={langConstants["L_NAME"]}
                  name="input_name_sell"
                  placeholder={langConstants["L_ENTER_TITLE"]}
                  type="text"
                  value={dataRedux.name}
                  onChange={nameHandler}
                  required={true}
                />
              </ListFormItem>
            )}
            <ListFormItem>
              <SelectCustom
                label={langConstants["L_CURRENCY_PRODUCT"]}
                options={optionDropDown}
                defaultValue={optionDropDown[0]}
                disabled={true}
                onChange={() => {}}
                icon={
                  <Tooltips
                    content={langConstants["L_CURRENCY_PRODUCT_HINT"]}
                    placement="top"
                    key="10"
                  >
                    <IconHelp />
                  </Tooltips>
                }
              />

              <SelectCustom
                label={langConstants["L_COUNTRY_SELECTION"]}
                options={optionDropDown}
                defaultValue={optionDropDown[1]}
                disabled={true}
                onChange={() => {}}
              />

              <SelectCustom
                label={langConstants["L_PAYMENT_SYSTEM_CURRENCY"]}
                options={updateCurrencyList}
                defaultValue={
                  changeCurrency || data ? currencyElement : optionDropDown[2]
                }
                disabled={data ? true : false}
                onChange={currencyHandler}
              />

              <SelectCustom
                label={langConstants["L_PAYMENT_SYSTEM"]}
                options={paySystemShowList}
                defaultValue={paySystemElement}
                disabled={data ? true : false}
                onChange={paySystemIdHandler}
              />
            </ListFormItem>
          </ListForm>

          <ListForm title={langConstants["L_MY_ESSENTIAL_ELEMENTS"]}>
            <ListFormItem>
              <FieldPaySystem
                createNew={data ? false : true}
                paySystemId={dataRedux.paymentSystemId}
                publicDetails={dataRedux.publicDetails}
                isSell={isSell}
              />
            </ListFormItem>
          </ListForm>

          <ListForm title={langConstants["L_SETTINGS"]}>
            <ListFormItem>
              {/* <SelectCustom
                label={langConstants["L_ONLY_FOR_VERIFIED"]}
                options={verifyUsers}
                defaultValue={verifyUsers[dataRedux.fromUserLevel]}
                onChange={selectFromUserLevelHandler}
                icon={
                  <Tooltips
                    content={langConstants["L_ONLY_FOR_VERIFIED_HINT"]}
                    placement="top"
                    key="10"
                  >
                    <IconHelp />
                  </Tooltips>
                }
              /> */}

              <Input
                label={
                  langConstants["L_TIME_LIMIT"] +
                  " (" +
                  langConstants["L_DATE_M"] +
                  ")"
                }
                name="input_timeout"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${timeout}`}
                required={true}
                notification={timeoutNoti}
                error={timeoutNoti ? true : false}
                icon={
                  <Tooltips
                    content={langConstants["L_TIME_LIMIT_HINT"]}
                    placement="top"
                    key="9"
                  >
                    <IconHelp />
                  </Tooltips>
                }
                onChange={timeoutHandler}
              />

              <Input
                label={langConstants["L_MY_COMMISSION"] + "(%)"}
                name="input_percent"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${percent}`}
                required={true}
                icon={
                  <Tooltips
                    content={langConstants["L_PERCENT_HINT"]}
                    placement="top"
                    key="7"
                  >
                    <IconHelp />
                  </Tooltips>
                }
                onBlur={percentHandlerBlur}
                onChange={percentHandlerChange}
              />
              <Input
                label={langConstants["L_COMMISSION_MATBEA"]}
                name="input_percent_matbea"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${fullCommission}`}
                disabled
                icon={
                  <Tooltips
                    content={langConstants["L_TITLE_HINT"]}
                    placement="top"
                    key="8"
                  >
                    <IconHelp />
                  </Tooltips>
                }
              />
              <Input
                label={langConstants["L_TOTAL_PERCENTAGE"]}
                name="input_final_percent"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${finalCommission}`}
                disabled
                icon={
                  <Tooltips
                    content={langConstants["L_TOTAL_PERCENTAGE_HINT"]}
                    placement="top"
                    key="11"
                  >
                    <IconHelp />
                  </Tooltips>
                }
              />
              <Input
                label={
                  langConstants["L_PRICE"] + " " + currencyElement?.shortName
                }
                name="input_price"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${price}`}
                disabled
                icon={
                  <Tooltips
                    content={langConstants["L_PRICE_HINT"]}
                    placement="top"
                    key="12"
                  >
                    <IconHelp />
                  </Tooltips>
                }
              />
              {isSell && (
                <>
                  <Input
                    label={langConstants["L_TURNOVER"]}
                    name="input_turnover_total2"
                    placeholder={langConstants["L_ENTER_VALUE"]}
                    type="text"
                    value={`${dataRedux.amountLimit}`}
                    required={true}
                    icon={
                      <Tooltips
                        content={langConstants["L_TURNOVER_HINT"]}
                        placement="top"
                        key="1"
                      >
                        <IconHelp />
                      </Tooltips>
                    }
                    onChange={amountLimitHandler}
                  />

                  <Input
                    label={langConstants["L_TURNOVER_DAY"]}
                    name="input_turnover_day2"
                    placeholder={langConstants["L_ENTER_VALUE"]}
                    type="text"
                    value={`${dataRedux.amountLimitDay}`}
                    required={true}
                    icon={
                      <Tooltips
                        content={langConstants["L_TURNOVER_DAY_HINT"]}
                        placement="top"
                        key="2"
                      >
                        <IconHelp />
                      </Tooltips>
                    }
                    onChange={amountLimitDayHandler}
                  />

                  <Input
                    label={langConstants["L_COUNT_LIMIT"]}
                    name="input_limit_total2"
                    placeholder={langConstants["L_ENTER_VALUE"]}
                    type="text"
                    value={`${dataRedux.countLimit}`}
                    required={true}
                    icon={
                      <Tooltips
                        content={langConstants["L_COUNT_LIMIT_HINT"]}
                        placement="top"
                        key="3"
                      >
                        <IconHelp />
                      </Tooltips>
                    }
                    onChange={countLimitHandler}
                  />

                  <Input
                    label={langConstants["L_COUNT_LIMIT_SHORT"]}
                    name="input_limit_day2"
                    placeholder={langConstants["L_ENTER_VALUE"]}
                    type="text"
                    value={`${dataRedux.countLimitShort}`}
                    required={true}
                    icon={
                      <Tooltips
                        content={langConstants["L_COUNT_LIMIT_SHORT_HINT"]}
                        placement="top"
                        key="4"
                      >
                        <IconHelp />
                      </Tooltips>
                    }
                    onChange={countLimitShortHandler}
                  />
                </>
              )}
              {!isSell && (
                <Input
                  label={langConstants["L_RESERVE_ON_CARD"]}
                  name="input_percent"
                  placeholder={langConstants["L_ENTER_VALUE"]}
                  type="text"
                  value={`${dataRedux.sumOnAccount}`}
                  required={true}
                  onChange={sumOnAccountHandler}
                />
              )}

              <Input
                label={langConstants["L_AMOUNT_LOW"]}
                name="input_min_amount2"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${dataRedux.amountMin}`}
                required={true}
                icon={
                  <Tooltips
                    content={langConstants["L_MIN_AMOUNT_HINT"]}
                    placement="top"
                    key="5"
                  >
                    <IconHelp />
                  </Tooltips>
                }
                onChange={amountMinHandler}
              />

              <Input
                label={langConstants["L_AMOUNT_HIGH"]}
                name="input_maximum_amount2"
                placeholder={langConstants["L_ENTER_VALUE"]}
                type="text"
                value={`${dataRedux.amountMax}`}
                required={true}
                icon={
                  <Tooltips
                    content={langConstants["L_MAX_AMOUNT_HINT"]}
                    placement="top"
                    key="6"
                  >
                    <IconHelp />
                  </Tooltips>
                }
                onChange={amountMaxHandler}
              />
            </ListFormItem>
          </ListForm>
        </div>

        <div className="page-create-ad-form-sell-buy__bottom">
          <ButtonOutline onClick={cancelHandler}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>{langConstants["L_SAVE"]}</ButtonText>
          </ButtonBase>
        </div>
      </form>
    );
  };
