import React, { useContext } from "react";
import { useAppSelector } from "../../../app/hooks";
import { paySystemFields } from "../../../features/requisit/paySystemsSlice";
import { IHistoryOrder } from "../../../models/IHistory";
import { ListInfo, ListInfoItem } from "../../ListInfo";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { VerticalOffset } from "../../Grid/VerticalOffset";
import { ModalBody } from "../../ModalBody";

type ModalHistoryRequisiteProps = {
  data: IHistoryOrder;
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
};

export const ModalHistoryRequisite: React.FC<ModalHistoryRequisiteProps> = ({ data, isOpen, title, size }) => {
  const { onDismiss } = useContext(ModalContext);
  const allFieldsForPaySystems = useAppSelector(paySystemFields)
  const fields = allFieldsForPaySystems[data.paymentSystemId]
  const langConstants = useAppSelector(languageConstants)

  const mainData = {
    agencyDetails: data.depositDetails,
    clientDetails: data.withdrawDetails
  }
  if (data.type !== 1) {
    mainData.agencyDetails = data.withdrawDetails
    mainData.clientDetails = data.depositDetails
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      title={title}
      size={size}
    >
      <ModalBody>
        <VerticalOffset offset="2l">
          <ListInfo title={langConstants["L_ESSENTIAL_ELEMENTS_OF_COUNTERAGENT"]}>
            {fields.map(el => {
              if (el.fieldId !== 9 && data.type === 1 && el.forWithdraw) {
                return (
                  <ListInfoItem
                    key={el.fieldId}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                    value={mainData.agencyDetails[el.fieldId]}
                  />
                )
              } else if (el.fieldId !== 9 && data.type === 2 && el.forDeposit) {
                return (
                  <ListInfoItem
                    key={el.fieldId}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                    value={mainData.agencyDetails[el.fieldId]}
                  />
                )
              } else {
                return null
              }

            })}

            <ListInfoItem
              name={langConstants["L_PS_DETAILS_FIELD_9"]}
              value={mainData.agencyDetails[9]}
            />
          </ListInfo>

          <ListInfo title={langConstants["L_ESSENTIAL_ELEMENTS_OF_CLIENT"]}>
            {fields.map(el => {
              if (el.fieldId !== 9 && data.type === 2 && el.forWithdraw) {
                return (
                  <ListInfoItem
                    key={el.fieldId}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                    value={mainData.clientDetails[el.fieldId]}
                  />
                )
              } else if (el.fieldId !== 9 && data.type === 1 && el.forDeposit) {
                return (
                  <ListInfoItem
                    key={el.fieldId}
                    name={langConstants[`L_PS_DETAILS_FIELD_${el.fieldId}`]}
                    value={mainData.clientDetails[el.fieldId]}
                  />
                )
              } else {
                return null
              }
            })}

            <ListInfoItem
              name={langConstants["L_PS_DETAILS_FIELD_9"]}
              value={mainData.clientDetails[9]}
            />
          </ListInfo>
        </VerticalOffset>
      </ModalBody>
    </ModalWrapper>
  )
};
