import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./chat.scss";
import { ChatList, ChatListItem } from "./components/ChatList";
import { ChatTextArea } from "./components/ChatTextArea";
import { ChatDocument } from "./components/ChatDocument";
import { Listbids, ListbidsItem } from "./components/Listbids";
import { H2 } from "../UI/Text";
import { ButtonFlat } from "../UI/Button";
import { IconArrowChevronDown, IconArrowChevronLeft } from "../UI/Icon";
import { IChatInfo, IChatMessages } from "../../models/ISupport";
import Support from "../../services/SuppotrService";
import { useAppSelector } from "../../app/hooks";
import Services from "../../services/Services";
import { languageConstants } from "../../features/settings/settingsSlice";
import { ParamContext } from "../../context/paramReducer";

type ChatProps = {
  chatsInfo: IChatInfo[],
  supportMessageNumber: number
};

export const Chat: React.FC<ChatProps> = ({ chatsInfo, supportMessageNumber }) => {
  const { lang, setLoader, chatBarOpen, setChatBarOpen } = useContext(ParamContext)
  const length: number = 20
  const start: number = 0
  const [messages, setMessages] = useState<{ [index: string]: IChatMessages[] }>({})
  const [activeChat, setActiveChat] = useState<string>("0")
  const [files, setFiles] = useState<FileList>()
  const langConstants = useAppSelector(languageConstants)
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const [zIndexChat, setZIndexChat] = useState(chatBarOpen ? 100 : 1)
  const [showLinkCallAnAssistantForMessageId, setShowLinkCallAnAssistantForMessageId] = useState<number>(0);
  const [userMark, setUserMark] = useState(false);
  const [botChatEnabled, setBotChatEnabled] = useState(false);

  const updateShowLinkCallAnAssistant = useCallback((value: boolean = true) => {
    setUserMark(value)
  }, []);

  const getChatMessages = useCallback((id: string, afterTimeReq: string = "", newChat: boolean = false) => {
    if (newChat) setLoader(true)
    let entityType = ""
    if (id) {
      entityType = "10"
      setActiveChat(id)
    }

    Support.getChatMessages(length, start, "true", entityType, id, "true", "", afterTimeReq, lang)
      .then(res => {
        setMessages(Services.updateMassages(res.data.data))

        if (!entityType && res.data.data.length) {
          let time= Math.floor(Date.now() / 1000) - 86400
          let showLinkCallAnAssistantForMessageId = 0;
          const lastMessageFromAdmin = res.data.data.find((message) => message.isBot || message.msgType === 4)
          if (lastMessageFromAdmin && lastMessageFromAdmin.date > time) {
            showLinkCallAnAssistantForMessageId = lastMessageFromAdmin.id;
          }
          setShowLinkCallAnAssistantForMessageId(showLinkCallAnAssistantForMessageId);
          setUserMark(res.data.userMark);
          setBotChatEnabled(res.data.botChatEnabled);
        }

        setLoader(false)
      })

  }, [lang, setLoader])

  const getSupportMessage = (newChat: boolean = true) => {
    setActiveChat("0")
    openChats()
    getChatMessages("", "", newChat)
  }


  useEffect(() => {
    getChatMessages("")
  }, [getChatMessages])

  useEffect(() => {
    const getMessageInterval = setInterval(() => {
      if (activeChat === "0") {
        getChatMessages("")
      } else {
        getChatMessages(activeChat)
      }
    }, 10000);
    return () => clearInterval(getMessageInterval)
  }, [activeChat, getChatMessages])


  const openChats = () => {
    if (zIndexChat === 1) {
      setZIndexChat(100)
      setChatBarOpen(true)
    } else {
      setZIndexChat(1)
      setChatBarOpen(false)
    }
  }

  return (
    <div className="chat">
      <div className="chat__left" style={{ zIndex: zIndexChat }}>
        <div className="chat__title">
          <H2>{langConstants["L_CHAT"]}</H2>
        </div>
        <Listbids>
          <ListbidsItem
            support={true}
            active={activeChat === "0" ? true : false}
            title={langConstants["L_ADVANTAGES_HEADER_SUPPORT"] + " Matbea"}
            // date="!!!25.11.2021!!!"
            onClick={getSupportMessage}
            numberMessages={supportMessageNumber ? supportMessageNumber : undefined}
          />
          {chatsInfo.map(el => {
            const getMessages = () => {
              openChats()
              getChatMessages(`${el.orderId}`, "", true)
            }
            const active = activeChat === `${el.orderId}` ? true : false
            const status = `L_ORDER_STATUS_${el.status}`
            const dateFormat = Services.getFormatData(el.timeLastMessage).day
            return (
              <ListbidsItem
                key={el.orderId}
                bid={true}
                active={active}
                title={`${langConstants["L_ORDER_NUMBER"]} ${el.orderId}`}
                date={dateFormat}
                status={status}
                onClick={getMessages}
                numberMessages={el.numberOfNewMessages ? el.numberOfNewMessages : undefined}
              />
            )
          })}
        </Listbids>
      </div>

      <div className="chat__body">
        <div className="chat__body-wrapper">
          <div className="chat__body-button">
            <ButtonFlat onClick={openChats}>
              {zIndexChat === 1 ?
                <IconArrowChevronLeft />
                :
                <IconArrowChevronDown />
              }
              <H2>{activeChat === "0" ? langConstants["L_ADVANTAGES_HEADER_SUPPORT"] + " Matbea" : `${langConstants["L_ORDER_NUMBER"]} ${activeChat}`}</H2>
            </ButtonFlat>
          </div>

          <div className="chat__body-list">
            {Object.keys(messages).map(el => {
              return (
                <ChatList date={el} key={el}>
                  {messages[el].map((item, index) => {
                    const time = Services.getFormatShortData(item.date).time
                    return (
                      <ChatListItem
                        key={item.id}
                        messageId={item.id}
                        support={item.type === "admin" ? true : false}
                        user={item.type === "user" ? true : false}
                        title={item.fromWhom}
                        message={item.text}
                        time={time}
                        files={item.files}
                        updateLinkCallAnAssistant={updateShowLinkCallAnAssistant}
                        showLinkCallAnAssistantForMessageId={showLinkCallAnAssistantForMessageId}
                        botChatEnabled={botChatEnabled}
                        userMark={userMark}
                      />
                    )
                  })}
                </ChatList>
              )
            })}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat__actions">
            <ChatTextArea
              setFiles={setFiles}
              files={files}
              activeChat={activeChat}
              getChatMessages={getChatMessages}
            />
            {files && Object.values(files).map(el =>
              <ChatDocument
                key={el.name}
                name={el.name}
                setFiles={setFiles}
                files={files}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
};
