import React from "react";
import "./input.scss";
import classNames from "classnames";
import cn from "classnames";
import { LabelInputText, NotificationInputText } from "../Text";

type InputProps = {
  name: string;
  type: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  error?: boolean;
  maxLength?: number;
  notification?: string;
  icon?: React.ReactNode;
  iconChek?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean,
};

export const Input: React.FC<InputProps> = ({
  name,
  type = "text",
  value = "",
  placeholder = "Input Text",
  disabled = false,
  label,
  error = false,
  maxLength,
  notification,
  icon,
  iconChek,
  onChange,
  onBlur,
  required,
}) => {

  return (
    <div className={`input ${classNames(error ? `input--error` : "")}`}>
      <label htmlFor={name} className="input__label">
        <LabelInputText>{label}</LabelInputText>
      </label>

      <div className={cn("input__body", { "input__body--disabled": disabled })} tabIndex={1}>
        <input
          className="input__input"
          type={type}
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          required={required}
          onBlur={onBlur}
        />
        {icon &&
          <div className="input__icon">{icon}</div>
        }

        {iconChek &&
          <div className={cn("input__icon input__icon--check", { "input__icon--value": (value.length > 0) })}>{iconChek}</div>
        }
      </div>
      <div className="input__notification">
        <NotificationInputText>{notification}</NotificationInputText>
      </div>
    </div >
  );
};
