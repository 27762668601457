import { AxiosResponse } from "axios";
import { api } from "../api";
import { IMyApplicationFilterComponents, IMyApplicationRequest } from "../models/IMyApplication";
import { IObjectString } from "../models/IObjectString";
import Services from "./Services";

type actionOrderType = {
  text: string,
  type: string,
  data: {
    settings: settingsActionOrder
  }
}
type changePhoneRequest = {
  text: string,
  type: string,
}
export type settingsActionOrder = {
    canRecall: null | boolean;
    canResend: null | boolean;
    canResendEmail: null | boolean;
    canResendWhatsApp: null | boolean;
    handlers: number[];
    restOfTimeout: number;
    timeout: number;
    useHandlers: {
        [index: number]: boolean;
    };
}
export default class MyApplication {
  static async getData(
    filterComponents: IMyApplicationFilterComponents,
    length: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IMyApplicationRequest>> {
    const url = Services.getURL("/api/market/ajax-orders-dt", lang)
    const dataURL = new FormData();
    dataURL.append("draw", "1")
    dataURL.append("start", filterComponents.start)
    dataURL.append("length", length)
    dataURL.append("order_type", "0")
    dataURL.append("ordersGroupFilter", filterComponents.orderGroupFilter)
    return api.post<IMyApplicationRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async actionToOrderPay(
    id: string,
    action: "pay" | "confirm" | "delete" | "cancel" | "complaint",
    lang: "en" | "ru",
    option?: IObjectString,
  ): Promise<AxiosResponse<actionOrderType>> {
    const url = Services.getURL("/api/market/send-action-to-order", lang)
    const dataURL = new FormData();
    dataURL.append("action_order", "true")
    dataURL.append("action", action)
    dataURL.append("id", id)
    if (option) {
      const options = Object.entries(option)
      options.forEach(el => {
        if (el[1]) dataURL.append(el[0], el[1])
      })
    }
    return api.post<actionOrderType>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async changeAmount(
    id: string,
    newAmount: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/market/offer-to-change-order-amount", lang)
    const dataURL = new FormData();
    dataURL.append("newAmount", newAmount)
    dataURL.append("orderId", id)
    return api.post(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }

  static async toggleActiveCabinet(): Promise<AxiosResponse> {
    return api.post('/api/account/toggle-active-cabinet')
  }

  static async resendCode(
    urlResend: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<actionOrderType>> {
    const url = Services.getURL(urlResend, lang)
    return api.post<actionOrderType>(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async insertPhoneFio(
    fio: string,
    usersPhoneId: number,
    paymentsSystemId: number,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/insert-phone-fio", lang)
    const dataURL = new FormData();
    dataURL.append("fio", fio)
    dataURL.append("usersPhoneId", usersPhoneId.toString())
    dataURL.append("paymentsSystemId", paymentsSystemId.toString())
    return api.post<changePhoneRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async updateGlobalPercent(
    orderType: number,
    percent: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/update-global-percent", lang)
    const dataURL = new FormData();
    dataURL.append("orderType", orderType.toString())
    dataURL.append("percent", percent)
    return api.post<changePhoneRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
  static async resetPhoneFio(
    orderId: number,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/market/reset-users-phone-on-order", lang)
    const dataURL = new FormData();
    dataURL.append("orderId", orderId.toString())
    return api.post<changePhoneRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  }
}