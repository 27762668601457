import React from "react";
import "./operation-number-state.scss";
import { CardText, TitleCardText } from "../UI/Text";
import {
  IconArrowLongBottomDown,
  IconArrowLongBottomUp,
  IconNaObmen,
  IconObmen,
  IconSExchange,
} from "../UI/Icon";

type OperationNumberStateProps = {
  state: {
    down?: boolean;
    up?: boolean;
  },
  value?: string;
};

export const OperationNumberState: React.FC<OperationNumberStateProps> = ({
  state = {
    down: false,
    up: false,
  },
  value
}) => {
  const valueDefault = state.down ? "Ввод" : "Вывод"
  const useValue = value ? value : valueDefault
  return (
    <div className="operation-number-state">
      {state.down &&
        < IconArrowLongBottomDown />
      }
      {state.up &&
        < IconArrowLongBottomUp />
      }
      <TitleCardText>{useValue}</TitleCardText>
    </div>
  )
};

type OperationIconNumberStateProps = {
  operationName: string
  value?: string;
  state?: StateType;
};

type StateType = "down" | "up" | "na-obmen" | "obmen" | "exchange";

export const OperationIconNumberState: React.FC<OperationIconNumberStateProps> = ({
  operationName,
  value,
  state,
}) => {
  return (
    <div className="operation-icon-type">
      <div className="operation-icon-type__icon">
        {state === "down" && (
          <IconArrowLongBottomDown />
        )}

        {state === "up" && (
          <IconArrowLongBottomUp />
        )}

        {state === "obmen" && (
          <IconObmen />
        )}

        {state === "na-obmen" && (
          <IconNaObmen />
        )}
        {state === "exchange" && (
          <IconSExchange />
        )}
      </div>

      <div className="operation-icon-type__name">
        <CardText>
          {value} {operationName}
        </CardText>
      </div>
    </div>
  )
};
