import { IChatMessages } from "../models/ISupport";

export default class Services {
  static plusZero(item: number) {
    if (item < 10) {
      return `0${item}`;
    } else {
      return `${item}`;
    }
  }
  static getFormatData(timeData: number) {
    const data = new Date(timeData * 1000);
    const hours = this.plusZero(data.getHours());
    const minutes = this.plusZero(data.getMinutes());
    const seconds = this.plusZero(data.getSeconds());
    const day = this.plusZero(data.getDate());
    const month = this.plusZero(data.getMonth() + 1);
    const year = this.plusZero(data.getFullYear());
    return {
      time: `${hours}:${minutes}:${seconds}`,
      day: `${day}.${month}.${year}`,
    };
  }
  static getFormatShortData(timeData: number) {
    const data = new Date(timeData * 1000);
    const hours = this.plusZero(data.getHours());
    const minutes = this.plusZero(data.getMinutes());
    const day = this.plusZero(data.getDate());
    const month = this.plusZero(data.getMonth() + 1);
    return {
      time: `${hours}:${minutes}`,
      day: `${day}.${month}`,
    };
  }
  static getDataForSearch(date: Date) {
    const day = this.plusZero(date.getDate());
    const month = this.plusZero(date.getMonth() + 1);
    const year = this.plusZero(date.getFullYear());
    return `${day}/${month}/${year}`;
  }
  static getDataFormatFromStr(value: string) {
    const newValue = value.replace(/\D/g, "");
    if (newValue.length > 2) {
      let answer = "";
      for (let i = 0; i < 8 && i < newValue.length; i++) {
        if (i === 2 || i === 4) answer = answer + "/" + newValue[i];
        else answer = answer + newValue[i];
      }
      return answer;
    } else {
      return newValue;
    }
  }
  static checkDataStr(value: string) {
    const el = value.split("/");
    if (
      +el[0] <= 31 &&
      +el[1] <= 12 &&
      +el[2] > 1900 &&
      +el[2] <= new Date().getFullYear() &&
      new Date() >= new Date(`${el[2]}-${el[1]}-${el[0]}`)
    )
      return true;
    else return false;
  }
  static getURL(url: string, lang: "en" | "ru") {
    if (lang === "en") return "/" + lang + url;
    else return url;
  }
  static getCurrencyFormat(amount: number, count: number) {
    const correctAmount = amount.toFixed(count);
    return (+correctAmount).toPrecision();
  }
  static updateMassages(arrMessages: IChatMessages[]) {
    const objMessages: { [index: string]: IChatMessages[] } = {};
    for (let i = 0; i < arrMessages.length; i++) {
      const date = this.getFormatShortData(arrMessages[i].date).day;
      if (objMessages[date]) {
        objMessages[date].unshift(arrMessages[i]);
      } else {
        objMessages[date] = [arrMessages[i]];
      }
    }
    return objMessages;
  }
  static formatCreditCardNumber(cardNumber: string) {
    const cleanedNumber = cardNumber.replace(/\D/g, "");
    const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

    return formattedNumber;
  }
  static formatPhoneNumber(phoneNumber: string) {
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    let formattedNumber = cleanedNumber;
    if (formattedNumber.charAt(0) === "8") {
      formattedNumber = "+7" + formattedNumber.slice(1);
    }

    formattedNumber = formattedNumber.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      "+$1 ($2) $3-$4-$5"
    );

    return formattedNumber;
  }
}
