import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { Page } from "../../components/Page";
import { BlockContent } from "../../components/UI/BlockContent";

import { Tab, Tabs, TabsHeaderSelect, TabsList } from "../../components/UI/Tabs";
import { changeOrderType, clearData } from "../../features/requisit/paySystemsSlice";
import { languageConstants } from "../../features/settings/settingsSlice";
import { IMyRequisitesGlobeRequest } from "../../models/IMyRequisitesGlobeRequest";
import { IRequisite } from "../../models/IRequisite";

import { PageCreateAdFormSellBuy } from "./components/PageCreateAdFormSellBuy";

type PageCreateAdProps = {
  getMainData: () => Promise<AxiosResponse<IMyRequisitesGlobeRequest>>

};

export const PageCreateAd: React.FC<PageCreateAdProps> = ({getMainData}) => {
  const langConstants = useAppSelector(languageConstants)
  const dispatch = useAppDispatch();
  const location = useLocation()
  const state = location.state as IRequisite
  
  const [tabActive, setTabActive] = useState(state? state.orderType : 2)
  const data = (state && tabActive === state.orderType)? state : null

  const buyActive = () => {
    setTabActive(1)
    dispatch(clearData())
    dispatch(changeOrderType(1))

  }
  const sellActive = () => {
    setTabActive(2)
    dispatch(clearData())
    dispatch(changeOrderType(2))
  }
  return (
    <Page
      title={langConstants["L_MY_DETAILS_CREATE_AD"]}
      pagination currentPage={1}
      countInPage={{ value: "test", label: 1 }}
      count={1}
    >
      <Tabs>
        <TabsHeaderSelect>
          <TabsList>
            <Tab active={tabActive=== 2 ? true : false} tabOnClick={sellActive}>{langConstants["L_TEXT_SELL"]}</Tab>
            <Tab active={tabActive=== 1 ? true : false} tabOnClick={buyActive}>{langConstants["L_TEXT_BUY"]}</Tab>
          </TabsList>
        </TabsHeaderSelect>

         <BlockContent>
          {/* <TabPanel selected> */}
            <PageCreateAdFormSellBuy data={data} getMainData={getMainData} isSell={tabActive=== 2 ? true : false}/>
          {/* </TabPanel> */}
          {/* <TabPanel selected={tabActive=== 2 ? true : false}>
            <PageCreateAdFormSell data={data}/>
          </TabPanel>

          <TabPanel selected={tabActive=== 1 ? true : false}>
            <PageCreateAdFormBuy data={dataBuy}/>
          </TabPanel> */}
        </BlockContent>
      </Tabs>
    </Page >
  )
};