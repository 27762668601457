import NewsService, { newsMessageType } from "../../services/NewsService"
import "./news.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { IconClose } from "../UI/Icon"

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import { Pagination, Navigation } from "swiper";
import { ButtonFlatSecondary } from "../UI/Button";
import { useCallback, useContext, useEffect, useState } from "react";
import { ParamContext } from "../../context/paramReducer";
import { BodyText } from "../UI/Text";

type newsPropsType = {
}
export const News: React.FC<newsPropsType> = () => {
  const { lang } = useContext(ParamContext)
  const [messageNews, setMessageNews] = useState<newsMessageType[]>([])


  const getNews = useCallback(() => {
    NewsService.getNews(lang)
      .then(res => {
        if (res.data.data) {
          setMessageNews(res.data.data.news)
        }
      })
  }, [lang])

  useEffect(() => {
    getNews()
  }, [getNews])

  const hideMessageHandler = (id: number) => {
    NewsService.hideNews(id.toString(), lang)
      .then(res => {
        if (res.data.type === "success") getNews()
      })
  }

  return (
    <>
      {messageNews.length > 0 ?
        <div className="news">
          <Swiper
            pagination={{
              type: "fraction",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="news__slider"
          >
            {messageNews.map(el => {
              const hideItemMessage = () => {
                hideMessageHandler(el.id)
              }
              return (
                <SwiperSlide key={el.id} className="news__item">
                  <div className="news__body">
                    <BodyText tag="div">
                      {el.message.ru}
                    </BodyText>
                  </div>
                  <div className="news__button-close">
                    <ButtonFlatSecondary onClick={hideItemMessage}>
                      <IconClose />
                    </ButtonFlatSecondary>
                  </div>
                </SwiperSlide>)
            })}
          </Swiper>
        </div>
        :
        null
      }
    </>
  )
}