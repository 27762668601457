import React from "react";
import "./page-requisite-drop-down.scss";

type PageRequisiteDropDownProps = {
  children: React.ReactNode;
};


export const PageRequisiteDropDown: React.FC<PageRequisiteDropDownProps> = ({
  children,
}) => (
  <div className="page-requisite-drop-down">
    {children}
  </div>
);