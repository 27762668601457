import { AxiosResponse } from "axios";
import { api } from "../api";
import { IMyRequisitesFilterComponents } from "../models/IMyRequisitesFilterComponents";
import { IMyRequisitesGlobeRequest } from "../models/IMyRequisitesGlobeRequest";
import { IRequisite } from "../models/IRequisite";
import Services from "./Services";

export interface ICreateRequisiteResponse {
  text: string;
  type: string;
}
type changeEnableType = {
  data: { depositEnabled: boolean; withdrawEnabled: boolean };
  text: string;
  type: string;
};
export interface IUpgradeGlobType {
  data: null;
  text: string;
  type: string;
}

function createFormData(data: IRequisite) {
  const dataURL = new FormData();
  dataURL.append("orderType", data.orderType.toString());
  dataURL.append("paymentSystemId", data.paymentSystemId.toString());
  dataURL.append("userEnable", data.userEnable.toString());
  dataURL.append("amountLimit", data.amountLimit.toString());
  dataURL.append("amountLimitDay", data.amountLimitDay.toString());
  dataURL.append("amountMax", data.amountMax.toString());
  dataURL.append("amountMin", data.amountMin.toString());
  dataURL.append("countLimit", data.countLimit.toString());
  dataURL.append("countLimitShort", data.countLimitShort.toString());
  dataURL.append("priority", data.priority.toString());
  dataURL.append("percent", data.percent.toString());
  dataURL.append("deleted", data.deleted.toString());
  if (data.name) dataURL.append("name", data.name);
  for (const key in data.publicDetails) {
      dataURL.append("details" + key, data.publicDetails[key]);
  }

  if (data.id !== 0) dataURL.append("id", data.id.toString());
  if (data.amount !== 0) dataURL.append("amount", data.amount.toString());
  if (data.count !== 0) dataURL.append("count", data.count.toString());
  if (data.countShort !== 0)
    dataURL.append("countShort", data.countShort.toString());
  if (data.currencyId !== 0)
    dataURL.append("currencyId", data.currencyId.toString());
  dataURL.append("enable", data.enable.toString());
  dataURL.append("exhausted", data.exhausted.toString());
  // if (data.fromUserLevel !== 0) dataURL.append("fromUserLevel", data.fromUserLevel.toString())
  if (data.sumOnAccount !== 0)
    dataURL.append("sumOnAccount", data.sumOnAccount.toString());
  if (data.timeCreate !== 0)
    dataURL.append("timeCreate", data.timeCreate.toString());
  if (data.timeout !== 0) dataURL.append("timeout", data.timeout.toString());
  if (data.referenceTo)
    dataURL.append("referenceTo", data.referenceTo.toString());
  return dataURL;
}

export default class MyRequistes {
  static async getData(
    filterComponents: IMyRequisitesFilterComponents,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IMyRequisitesGlobeRequest>> {
    const url = Services.getURL("/api/account/get-my-upds", lang);
    const dataURL = new FormData();
    // dataURL.append("orderType", filterComponents.orderType);
    if (filterComponents.filterType)
      dataURL.append("filterType", filterComponents.filterType);
    if (filterComponents.start) dataURL.append("start", filterComponents.start);
    if (filterComponents.length)
      dataURL.append("length", filterComponents.length);
    if (filterComponents.paymentSystemId)
      dataURL.append("paymentSystemId", filterComponents.paymentSystemId);
    return api.post<IMyRequisitesGlobeRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async createRequisite(
    data: IRequisite,
    // filterComponents: IMyRequisitesFilterComponents,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<ICreateRequisiteResponse>> {
    const url = Services.getURL("/api/account/add-user-payment-detail", lang);
    const dataURL = createFormData(data);
    // const dataURL = createFormData(data, filterComponents)
    return api.post<ICreateRequisiteResponse>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async updateRequisite(
    data: IRequisite,
    filterComponents: IMyRequisitesFilterComponents,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<ICreateRequisiteResponse>> {
    const url = Services.getURL(
      "/api/account/update-user-payment-detail",
      lang
    );
    const dataURL = createFormData(data);
    // const dataURL = createFormData(data, filterComponents)
    return api.post<ICreateRequisiteResponse>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  static async toggleRequisite(
      id: number,
      lang: "en" | "ru"
  ): Promise<AxiosResponse<ICreateRequisiteResponse>> {
    const url = Services.getURL(
        "/api/account/toggle-user-payment-detail",
        lang
    );
    const dataURL = new FormData();
    dataURL.append("id", id.toString());

    return api.post<ICreateRequisiteResponse>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async delRequisite(
    id: number,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<ICreateRequisiteResponse>> {
    const url = Services.getURL(
      "/api/account/delete-user-payment-detail",
      lang
    );
    const dataURL = new FormData();
    dataURL.append("id", id.toString());
    return api.post<ICreateRequisiteResponse>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async restoreRequisite(
    id: number,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<ICreateRequisiteResponse>> {
    const url = Services.getURL(
      "/api/account/restore-user-payment-detail",
      lang
    );
    const dataURL = new FormData();
    dataURL.append("id", id.toString());
    return api.post<ICreateRequisiteResponse>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async newRequisiteFields(
    message: string,
    requisiteFields: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/request-new-direction", lang);
    const dataURL = new FormData();
    dataURL.append("message", message);
    dataURL.append("requiredAttributeFields", requisiteFields);
    return api.post(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async updateGlobalPercent(
    orderType: string,
    percent: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IUpgradeGlobType>> {
    const url = Services.getURL("/api/account/update-global-percent", lang);
    const dataURL = new FormData();
    dataURL.append("orderType", orderType);
    dataURL.append("percent", percent);
    return api.post<IUpgradeGlobType>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  static async changeEnable(
    orderType: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<changeEnableType>> {
    const url = Services.getURL("/api/account/toggle-requisites", lang);
    const dataURL = new FormData();
    dataURL.append("orderType", orderType);
    return api.post<changeEnableType>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
}
