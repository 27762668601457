import React, { useContext, useEffect, useState } from "react";
import "./langs.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { IconLangEng, IconLangRus } from "../UI/IconImg";
import { IconArrowCaretDown } from "../UI/Icon";
import { IconText } from "../UI/IconText";
import { BodyText } from "../UI/Text";
import { ParamContext } from "../../context/paramReducer";

type LangsProps = {
  lang: "ru" | "en"
};

type LangsType = {
  value: "ru" | "en",
  label: React.ReactNode
}

const optionLangs: LangsType[] = [
  {
    value: "ru",
    label:
      <IconText gap="lg">
        <IconLangRus />
        <BodyText color="secondary">RUS</BodyText>
      </IconText>
  },
  {
    value: "en",
    label:
      <IconText gap="lg">
        <IconLangEng />
        <BodyText color="secondary">ENG</BodyText>
      </IconText>
  },
]

export const Langs: React.FC<LangsProps> = ({ lang }) => {
  const { setLang } = useContext(ParamContext)
  const [defaultValue, setDefaultValue] = useState(optionLangs.find(el => el.value === lang))

  const changeHandle = (selectedOption: LangsType) => {
    if (selectedOption.value === "ru") setLang(selectedOption.value);
    if (selectedOption.value === "en") setLang(selectedOption.value);
  }
  useEffect(() => {
    setDefaultValue(optionLangs.find(el => el.value === lang))
  }, [lang])
  return (
    <div className="langs">
      <Select
        isSearchable={false}
        className="langs__body"
        options={optionLangs}
        value={defaultValue}
        classNamePrefix="langs"
        components={{ DropdownIndicator }}
        onChange={(value) => changeHandle(value as LangsType)}
      />
    </div>
  )
};

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => (
  <components.DropdownIndicator {...props}>
    <IconArrowCaretDown />
  </components.DropdownIndicator>
)