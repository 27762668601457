import React from "react";
import "./list-chips.scss";
import { H3 } from "../UI/Text";

type ListChipsProps = {
  title?: string;
  children: React.ReactNode;
};

export const ListChips: React.FC<ListChipsProps> = ({
  title,
  children,
}) => (
  <div className="list-chips">
    {title &&
      <div className="list-chips__title">
        <H3>{title}</H3>
      </div>
    }
    <div className="list-chips__body">
      {children}
    </div>
  </div>
);