import React from "react";
import { IconImg, IconImgUrl, Size } from "./IconImg";
import IconLangEngBase from "../../../assets/icon/icon--lang-eng.svg";
import IconLangRusBase from "../../../assets/icon/icon--lang-rus.svg";
import IconBitcoinBase from "../../../assets/icon/icon--bitcoin.svg";
import IconDashBase from "../../../assets/icon/icon--dash.svg";
import IconEthereumBase from "../../../assets/icon/icon--ethereum.svg";
import IconLitecoinBase from "../../../assets/icon/icon--litecoin.svg";
import IconTetherBase from "../../../assets/icon/icon--tether.svg";
import IconCancelBase from "../../../assets/icon/icon--cancel.svg";

type IconImgUIProps = {
  size?: Size;
};
type IconImgUrlProps = {
  url: string
  size?: Size;
};
export const IconCancel: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconCancelBase} name="icon--cancel" size={size} />
);
export const IconBitcoin: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconBitcoinBase} name="icon--bitcoin" size={size} />
);
export const IconDash: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconDashBase} name="icon--dash" size={size} />
);
export const IconEthereum: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconEthereumBase} name="icon--ethereum" size={size} />
);
export const IconLitecoin: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconLitecoinBase} name="icon--litecoin" size={size} />
);
export const IconTether: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconTetherBase} name="icon--tether" size={size} />
);
export const IconsAllCoins: React.FC<IconImgUrlProps> = ({ size = "base", url }) => (
  <IconImgUrl icons={url} size={size} />
);
export const IconLangRus: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconLangRusBase} name="icon--lang-rus" size={size} />
);
export const IconLangEng: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconLangEngBase} name="icon--lang-eng" size={size} />
);
