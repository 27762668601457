import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import "./page-history-operation-search-options.scss";
import className from "classnames";
import { ButtonFlat, ButtonBase } from "../../../../components/UI/Button";
import { IconSlider } from "../../../../components/UI/Icon";
import { BodyText, ButtonText } from "../../../../components/UI/Text";
import { ListChips } from "../../../../components/ListChips";
import { Chips } from "../../../../components/UI/Chips";
import { Input } from "../../../../components/UI/Input";
import { CalendarCustom } from "../../../../components/CalendarCustom";
import { IHistoryFilterComponents, IHistoryFilterComponentsKeys } from "../../../../models/IHistory";
import { IPaySystemShort } from "../../../../models/IPaySystem";
import { IDateSearch } from "../../../../models/IGlobe";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { useAppSelector } from "../../../../app/hooks";
import Services from "../../../../services/Services";
import { ParamContext } from "../../../../context/paramReducer";

type PageHistoryOperationSearchOptionsProps = {
  filterState: IHistoryFilterComponents;
  setFilterState: Dispatch<SetStateAction<IHistoryFilterComponents>>;
  paySystem: IPaySystemShort[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
  upds: { id: number, name: string, paymentSystemId: number }[];
  setUpds: Dispatch<SetStateAction<{ id: number, name: string, paymentSystemId: number }[]>>
};


export const PageHistoryOperationSearchOptions: React.FC<PageHistoryOperationSearchOptionsProps> = ({ filterState, setFilterState, paySystem, setCurrentPage, upds, setUpds }) => {
  const {setLoader} = useContext(ParamContext)
  const [open, setOpen] = useState(false);
  const [activeTypes, setActiveTypes] = useState({ all: true, deposit: false, withdraw: false })
  const [ordersGroupTypes, setOrdersGroupTypes] = useState({ all: true, inWork: false, completed: false, cancel: false })
  const [filter, setFilter] = useState<IHistoryFilterComponents>(filterState)
  const [date, setDate] = useState<Date[] | null>(null);
  const dateFrom = date?.[0] ? Services.getDataForSearch(date[0]) : null
  const dateTo = date?.[1] ? Services.getDataForSearch(date[1]) : null
  const [filterDate, setFilterDate] = useState<IDateSearch>({ dateFrom, dateTo })
  const [allPaySystemsActive, setAllPaySystemsActive] = useState<boolean>(true)
  const [allUpdsActive, setAllUpdsActive] = useState<boolean>(true)
  const langConstants = useAppSelector(languageConstants)

  const onOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (filterDate.dateFrom && filterDate.dateTo) {
      setFilter(preFilter => ({ 
        ...preFilter,  
        ...(filterDate.dateFrom && {dateFrom: filterDate.dateFrom}),
        ...(filterDate.dateTo && {dateTo: filterDate.dateTo}),
      }))
    }
  }, [filterDate])

  useEffect(() => {
    if (filter.typesAll) {
      setActiveTypes({ all: true, deposit: false, withdraw: false })
    } else if (filter.types[0] === "1") {
      setActiveTypes({ all: false, deposit: false, withdraw: true })
    } else {
      setActiveTypes({ all: false, deposit: true, withdraw: false })
    }
  }, [filter.types, filter.typesAll])

  const delPropertyFilter = (name: IHistoryFilterComponentsKeys) => {
    const newFilter = { ...filter }
    delete newFilter[name]
    return newFilter
  }
  const activeAllTypes = () => {
    setFilter({ ...filter, typesAll: "on", types: ["1", "2"] })
  }
  const activeTypeDeposit = () => {
    const newFilter = delPropertyFilter("typesAll")
    newFilter.types = ["2"]
    setFilter(newFilter)
  }
  const activeTypeWithdraw = () => {
    const newFilter = delPropertyFilter("typesAll")
    newFilter.types = ["1"]
    setFilter(newFilter)
  }
  const operationIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, orderId: e.target.value })
  }
  const clientHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, client: e.target.value })
  }
  const amountMinHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, amountMin: e.target.value })
  }
  const amountMaxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, amountMax: e.target.value })
  }


  useEffect(() => {
    if (filter.ordersGroupFiltersTypesAll) {
      setOrdersGroupTypes({ all: true, inWork: false, completed: false, cancel: false })
    } else {
      const newOrdersGroupTypes = { all: false, inWork: false, completed: false, cancel: false }
      for (const el of filter.ordersGroupFiltersTypes) {
        if (el === "4") newOrdersGroupTypes.inWork = true
        if (el === "5") newOrdersGroupTypes.completed = true
        if (el === "6") newOrdersGroupTypes.cancel = true
      }
      setOrdersGroupTypes(newOrdersGroupTypes)
    }
  }, [filter.ordersGroupFiltersTypes, filter.ordersGroupFiltersTypesAll])

  const ordersGroupTypesAll = () => {
    if (filter.ordersGroupFiltersTypesAll === "on") {
      const newFilter = { ...filter }
      delete newFilter.ordersGroupFiltersTypesAll
      setFilter({ ...newFilter, ordersGroupFiltersTypes: [] })
    } else {
      setFilter({ ...filter, ordersGroupFiltersTypesAll: "on", ordersGroupFiltersTypes: ["4", "5", "6"] })
    }
  }
  const ordersGroupTypeHandler = (type: "4" | "5" | "6") => {
    let option: "inWork" | "completed" | "cancel" = "inWork"
    if (type === "5") option = "completed"
    if (type === "6") option = "cancel"


    if (ordersGroupTypes[option]) {
      const newOrdersFiltersTypes = [...filter.ordersGroupFiltersTypes]
      const arrFilter = newOrdersFiltersTypes.filter(el => el !== type)
      setFilter({ ...filter, ordersGroupFiltersTypes: arrFilter })
    } else if (ordersGroupTypes.all) {
      const newFilter = delPropertyFilter("ordersGroupFiltersTypesAll")
      newFilter.ordersGroupFiltersTypes = [type]
      setFilter(newFilter)
    } else {
      if (filter.ordersGroupFiltersTypes.length === 2) {
        ordersGroupTypesAll()
      } else {
        const newOrdersFiltersTypes = [...filter.ordersGroupFiltersTypes]
        newOrdersFiltersTypes.push(type)
        setFilter({ ...filter, ordersGroupFiltersTypes: newOrdersFiltersTypes })

      }
    }
  }
  const ordersGroupTypeInWork = () => {
    ordersGroupTypeHandler("4")
  }
  const ordersGroupTypeCompleted = () => {
    ordersGroupTypeHandler("5")
  }
  const ordersGroupTypeCancel = () => {
    ordersGroupTypeHandler("6")
  }

  const changePaySystemAll = () => {
    if (allPaySystemsActive) {
      setFilter({ ...filter, paymentsSystem: [] })
      setAllPaySystemsActive(false)
    } else {
      setFilter({ ...filter, paymentsSystem: ["all"] })
      setAllPaySystemsActive(true)
    }
  }

  const changePaySystem = (el: string) => {
    const indexItem = filter.paymentsSystem.indexOf(el)
    const includeAll = filter.paymentsSystem.includes("all")
    if (includeAll) {
      const newPaymentSystem = [el]
      setFilter({ ...filter, paymentsSystem: newPaymentSystem })
      setAllPaySystemsActive(false)
    } else if (indexItem === -1 && filter.paymentsSystem.length === paySystem.length - 1) {
      setFilter({ ...filter, paymentsSystem: ["all"] })
      setAllPaySystemsActive(true)
    } else if (indexItem !== -1 && filter.paymentsSystem.length === 1) {
      setFilter({ ...filter, paymentsSystem: [] })

    } else if (indexItem === -1) {
      const newPaymentSystem = [...filter.paymentsSystem]
      newPaymentSystem.push(el)
      setFilter({ ...filter, paymentsSystem: newPaymentSystem })
      setAllPaySystemsActive(false)
    } else {
      const newPaymentSystem = [...filter.paymentsSystem]
      newPaymentSystem.splice(indexItem, 1)
      setFilter({ ...filter, paymentsSystem: newPaymentSystem })
      setAllPaySystemsActive(false)
    }
  }
  const showResult = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onOpen()
    window.scrollTo({top:0,behavior:'smooth'})
    setLoader(true)
    setCurrentPage(1)
    setFilterState(filter)
  }
  const updsAllHandler = () => {
    if (!filter.updsAll || filter.updsAll !== "on") {
      setFilter({ ...filter, updsAll: "on", upds: [] })
      setAllUpdsActive(true)
    } else {
      const newFilter = { ...filter }
      delete newFilter.updsAll
      setFilter({ ...newFilter, upds: [] })
      setAllUpdsActive(false)

    }
  }

  const updsHandler = (id: number) => {
    const idInclude = filter.upds?.indexOf(id.toString())
    if (idInclude === undefined || !filter.upds || filter.upds.length === 0) {
      const newFilter = { ...filter }
      delete newFilter.updsAll
      newFilter.upds = [`${id}`]
      setFilter(newFilter)
      setAllUpdsActive(false)
    } else if (idInclude === -1 && filter.upds.length > 0 && filter.upds.length < upds.length - 1) {
      const newUpds = [...filter.upds]
      newUpds.push(`${id}`)
      setFilter({ ...filter, upds: newUpds })
      setAllUpdsActive(false)
    } else if (idInclude === -1 && filter.upds.length === upds.length - 1) {
      updsAllHandler()
    } else if (idInclude !== -1 && filter.upds.length === 1) {
      setFilter({ ...filter, upds: [] })
    } else if (idInclude !== -1 && filter.upds.length > 1) {
      const newUpds = [...filter.upds]
      newUpds.splice(idInclude, 1)
      setFilter({ ...filter, upds: newUpds })
      setAllUpdsActive(false)
    }
  }

  return (
    <div className="page-history-operation-search-options">
      <ButtonFlat onClick={onOpen}>
        <IconSlider />
        <ButtonText>{langConstants["L_FIND_PARAMS"]}</ButtonText>
      </ButtonFlat>


      <div className={`page-history-operation-search-options__body ${className(
        open ? "page-history-operation-search-options__body--open" : "",
      )}`}>
        <form className="page-history-operation-search-options__form" action="" onSubmit={showResult} method="post">
          <div className="page-history-operation-search-options__form-body">
            <fieldset className="page-history-operation-search-options__form-item">
              <ListChips title={langConstants["L_OPERATION_TYPE"]}>
                <Chips onClick={activeAllTypes} active={activeTypes.all}>
                  {langConstants["L_ALL"]}
                </Chips>

                <Chips onClick={activeTypeDeposit} active={activeTypes.all ? activeTypes.all : activeTypes.deposit}>
                  {langConstants["L_ORDER_TYPE_1"]}
                </Chips>

                <Chips onClick={activeTypeWithdraw} active={activeTypes.all ? activeTypes.all : activeTypes.withdraw}>
                  {langConstants["L_ORDER_TYPE_2"]}
                </Chips>
              </ListChips>
            </fieldset>

            <fieldset className="page-history-operation-search-options__form-item">
              <div className="page-history-operation-search-options__form-inputs">
                <Input
                  label={langConstants["L_OPERATION_NUMBER"]}
                  name="input_operation"
                  placeholder={langConstants["L_ENTER_OPERATION_NUMBER"]}
                  type="text"
                  value={filter.orderId}
                  onChange={operationIdHandler}
                />

                <Input
                  label={langConstants["L_CLIENT"]}
                  name="input_user"
                  placeholder={langConstants["L_CLIENT"]}
                  type="text"
                  value={filter.client}
                  onChange={clientHandler}
                />
              </div>

              <div className="page-history-operation-search-options__form-inputs">
                <div className="page-history-operation-search-options__form-summa">
                  <Input
                    label={langConstants["L_AMOUNT"]}
                    name="input_summ-down"
                    placeholder={langConstants["L_RANGE_FROM"]}
                    type="text"
                    value={filter.amountMin}
                    onChange={amountMinHandler}
                  />
                  <BodyText color="secondary">-</BodyText>
                  <Input
                    name="input_summ-up"
                    placeholder={langConstants["L_RANGE_TO"]}
                    type="text"
                    value={filter.amountMax}
                    onChange={amountMaxHandler}
                  />
                </div>
              </div>

              <div className="page-history-operation-search-options__form-item-chips">
                <CalendarCustom label={langConstants["L_SELECT_DATE"]} setDateState={setFilterDate} setInitDate={setDate} initDate={date} />

                <ListChips title={langConstants["L_APPLICATION_STATUS"]}>
                  <Chips onClick={ordersGroupTypesAll} active={ordersGroupTypes.all}>
                    {langConstants["L_ALL"]}
                  </Chips>

                  <Chips onClick={ordersGroupTypeInWork} active={ordersGroupTypes.all ? ordersGroupTypes.all : ordersGroupTypes.inWork}>
                    {langConstants["L_IN_WORK"]}
                  </Chips>

                  <Chips onClick={ordersGroupTypeCompleted} active={ordersGroupTypes.all ? ordersGroupTypes.all : ordersGroupTypes.completed}>
                    {langConstants["L_ORDERS_COMPLETED"]}
                  </Chips>

                  <Chips onClick={ordersGroupTypeCancel} active={ordersGroupTypes.all ? ordersGroupTypes.all : ordersGroupTypes.cancel}>
                    {langConstants["L_ORDERS_CANCELLED"]}
                  </Chips>
                </ListChips>

                <ListChips title={langConstants["L_BANKS"]}>
                  <Chips onClick={changePaySystemAll} active={allPaySystemsActive}>
                    {langConstants["L_ALL"]}
                  </Chips>
                  {paySystem.map(el => {
                    const changePaySystemHandler = () => {
                      changePaySystem(el.id.toString())
                    }
                    const active = allPaySystemsActive ? allPaySystemsActive : filter.paymentsSystem.includes(el.id.toString())


                    return (
                      <Chips key={el.id} onClick={changePaySystemHandler} active={active}>
                        {el.name}
                      </Chips>
                    )
                  })}
                </ListChips>

                <ListChips title={langConstants["L_NAMES_ACCOUNTS"]}>
                  <Chips onClick={updsAllHandler} active={allUpdsActive}>
                    {langConstants["L_ALL"]}
                  </Chips>
                  {upds && upds.map(el => {
                    const updsItemHandler = () => updsHandler(el.id)
                    const active = filter.upds ? filter.upds.includes(`${el.id}`) : false
                    const paySystemActive = filter.paymentsSystem.includes(el.paymentSystemId.toString())
                    if (paySystemActive || allPaySystemsActive) {
                      return (
                        <Chips key={el.id} onClick={updsItemHandler} active={allUpdsActive ? allUpdsActive : active}>
                          {el.name}
                        </Chips>
                      )
                    } else {
                      return null
                    }

                  })}
                </ListChips>


              </div>
            </fieldset>
          </div>
          <div className="page-history-operation-search-options__form-bottom">
            <ButtonBase size="sm" type="submit">
              <ButtonText>{langConstants["L_SHOW"]}</ButtonText>
            </ButtonBase>
          </div>
        </form>
      </div>
    </div>
  );
};


