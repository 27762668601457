import React from "react";
import "./body.scss";
import { Container } from "../Grid/Container/Container";

type BodyProps = {
  children: React.ReactNode;
}

export const Body: React.FC<BodyProps> = ({
  children
}) => {
  return (
    <div className="body">
      <Container>
        <div className="body__content">
          {children}
        </div>
      </Container>
    </div>
  )
}