import React, { useCallback, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./tooltips.scss";
import classNames from "classnames";
import { TooltipText } from "../Text";

export type TooltipsProps = {
  placement?: Placement;
  children: React.ReactNode;
  content: string,
};

export type Placement = "left" | "right" | "bottom" | "top";

export const Tooltips: React.FC<TooltipsProps> = ({
  placement,
  content,
  children,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const tooltipContentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowDimensions();
  const [position, setPosition] = useState(placement);
 

  const calculateTooltipPosition = useCallback(() => {
    if (tooltipRef.current && tooltipContentRef.current) {

      const elementPosition = tooltipRef.current?.getBoundingClientRect()
      const elementContetnPosition = tooltipContentRef.current?.getBoundingClientRect()
      const horizontalPosition = (width || 0) - elementPosition.x - elementPosition.width;
      const verticalPosition = (height || 0) - elementPosition.y - elementPosition.height;

      const getVerticalPosition = (pos: Placement) => {
        if (pos === "top") {
          if(width && (width < elementPosition.x  + elementContetnPosition.width/2)){
            return "left"
          }else if (elementPosition.y >= elementContetnPosition.height + 60) {
            return "top";
          }else{
            return "bottom";
          }
        }else if (pos === "bottom") {
          if(elementContetnPosition.x < (width || 0) - elementContetnPosition.width){
            return "left"
          }else if (verticalPosition >= elementContetnPosition.height) {
            return "bottom";
          }else{
            return "top";
          }
        }else{
          return pos
        }
      };

      switch (placement) {
        case "right":
        case "left":
          if (horizontalPosition <= 280) {
            setPosition(getVerticalPosition("top"));
          } else {
            setPosition(placement);
          }
          break;

        case "bottom":
        case "top":
          setPosition(getVerticalPosition(placement));
          break;
      }
    }
  },[height, width, placement]);
  useEffect(() => {
    calculateTooltipPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  useEffect(() => {
    calculateTooltipPosition();
    window.addEventListener("scroll", calculateTooltipPosition);

    return () => {
      window.removeEventListener("scroll", calculateTooltipPosition);
    };
  },[calculateTooltipPosition]);

  const onClick = () => {
    calculateTooltipPosition();
    setOpenTooltip(!openTooltip);
  };

  return (
    <div className={`tooltips`}>
      <button ref={tooltipRef} type="button" className="tooltips__button" onMouseEnter={onClick} onMouseLeave={onClick}>{children}</button>

      <div ref={tooltipContentRef} className={`tooltips__content ${classNames(
        placement ? `tooltips__content--${position}` : "",
        openTooltip ? "tooltips__content--open" : ""
      )}`}
      >
        <TooltipText> {content}</TooltipText>
      </div>
    </div>
  );
};
