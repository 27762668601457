import { AxiosResponse } from "axios";
import {api} from "../api";
import { IGetIdChatsRequest, IMessagesRequest } from "../models/ISupport";
import Services from "./Services";

type sendMessageRequest = {
    message: string,
    type: string
}

type callAnAssistantResponse = {
    text: string,
    type: string,
    userMark: boolean
}

export default class Support {
    static async getIdChats(
        length: number,
        start: number,
        ajax_data_tables: string,
        lang: "en" | "ru"
    ): Promise<AxiosResponse<IGetIdChatsRequest>> {
        const url = Services.getURL("/api/account/ajax-chat-pagination", lang)
        const dataURL = new FormData();
        dataURL.append("start", start.toString())
        dataURL.append("length", length.toString())
        dataURL.append("ajax_data_tables", ajax_data_tables)
        return api.post<IGetIdChatsRequest>(url, dataURL, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
    static async getChatMessages(
        length: number,
        start: number,
        ajax_data_tables: string,
        entityType: string,//(остается пустым для службы поддержки)
        entityId: string,//id операции (остается пустым для службы поддержки)
        isNotificationNewMessage: string,
        beforeTime: string, //первый запрос
        afterTime: string,// последующие запросы
        lang: "en" | "ru"
    ): Promise<AxiosResponse<IMessagesRequest>> {
        const url = Services.getURL("/api/account/ajax-support-pagination", lang)
        const dataURL = new FormData();
        dataURL.append("start", start.toString())
        dataURL.append("length", length.toString())
        dataURL.append("entityType", entityType)
        dataURL.append("entityId", entityId)
        dataURL.append("ajax_data_tables", ajax_data_tables)
        dataURL.append("isNotificationNewMessage", isNotificationNewMessage)
        dataURL.append("beforeTime", beforeTime)
        dataURL.append("afterTime", afterTime)

        return api.post<IMessagesRequest>(url, dataURL, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
    static async sendMessage(
        text: string,
        entityType: string,
        entityId: string,
        lang: "en" | "ru",
        files?: FileList
    ): Promise<AxiosResponse<sendMessageRequest>> {
        const url = Services.getURL("/api/account/support", lang)
        const dataURL = new FormData();
        dataURL.append("send_private_message", "true")
        dataURL.append("text", text)
        dataURL.append("entityType", entityType)
        dataURL.append("entityId", entityId)
        if (files) {
            for (const key in files) {
                if (key !== "length" && key !== "file_item" && key !== "item") {
                    dataURL.append(`file_${key}`, files[key])
                }
            }
        }
        return api.post<sendMessageRequest>(url, dataURL, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
    static async getDoc(
        item: number,
        messageId: number
    ): Promise<AxiosResponse<Blob>> {
        return api.get<Blob>(`/api/account/private-messages/get-photo?photo=${item}&messageId=${messageId}&type=1`, {
            headers: {
                "Content-Type": "image/jpeg",
            }
        })
    }

    static async callAnAssistant(
        lang: "en" | "ru",
    ): Promise<AxiosResponse<callAnAssistantResponse>> {
        const url = Services.getURL(
            "/api/account/support-call-human",
            lang
        );

        return api.post<callAnAssistantResponse>(url, { }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }
}