import React from "react";
import "./list-bids.scss";
import className from "classnames";
import { IconFileBlank } from "../../../UI/Icon";
import { BodyText, BodySmallText } from "../../../UI/Text";
import ImgLogoChat from "../../../../assets/images/img-logo-chat.svg";
import { useAppSelector } from "../../../../app/hooks";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { CounterRound } from "../../../UI/CounterRound";

type ListbidsProps = {
  children: React.ReactNode;
};


export const Listbids: React.FC<ListbidsProps> = ({
  children,
}) => (
  <div className="list-bids">
    {children}
  </div>
);

type ListbidsItemProps = {
  title: string;
  date?: string;
  status?: Status;
  support?: boolean;
  bid?: boolean;
  active?: boolean;
  onClick: () => void;
  numberMessages?: number;
};

type Status = string;
// type Status = "paid" | "not-paid";

export const ListbidsItem: React.FC<ListbidsItemProps> = ({
  title,
  status,
  support = false,
  bid = false,
  active = false,
  date,
  numberMessages,
  onClick,
}) => {
  const langConstants = useAppSelector(languageConstants)

  return (
    <div className={`list-bids__item ${className(
      active ? "list-bids__item--active " : ""
    )}`} onClick={onClick}>
      <div className="list-bids__item-body">
        {support &&
          <div className="list-bids__item-icon">
            {numberMessages &&
              <span className="list-bids__item-number-messages">
                <CounterRound counter={numberMessages} />
              </span>
            }
            <img src={ImgLogoChat} alt="logo-chat" />
          </div>
        }
        {bid &&
          <div className="list-bids__item-icon">
            {numberMessages &&
              <span className="list-bids__item-number-messages">
                <CounterRound counter={numberMessages} />
              </span>
            }
            <IconFileBlank />
          </div>
        }

        <div className="list-bids__info">
          <div className="list-bids__info-top">
            <BodyText>{title}</BodyText>
            {date &&
              <div className="list-bids__item-date">
                <BodySmallText color="secondary" >{date}</BodySmallText>
              </div>
            }
          </div>

          {status === "L_ORDER_STATUS_13" &&
            <div className="list-bids__item-status list-bids__item-status--paid">
              <BodySmallText>{langConstants[status]}</BodySmallText>
            </div>
          }
          {status && status !== "L_ORDER_STATUS_13" &&
            <div className="list-bids__item-status list-bids__item-status--not-paid">
              <BodySmallText>{langConstants[status]}</BodySmallText>
            </div>
          }
        </div>
      </div >
    </div >
  )
};