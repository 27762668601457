import React from "react";
import "./sidebar-list.scss";
import classNames from "classnames";
import { BodySmallText, BodyText, ButtonText, H3 } from "../../../UI/Text";
import { ButtonFlat } from "../../../UI/Button";
import { IconArrowCaretDown } from "../../../UI/Icon";
import { IconText } from "../../../UI/IconText";
import { useAppSelector } from "../../../../app/hooks";
import { languageConstants } from "../../../../features/settings/settingsSlice";

type SidebarListProps = {
  title?: string;
  children: React.ReactNode;
  setShow?: () => void;
  show?: boolean;
  button?: boolean;
  gap?: Gap
};

type Gap =
  | "xs"
  | "2xs"
  | "s"
  | "2s"
  | "3s"
  | "m"
  | "2m"
  | "3m"
  | "4m";

export const SidebarList: React.FC<SidebarListProps> = ({
  title,
  gap = "2s",
  children,
  button = false,
  setShow,
  show
}) => {

  const langConstants = useAppSelector(languageConstants)

  return (
    <div className="sidebar-list">
      {title &&
        <div className="sidebar-list__title">
          <H3>{title}</H3>
        </div>
      }
      <ul className={`sidebar-list__content ${classNames(
        gap ? `sidebar-list__content--${gap}` : "",
        button ? "sidebar-list__content--heide" : "",
        show ? "sidebar-list__content--show" : "",
      )}`}>
        {children}
      </ul>

      {button &&
        <div className={`sidebar-list__button ${classNames(
          show ? "sidebar-list__button--show" : "",
        )}`}>
          <ButtonFlat onClick={setShow}>
            <ButtonText>{show ? langConstants["L_HIDE"] : langConstants["L_SHOW_ALL"]}</ButtonText>
            <IconArrowCaretDown />
          </ButtonFlat>
        </div>
      }
    </div>
  );
};

type SidebarListItemReservesProps = {
  name: string;
  value: string;
};

export const SidebarListItemReserves: React.FC<SidebarListItemReservesProps> = ({
  name,
  value,
}) => (
  <li className="sidebar-list__item sidebar-list__item--reserves">
    <BodySmallText>{name}</BodySmallText>
    <BodySmallText>{value}</BodySmallText>
  </li>
);

type SidebarListItemMyAccountsProps = {
  name: {
    icon: React.ReactNode;
    name: string;
  };
  value: string;
};

export const SidebarListItemMyAccounts: React.FC<SidebarListItemMyAccountsProps> = ({
  name,
  value,
}) => (
  <li className="sidebar-list__item sidebar-list__item--my-accounts">
    <IconText gap="2xl">
      {name.icon}
      <BodyText>{name.name}</BodyText>
    </IconText>
    <BodyText>{value}</BodyText>
  </li>
);
