import React from "react";
import "./operation-state.scss";
import classNames from "classnames";

type OperationStateProps = {
  state: number;
  empty?: boolean;
  children: React.ReactNode;
};

export const OperationState: React.FC<OperationStateProps> = ({
  state,
  empty,
  children,
}) => {
  return (
    <div
      className={`operation-state ${classNames(
        state === 1 ? "operation-state--sale" : "",
        state === 2 ? "operation-state--buy" : "",
        empty && state === 1 ? "operation-state--empty" : ""
      )}`}
    >
      {children}
    </div>
  );
};
