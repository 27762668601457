import React from "react";
import "./report-table.scss";
import { TableText, BodySmallText } from "../UI/Text";

type ReportTableProps = {
  tHead?: string[],
  tBody?: {
    itemsTd?: {}[]
  }[],
  tBottom?: {}[],
  tTotal?: {}[],
};

export const ReportTable: React.FC<ReportTableProps> = ({
  tHead,
  tBody,
  tBottom,
  tTotal
}) => (
  <div className="report-table">
    <Thead items={tHead} />

    <Tbody items={tBody} />

    <TbottomTotal items={tBottom} />
    <Tbottom items={tTotal} />
  </div>
);

type TheadProps = {
  items?: string[];
};

const Thead: React.FC<TheadProps> = ({
  items = [],
}) => {
  return (
    <div className="report-table__head">
      {items.map((item, index) => (
        <div key={index} className="report-table__head-item">
          <BodySmallText color="secondary" >{item}</BodySmallText>
        </div>
      ))}
    </div>
  )
};

type TbottomTotalProps = {
  items?: {
    title?: string;
    value?: string;
  }[]
};

const TbottomTotal: React.FC<TbottomTotalProps> = ({
  items = [],
}) => {
  return (
    <div className="report-table__bottom-total">
      {items.map((item, index) => (
        <div key={index} className="report-table__bottom-total-item">
          {item.title &&
            <div className="report-table__title">
              <TableText color="white" >{item.title}</TableText>
            </div>
          }
          <TableText color="white" >{item.value}</TableText>
        </div>
      ))}
    </div>
  )
};

type TbottomProps = {
  items?: {
    title?: string;
    value?: string;
  }[]
};

const Tbottom: React.FC<TbottomProps> = ({
  items = [],
}) => {
  return (
    <div className="report-table__bottom">
      {items.map((item, index) => (
        <div key={index} className="report-table__bottom-item">
          {item.title &&
            <div className="report-table__title">
              <TableText color="white" >{item.title}</TableText>
            </div>
          }
          <TableText color="white" >{item.value}</TableText>
        </div>
      ))}
    </div>
  )
};

type TbodyProps = {
  items?: {
    itemsTd?: {}[]
  }[]
};

const Tbody: React.FC<TbodyProps> = ({
  items = [],
}) => {
  return (
    <div className="report-table__body" >
      {items.map((item, index) => (
        <Trow items={item.itemsTd} key={index} />
      ))}
    </div>
  )
};

type TrowProps = {
  items?: {
    title?: string;
    value?: string;
  }[]
};

const Trow: React.FC<TrowProps> = ({
  items = [],
}) => {
  return (
    <div className="report-table__body-item" >
      {items.map((item, index) => (
        <div className="report-table__body-item-content" key={index}>
          {item.title &&
            <div className="report-table__title">
              <TableText color="secondary" >{item.title}</TableText>
            </div>
          }
          <TableText color="primary" >{item.value}</TableText>
        </div>
      ))}
    </div>
  )
};
