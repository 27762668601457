import React from "react";
import "./page-history-operation-control.scss";

type PageHistoryOperationControlProps = {
  children: React.ReactNode;
};


export const PageHistoryOperationControl: React.FC<PageHistoryOperationControlProps> = ({
  children,
}) => (
  <div className="page-history-operation-control">
    {children}
  </div>
);