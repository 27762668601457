import React, { useContext, useEffect, useState } from "react";
import "./page.scss";
import { H1, BodyText } from "../UI/Text";
import { Pagination } from "../UI/Pagination";
import { useAppSelector } from "../../app/hooks";
import { languageConstants } from "../../features/settings/settingsSlice";
import { News } from "../News";
import { ParamContext } from "../../context/paramReducer";
import { VerticalOffset } from "../Grid/VerticalOffset";

type PageProps = {
  title: string;
  countInPage: { value: string, label: number }
  lead?: React.ReactNode;
  pagination?: boolean;
  count?: number;
  currentPage: number;
  setCurrentPage?: (num: number) => void;
  news?: boolean
};

export const Page: React.FC<PageProps> = ({
  title,
  countInPage,
  lead,
  pagination,
  count,
  children,
  currentPage,
  setCurrentPage,
  news = true
}) => {
  const { loader } = useContext(ParamContext)

  const [numPages, setNumPages] = useState(2);
  const [firstItemInPage, setFirstItemInPage] = useState(1);
  const langConstants = useAppSelector(languageConstants)
  const [stringNotesTotal, setStringNotesTotal] = useState<string>("")
  const { setSideBarOpen } = useContext(ParamContext)

  const closeSideBar = () => {
    setSideBarOpen(false)
  }

  useEffect(() => {
    if (count) {
      const toCount = (firstItemInPage + (countInPage.label - 1)) < count ? firstItemInPage + (countInPage.label - 1) : count
      const newNotesString = langConstants["L_DATA_TABLE_RECORDS_TOTAL"]?.replace(/:start/, firstItemInPage.toString()).replace(/:toCount/, toCount.toString()).replace(/:total/, count.toString())

      setStringNotesTotal(newNotesString)
    }
  }, [firstItemInPage, count, langConstants, countInPage.label])

  useEffect(() => {
    if (count && count > countInPage.label) {
      setNumPages(Math.ceil(count / countInPage.label));
    }
  }, [count, countInPage]);

  useEffect(() => {
    setFirstItemInPage((currentPage - 1) * countInPage.label + 1)
  }, [currentPage, countInPage.label])
  const pageChangeHandler = (countPage: number) => {
    if (setCurrentPage) {
      setCurrentPage(countPage);
    }
    setFirstItemInPage((countPage - 1) * countInPage.label + 1);
  };

  return (
    <section className="page" onClick={closeSideBar}>
      {news && <News />}
      <div className="page__title">
        <H1>{title}</H1>
        {lead}
      </div>
      <div className="page__body">
        <VerticalOffset>
          {children}
          {count === 0 && !loader &&
            <BodyText color="secondary">
              {langConstants["L_ZERO_RECORDS_IN_TABLE"]}
            </BodyText>
          }
          {(pagination && count && count > countInPage.label && currentPage) ?
            <div className="page__bottom">
              <BodyText color="secondary" >
                {stringNotesTotal}
              </BodyText>

              <Pagination
                numOfPages={numPages}
                currentPage={currentPage}
                onPageChange={pageChangeHandler}
              />
            </div>
            :
            null
          }
        </VerticalOffset>
      </div>
    </section>
  )
};
