import React, { useContext, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ListForm, ListFormItem } from "../../ListForm";
import { ButtonBase, ButtonOutline } from "../../UI/Button";
import { Input } from "../../UI/Input";
import { ButtonText } from "../../UI/Text";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper"
import MyApplication from "../../../services/MyApplicationService"
import { toast } from "react-toastify";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ParamContext } from "../../../context/paramReducer";
import { FormModal, FormModalBody, FormModalFooter } from "../../Form/FormModal";

type ModalChangeAmountProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
  id: string;
  nickName: string;
  getData: () => void;
};


export const ModalChangeAmount: React.FC<ModalChangeAmountProps> = ({ isOpen, title, size, id, nickName, getData }) => {
  const { onDismiss } = useContext(ModalContext);
  const { lang } = useContext(ParamContext)
  const [newAmount, setNewAmount] = useState<string>("")
  const langConstants = useAppSelector(languageConstants)

  const sendHandler = () => {
    onDismiss()
    toast.promise(MyApplication.changeAmount(id, newAmount, lang)
      .then(res => {
        if (res.data.type === "success") {
          getData()
          return res.data.text
        } else {
          throw res.data.text
        }

      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })

  }
  const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewAmount(e.target.value)
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      title={title}
      size={size}
    >
      <FormModal onSubmit={sendHandler}>
        <FormModalBody>
          <ListForm
            title={langConstants["L_USER"] + " " + nickName}
          >
            <ListFormItem >
              <Input
                label={langConstants["L_EDIT_SUM"]}
                name="input_name"
                placeholder={langConstants["L_ENTER_NEW_AMOUNT"]}
                type="text"
                value={newAmount}
                onChange={amountHandler}
              />
            </ListFormItem>
          </ListForm>
        </FormModalBody>

        <FormModalFooter>
          <ButtonOutline onClick={onDismiss}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>{langConstants["L_LIGHT_WITHDRAW_COUNT_CHANGE"]}</ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper >
  )
};
