import React, { useCallback, useContext, useEffect, useState } from "react";
import { Bids, ListBids } from "../../components/Bids";
import { Page } from "../../components/Page";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsHeaderSelect,
  TabsList,
} from "../../components/UI/Tabs";
import "./page-my-applications.scss";
import { DropdownQuantity } from "../../components/DropdownQuantity";
import dropDown from "../../data/dropDown.json";
import { ParamContext } from "../../context/paramReducer";
import MyApplication from "../../services/MyApplicationService";
import { useAppSelector } from "../../app/hooks";
import {
  IMyApplicationData,
  userPhone,
  userPSData,
} from "../../models/IMyApplication";
import { IPaySystemShort } from "../../models/IPaySystem";
import { languageConstants } from "../../features/settings/settingsSlice";
import { paySystemCurrency } from "../../features/requisit/paySystemsSlice";
import Support from "../../services/SuppotrService";
import { IChatInfo } from "../../models/ISupport";
import { PageRequisiteDropDown } from "../PageRequisites/components/PageRequisiteDropDown";
// import { newsMessageType } from "../../services/NewsService";

type PageMyApplicationsProps = {};

export const PageMyApplications: React.FC<PageMyApplicationsProps> = () => {
  const { lang, setLoader } = useContext(ParamContext);
  const { countInPageMyApplications } = useContext(ParamContext);
  const [countByFilter, setCountByFilter] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [data, setData] = useState<IMyApplicationData[]>([]);
  const [loudData, setLoudData] = useState(false);
  const [paySystem, setPaySystem] = useState<{
    [index: string]: IPaySystemShort;
  }>({});
  const [orderGroupFilter, setOrderGroupFilter] = useState<string>("0");
  const [orderGroupActive, setOrderGroupActive] = useState<{
    [index: string]: boolean;
  }>({ 1: false, 2: false, 3: false, 4: false });
  const [countRecords, setCountRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [chatsInfo, setChatsInfo] = useState<IChatInfo[]>([]);
  const [usersPSDataByIds, setUsersPSDataByIds] = useState<{
    [index: string]: { [index: string]: userPSData };
  }>({});
  const [usersPhonesData, setUsersPhonesData] = useState<{
    [index: string]: userPhone;
  }>({});
  const langConstants = useAppSelector(languageConstants);
  const currency = useAppSelector(paySystemCurrency);

  const getDataFunc = useCallback(() => {
    MyApplication.getData(
      {
        draw: "0",
        start: `${(currentPage - 1) * countInPageMyApplications.label}`,
        orderGroupFilter,
      },
      countInPageMyApplications.value,
      lang
    )
      .then((res) => {
        setCountByFilter(res.data.countByAnyFilters);
        setData(res.data.data);
        setPaySystem(res.data.paymentsSystemsData);
        setCountRecords(res.data.recordsTotal);
        setUsersPSDataByIds(res.data.usersPSDataByIds);
        setUsersPhonesData(res.data.usersPhonesData);
        setLoader(false);
        setLoudData(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    orderGroupFilter,
    countInPageMyApplications,
    currentPage,
    lang,
    setLoader,
  ]);

  const getChatsInfo = useCallback(() => {
    Support.getIdChats(10, 0, "true", lang).then((res) => {
      setChatsInfo(res.data.data);
    });
  }, [lang]);

  useEffect(() => {
    setLoader(true);
    getDataFunc();
    getChatsInfo();
    const getDataInterval = setInterval(() => {
      getDataFunc();
      getChatsInfo();
    }, 20000);
    return () => {
      clearInterval(getDataInterval);
      setLoader(false);
    };
  }, [getDataFunc, setLoader, getChatsInfo]);

  const orderGroupHandler = (id: string) => {
    if (orderGroupFilter === id) {
      setOrderGroupFilter("0");
      setOrderGroupActive({ 1: false, 2: false, 3: false, 4: false });
      setCurrentPage(1);
    } else {
      setOrderGroupFilter(id);
      setOrderGroupActive({
        1: false,
        2: false,
        3: false,
        4: false,
        [id]: true,
      });
      setCurrentPage(1);
    }
  };
  const tabActiveHandler = () => {
    orderGroupHandler("1");
  };
  const tabPassiveHandler = () => {
    orderGroupHandler("2");
  };
  const tabConflictHandler = () => {
    orderGroupHandler("3");
  };
  // const tabInWorkHandler = () => {
  //   orderGroupHandler("4")
  // }

  return (
    <Page
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      count={countRecords}
      title={langConstants["L_MY_DEALS"]}
      pagination
      countInPage={countInPageMyApplications}
    >
      {loudData && (
        <Tabs>
          <TabsHeaderSelect>
            <TabsList>
              <Tab
                count={countByFilter ? countByFilter[1] : 0}
                active={orderGroupActive ? orderGroupActive[1] : false}
                tabOnClick={tabActiveHandler}
                countOn={true}
              >
                {langConstants["L_ORDERS_ACTIVE"]}
              </Tab>
              <Tab
                count={countByFilter ? countByFilter[2] : 0}
                active={orderGroupActive ? orderGroupActive[2] : false}
                tabOnClick={tabPassiveHandler}
                countOn={true}
              >
                {langConstants["L_ORDERS_PASSIVE"]}
              </Tab>
              <Tab
                count={countByFilter ? countByFilter[3] : 0}
                active={orderGroupActive ? orderGroupActive[3] : false}
                tabOnClick={tabConflictHandler}
                countOn={true}
              >
                {langConstants["L_ORDER_ARBITRATION"]}
              </Tab>
              {/* <Tab count={countByFilter ? countByFilter[4] : 0} active={orderGroupActive ? orderGroupActive[4] : false} tabOnClick={tabInWorkHandler}>{langConstants["L_ORDERS_IN_ACTION"]}</Tab> */}
            </TabsList>
          </TabsHeaderSelect>
          <PageRequisiteDropDown>
            <DropdownQuantity
              title={langConstants["L_SHOW_BY"] + ":"}
              options={dropDown}
              defaultValue={countInPageMyApplications}
              page="Applications"
            />
          </PageRequisiteDropDown>

          <TabPanel selected>
            <ListBids>
              {data &&
                data.length > 0 &&
                data.map((el) => {
                  const currencyItem = currency.find(
                    (item) => item.id === el.order.currencyId
                  );
                  const countMessage = chatsInfo.find(
                    (item) => item.orderId === el.order.id
                  );
                  const paySystemId = el.order.paymentSystemId;
                  const user = Object.values(usersPhonesData).find(
                    (user) => user.id === el.order.userPhoneId
                  );
                  const fio =
                    user &&
                    usersPSDataByIds[user.id] &&
                    usersPSDataByIds[user.id][paySystemId]
                      ? usersPSDataByIds[user.id][paySystemId].fio
                      : "";
                  const phoneInfo = user
                    ? {
                        phoneNumber: user.phoneNumber,
                        fio,
                        usersPhoneId: user.id,
                        paymentsSystemId: paySystemId,
                        requiredPhone:
                          paySystem[paySystemId]?.cardPhoneRequired,
                      }
                    : {
                        phoneNumber: 0,
                        fio: "",
                        usersPhoneId: 0,
                        paymentsSystemId: paySystemId,
                        requiredPhone:
                          paySystem[paySystemId]?.cardPhoneRequired,
                      };
                  if (
                    paySystem &&
                    Object.keys(paySystem) &&
                    Object.keys(paySystem).length > 0 &&
                    currencyItem
                  ) {
                    return (
                      <Bids
                        key={el.order.id}
                        data={el}
                        getData={getDataFunc}
                        currency={currencyItem}
                        paySystem={paySystem[el.order.paymentSystemId]}
                        numberMessages={
                          countMessage ? countMessage.numberOfNewMessages : 0
                        }
                        order={el.order}
                        phoneInfo={phoneInfo}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </ListBids>
          </TabPanel>
          <TabPanel>{langConstants["L_ORDERS_PASSIVE"]}</TabPanel>
          <TabPanel>{langConstants["L_ORDER_ARBITRATION"]}</TabPanel>
          <TabPanel>{langConstants["L_ORDERS_IN_ACTION"]}</TabPanel>
        </Tabs>
      )}
    </Page>
  );
};
