import React, { useContext, useEffect, useRef, useState } from "react";
import { Page } from "../../components/Page";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsHeaderSelect,
  TabsList,
} from "../../components/UI/Tabs";
import { BodyText } from "../../components/UI/Text";
import { ButtonIcon } from "../../components/UI/Button";
import { IconCircleCheck, IconMenuCloseSmall } from "../../components/UI/Icon";
import { VerticalOffset } from "../../components/Grid/VerticalOffset";
import {
  Requisite,
  RequisiteHead,
  RequisiteList,
} from "../../components/Requisite";
import { DropdownQuantity } from "../../components/DropdownQuantity";
import { PageRequisitesTabs } from "./components/PageRequisitesTabs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  changeFilterComponents,
  paySystemFilterComponents,
  paySystemList,
} from "../../features/requisit/paySystemsSlice";
import { ParamContext } from "../../context/paramReducer";
import {
  requestCountsByFilter,
  requestData,
} from "../../features/requisit/requestDataSlice";
import { IMyRequisitesFilterComponents } from "../../models/IMyRequisitesFilterComponents";
import dropDown from "../../data/dropDown.json";
import { languageConstants } from "../../features/settings/settingsSlice";
import { IMyRequisitesGlobeRequest } from "../../models/IMyRequisitesGlobeRequest";
import { AxiosResponse } from "axios";
import { UpdateGlobalCommission } from "../../components/UpdateGlobalCommission";
import { BlockContent } from "../../components/UI/BlockContent";
import { PageRequisiteDropDown } from "./components/PageRequisiteDropDown";

type PageRequisitesProps = {
  getMainData: (
    filter?: IMyRequisitesFilterComponents
  ) => Promise<AxiosResponse<IMyRequisitesGlobeRequest>>;
  count: number;
};

type FilterTypeResType = {
  active: boolean;
  del: boolean;
  disable: boolean;
};

export const PageRequisites: React.FC<PageRequisitesProps> = ({
  getMainData,
  count,
}) => {
  const { countInPageRequisite, setLoader } = useContext(ParamContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countForPagination, setCountForPagination] = useState<number>(count);
  const dataState = useAppSelector(requestData);
  const countsByFilterState = useAppSelector(requestCountsByFilter);
  const filterComponentsState = useAppSelector(paySystemFilterComponents);
  const [filterComponents, setFilterComponents] =
    useState<IMyRequisitesFilterComponents>(filterComponentsState);
  let filterTypeResPre = { active: true, del: false, disable: false };
  if (dataState.length > 0) {
    if (dataState[0].deleted)
      filterTypeResPre = { active: false, del: true, disable: false };
    else if (!dataState[0].userEnable)
      filterTypeResPre = { active: false, del: false, disable: true };
  }
  const [filterTypeRes, setFilterTypeRes] =
    useState<FilterTypeResType>(filterTypeResPre);
  const dispatch = useAppDispatch();
  const paySystemsList = useAppSelector(paySystemList);
  const langConstants = useAppSelector(languageConstants);
  const willMount = useRef(true);

  useEffect(() => {
    if (!willMount.current) {
      if (filterTypeRes.active && countsByFilterState)
        setCountForPagination(+countsByFilterState.numberActive);
      if (filterTypeRes.del && countsByFilterState)
        setCountForPagination(+countsByFilterState.numberDeleted);
      if (filterTypeRes.disable && countsByFilterState)
        setCountForPagination(+countsByFilterState.numberOff);
    }
  }, [countsByFilterState, filterTypeRes]);

  useEffect(() => {
    if (!willMount.current) {
      dispatch(changeFilterComponents(filterComponents));
      getMainData(filterComponents).then((res) => {
        if (res.data) setLoader(false);
      });
    }
  }, [filterComponents, dispatch, getMainData, setLoader]);

  const filterTypeActiveHandler = () => {
    setFilterComponents({ ...filterComponents, filterType: "1" });
    setFilterTypeRes({ active: true, del: false, disable: false });
    setCurrentPage(1);
  };
  const filterTypeDelHandler = () => {
    setFilterComponents({ ...filterComponents, filterType: "2" });
    setFilterTypeRes({ active: false, del: true, disable: false });
    setCurrentPage(1);
  };
  const filterTypeDisableHandler = () => {
    setFilterComponents({ ...filterComponents, filterType: "4" });
    setFilterTypeRes({ active: false, del: false, disable: true });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!willMount.current) {
      const start = (currentPage - 1) * countInPageRequisite.label;
      setFilterComponents((prefFilterComponents) => ({
        ...prefFilterComponents,
        start: start.toString(),
        length: countInPageRequisite.value,
      }));
    }
    willMount.current = false;
  }, [currentPage, countInPageRequisite]);

  return (
    <Page
      title={langConstants["L_MY_DETAILS_ANNOUNCEMENT"]}
      pagination
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      countInPage={countInPageRequisite}
      count={countForPagination}
    >
      <VerticalOffset
        offset="3m"
        breakpoints={{
          md: {
            offset: "4m",
          },
        }}
      >
        <PageRequisitesTabs>
          <BlockContent>
            <UpdateGlobalCommission />
          </BlockContent>
          <Tabs>
            <TabsHeaderSelect>
              <TabsList>
                <Tab
                  active={filterTypeRes ? filterTypeRes.active : true}
                  count={
                    countsByFilterState ? +countsByFilterState.numberActive : 0
                  }
                  tabOnClick={filterTypeActiveHandler}
                  countOn={true}
                >
                  {langConstants["L_UPD_ACTIVE"]}
                </Tab>
                <Tab
                  active={filterTypeRes ? filterTypeRes.disable : false}
                  count={
                    countsByFilterState ? +countsByFilterState.numberOff : 0
                  }
                  tabOnClick={filterTypeDisableHandler}
                  countOn={true}
                >
                  {langConstants["L_UPD_OFF"]}
                </Tab>
                <Tab
                  active={filterTypeRes ? filterTypeRes.del : false}
                  count={
                    countsByFilterState ? +countsByFilterState.numberDeleted : 0
                  }
                  tabOnClick={filterTypeDelHandler}
                  countOn={true}
                >
                  {langConstants["L_UPD_DELETED"]}
                </Tab>
              </TabsList>
            </TabsHeaderSelect>
            <PageRequisiteDropDown>
              <DropdownQuantity
                title={langConstants["L_SHOW_BY"] + ":"}
                options={dropDown}
                defaultValue={countInPageRequisite}
                page="Requisite"
              />
            </PageRequisiteDropDown>
            <TabPanel selected>
              <RequisiteHead />
              <RequisiteList>
                {dataState &&
                  dataState.map((el) => {
                    const system =
                      paySystemsList && paySystemsList[el.paymentSystemId];
                    if (system)
                      return (
                        <Requisite
                          key={el.id}
                          paySystem={system}
                          data={el}
                          getMainData={getMainData}
                        />
                      );
                    else return null;
                  })}
              </RequisiteList>
            </TabPanel>
            <TabPanel>{langConstants["L_UPD_OFF"]}</TabPanel>
            <TabPanel>{langConstants["L_UPD_DELETED"]}</TabPanel>
          </Tabs>
        </PageRequisitesTabs>
      </VerticalOffset>
    </Page>
  );
};
type enableDirectionProps = {
  operation: boolean;
  withdrawEnabled: boolean;
  depositEnabled: boolean;
  enabledHandler: () => void;
};

export const EnableDirection: React.FC<enableDirectionProps> = ({
  operation,
  withdrawEnabled,
  depositEnabled,
  enabledHandler,
}) => {
  const langConstants = useAppSelector(languageConstants);

  const active: boolean = operation ? withdrawEnabled : depositEnabled;
  const title: string = operation
    ? langConstants["L_PLACE_REQUISITES_ON_WITHDRAWAL"]
    : langConstants["L_PLACE_REQUISITES_ON_DEPOSIT"];
  return (
    <div className="page-requisites-comision__enable-direction">
      <BodyText>{title}</BodyText>
      {active ? (
        <ButtonIcon onClick={enabledHandler}>
          <IconCircleCheck />
        </ButtonIcon>
      ) : (
        <ButtonIcon onClick={enabledHandler}>
          <IconMenuCloseSmall />
        </ButtonIcon>
      )}
    </div>
  );
};
