import { AxiosResponse } from "axios";
import {api} from "../api";
import Services from "./Services";

type newsRequestType = {
    data: { news: newsMessageType[] }
    errorCode: null
    moveTo: null
    text: null
    type: string
}
export interface newsMessageType {
    createdAt: number
    id: number
    isOnlyForMerchants: boolean
    message: { en: string, ru: string }
    url: string
}

export default class NewsService {
    static async getNews(
        lang: "en" | "ru"
    ): Promise<AxiosResponse<newsRequestType>> {
        const url = Services.getURL("/api/account/news", lang)
        return api.get<newsRequestType>(url, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
    static async hideNews(
        id: string,
        lang: "en" | "ru"
    ): Promise<AxiosResponse<newsRequestType>> {
        const url = Services.getURL("/api/account/news-hide", lang)
        const dataURL = new FormData();
        dataURL.append("id", id)
        return api.post<newsRequestType>(url, dataURL, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
}