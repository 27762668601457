import React, { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DropdownQuantity } from "../../components/DropdownQuantity";
import { VerticalOffset } from "../../components/Grid/VerticalOffset";
import { HistoryTable, HistoryTableHeader, HistoryTableItem } from "../../components/HistoryTable";
import { HistoryTableOperations, HistoryTableOperationsItem } from "../../components/HistoryTableOperations";
import { Page } from "../../components/Page";
import { Switcher } from "../../components/UI/Switcher";
import { IHistory, IOperationHistory } from "../../models/IHistory";
import { IHistoryFilterComponents } from "../../models/IHistory";
import { IPaySystemShort } from "../../models/IPaySystem";
import History from "../../services/HistoryService";
import { PageHistoryOperationControl } from "./components/PageHistoryOperationControl";
import { PageHistoryOperationSearchOptions } from "./components/PageHistoryOperationSearchOptions";
import dropDown from "../../data/dropDown.json"
import { ParamContext } from "../../context/paramReducer";
import { languageConstants } from "../../features/settings/settingsSlice";
import { paySystemCurrency } from "../../features/requisit/paySystemsSlice";

type PageHistoryOperationProps = {
};

const initialFilterComponents: IHistoryFilterComponents = {
  draw: "1",
  start: "0",
  typesAll: "on",
  types: ["1", "2"],
  ordersGroupFiltersTypesAll: "on",
  ordersGroupFiltersTypes: ["4", "5", "6"],
  paymentsSystem: ["all"],
  updsAll: "on",
  upds: [],
  orderId: "",
  amountMin: "",
  amountMax: "",
  dateFrom: "",
  dateTo: "",
  client: "",
}

export const PageHistoryOperation: React.FC<PageHistoryOperationProps> = () => {
  const { lang, setLoader } = useContext(ParamContext)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [operation, setOperation] = useState(false)
  const [filterComponents, setFilterComponents] = useState<IHistoryFilterComponents>(initialFilterComponents)
  const switchHandler = () => setOperation(!operation)
  const [data, setData] = useState<IHistory[]>([])
  const [dataOperation, setDataOperation] = useState<IOperationHistory[]>()
  const [paySystem, setPaySystem] = useState<{ [index: string]: IPaySystemShort }>({})
  const currency = useAppSelector(paySystemCurrency)
  const [upds, setUpds] = useState<{ id: number, name: string, paymentSystemId: number }[]>([])
  const { countInPageHistory } = useContext(ParamContext)
  const [count, setCount] = useState(0)
  const dispatch = useAppDispatch()
  const langConstants = useAppSelector(languageConstants)
  const willMount = useRef(true)


  useEffect(() => {
    setLoader(true)
    if (!willMount.current) {
      setCurrentPage(1)
    }
    willMount.current = false
    return () => setLoader(false)
  }, [countInPageHistory, setLoader])

  useEffect(() => {
    if (!willMount.current) {
      const newStart = countInPageHistory.label * (currentPage - 1) + 1
      setFilterComponents(prefilter => ({ ...prefilter, start: newStart.toString() }))
    }
  }, [currentPage, countInPageHistory])

  useEffect(() => {
    setLoader(true)
    if (operation) {
      History.getOperationsData(filterComponents, countInPageHistory.value, lang)
        .then(res => {
          setCount(res.data.recordsTotal)
          setDataOperation(res.data.data)
          setLoader(false)
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      History.getData(filterComponents, countInPageHistory.value, lang)
        .then(res => {
          setData(res.data.data)
          setCount(res.data.recordsTotal)
          setPaySystem(res.data.paymentsSystemsData)
          setUpds(res.data.upds)
          setLoader(false)
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, [filterComponents, operation, countInPageHistory.value, dispatch, lang, setLoader])
  return (
    <Page title={langConstants["L_HISTORY"]} pagination currentPage={currentPage} setCurrentPage={setCurrentPage} countInPage={countInPageHistory} count={count}>
      <VerticalOffset offset="3m"
        breakpoints={{
          md: {
            offset: "4m"
          }
        }}
      >
        <PageHistoryOperationControl>
          <Switcher active={!operation} activeTwo={operation} onClick={switchHandler} firstButton={langConstants["L_HISTORY_OF_ORDERS"]} secondButton={langConstants["L_HISTORY_OF_OPERATIONS"]} />

          <DropdownQuantity
            title={langConstants["L_SHOW_BY"] + ":"}
            options={dropDown}
            defaultValue={countInPageHistory}
            page="History"
          />
        </PageHistoryOperationControl>
        {!operation &&
          <PageHistoryOperationSearchOptions filterState={filterComponents} setFilterState={setFilterComponents} paySystem={paySystem ? Object.values(paySystem) : []} setCurrentPage={setCurrentPage} upds={upds} setUpds={setUpds} />
        }

        {operation ?
          <HistoryTableOperations>
            {dataOperation && dataOperation.map(el => {
              const currencyItem = currency.find(item => item.id === el.currencyId)
              if (currencyItem) {
                return (
                  <HistoryTableOperationsItem key={el.id} item={el} currency={currencyItem} />)
              } else {
                return null
              }
            })}
          </HistoryTableOperations>
          :
          <HistoryTable>
            <HistoryTableHeader />
            {data && data.map(el => {
              const currencyItem = currency.find(item => item.id === el.order.currencyId)
              if (currencyItem) {
                return (
                  <HistoryTableItem
                    key={el.order.id}
                    item={el.order}
                    paySystem={paySystem && paySystem[el.order.paymentSystemId]}
                    currency={currencyItem}
                  />)
              } else {
                return null
              }
            })}
          </HistoryTable>
        }
      </VerticalOffset>
    </Page>
  )
};
