import React from "react";
import "./counter-round.scss";

type CounterRoundProps = {
  counter: number;
};

export const CounterRound: React.FC<CounterRoundProps> = ({
  counter,
}) => (
  <div className="counter-round">
    {counter}
  </div>
);