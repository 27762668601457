import React, { useCallback, useContext, useEffect, useState } from "react";
import "./chat.scss";
import { ChatList, ChatListItem } from "./components/ChatList";
import { ChatTextArea } from "./components/ChatTextArea";
import { ChatDocument } from "./components/ChatDocument";
import { ParamContext } from "../../context/paramReducer";
import Support from "../../services/SuppotrService";
import { IChatMessages } from "../../models/ISupport";
import Services from "../../services/Services";

type ChatProps = {
  idChat: number
};

export const ChatShort: React.FC<ChatProps> = ({ idChat }) => {
  const { lang } = useContext(ParamContext)
  const [files, setFiles] = useState<FileList>();
  const [messages, setMessages] = useState<{ [index: string]: IChatMessages[] }>({})

  const getChatMessages = useCallback((id: string) => {
    Support.getChatMessages(20, 0, "true", "10", id, "true", "", "", lang)
      .then(res => {
        setMessages(Services.updateMassages(res.data.data))
      })
  }, [lang])

  useEffect(() => {
    getChatMessages(`${idChat}`)
    const getMessageInterval = setInterval(() => {
      getChatMessages(`${idChat}`)
    }, 10000);
    return () => clearInterval(getMessageInterval)
  }, [getChatMessages, idChat])

  return (
    <div className="chat chat--short">
      <div className="chat__body">
        <div className="chat__body-wrapper">
          <div className="chat__body-list">
            {Object.keys(messages).map(el => {
              return (
                <ChatList date={el}>
                  {messages[el].map(item => {
                    const time = Services.getFormatShortData(item.date).time
                    return (
                      <ChatListItem
                        title={item.fromWhom}
                        message={item.text}
                        time={time}
                        messageId={item.id}
                        support={item.type === "admin" ? true : false}
                        user={item.type === "user" ? true : false}
                        files={item.files}
                      />
                    )
                  })}
                </ChatList>
              )
            })}
          </div>
          <div className="chat__actions">
            <ChatTextArea
              setFiles={setFiles}
              files={files}
              activeChat={`${idChat}`}
              getChatMessages={getChatMessages}
            />
            {files && Object.values(files).map(el =>
              <ChatDocument
                key={el.name}
                name={el.name}
                setFiles={setFiles}
                files={files}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
};
