import React, { useContext } from "react";
import "./dropdown-quantity.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { IconArrowChevronDown } from "../UI/Icon";
import { BodyText } from "../UI/Text";
import { ParamContext } from "../../context/paramReducer";


type DropdownQuantityProps = {
  title: string;
  options: React.ReactNode[];
  defaultValue: React.ReactNode;
  page: string
};
type countType = {
  value: string,
  label: number
}

export const DropdownQuantity: React.FC<DropdownQuantityProps> = ({
  title,
  options,
  defaultValue,
  page
}) => {
  const { setCountInPageRequisite, setCountInPageHistory, setCountInPageMyApplications } = useContext(ParamContext)
  const selectHandler = (e: countType) => {
    if (page === "History") {
      setCountInPageHistory(e)
    } else if (page === "Requisite") {
      setCountInPageRequisite(e)
    } else if (page === "Applications") {
      setCountInPageMyApplications(e)
    }
  }
  return (
    <div className="dropdown-quantity">
      <BodyText color="secondary">{title}</BodyText>
      <Select
        className="dropdown-quantity__body"
        options={options}
        defaultValue={defaultValue}
        classNamePrefix="dropdown-quantity"
        components={{ DropdownIndicator }}
        onChange={(value) => selectHandler(value as countType)}
      />
    </div>
  )
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => (
  <components.DropdownIndicator {...props}>
    <IconArrowChevronDown />
  </components.DropdownIndicator>
)