import React, { useCallback, useEffect, useRef, useState } from "react";
import "./dropdown-actions.scss";
import classNames from "classnames";
import { ButtonFlatSecondary } from "../UI/Button";
import { IconMenu } from "../UI/Icon";
import useWindowDimensions from "../../hooks/useWindowDimensions";

type DropdownActionsProps = {
  children: React.ReactNode;
};

export const DropdownActions: React.FC<DropdownActionsProps> = ({
  children,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState("bottom")
  const { height } = useWindowDimensions();
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const calculateDropdownPosition = useCallback(() => {
    if (dropdownRef.current && dropdownContentRef.current) {
      const elementPosition = dropdownRef.current?.getBoundingClientRect()
      const elementContentPosition = dropdownContentRef.current?.getBoundingClientRect()
      const verticalPosition = (height || 0) - elementPosition.y - elementPosition.height;
      if (verticalPosition <= elementContentPosition.height) setPosition("top")
      else setPosition("bottom")
    }
  }, [height]);

  useEffect(() => {
    calculateDropdownPosition();
    window.addEventListener("scroll", calculateDropdownPosition);
    return () => {
      window.removeEventListener("scroll", calculateDropdownPosition);
    };
  }, [calculateDropdownPosition]);


  useEffect(()=>{
    const callback = (ev: Event)=>{
      if (!dropdownRef.current){
        return
      }
      const target = ev.target as Node;
      if (!dropdownRef.current.contains(target)){
        setOpenDropdown(false)
      }
    }
    window.addEventListener("click", callback)
    return ()=>{
      window.removeEventListener("click", callback)
    }
  }, [])

  return (
    <div ref={dropdownRef} className="dropdown-actions">
      <div className="dropdown-actions__body">
        <ButtonFlatSecondary onClick={handleOpenDropdown}>
          <IconMenu />
        </ButtonFlatSecondary>
      </div>

      <div ref={dropdownContentRef} className={`dropdown-actions__dropdown ${classNames(
        openDropdown ? `dropdown-actions__dropdown--open` : "",
        `dropdown-actions__dropdown--${position}`
      )}`} >
        {children}
      </div>
    </div>
  )
};
