import "./update-global-commission.scss";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ParamContext } from "../../context/paramReducer";
import {
  changeGlobalPercentDepositP2P,
  changeGlobalPercentWithdrawP2P,
  depositEnabled,
  depWithEnableChange,
  paySystemGlobalPercentDepositP2P,
  paySystemGlobalPercentWithdrawP2P,
  withdrawEnabled,
} from "../../features/requisit/paySystemsSlice";
import { languageConstants } from "../../features/settings/settingsSlice";
import MyApplication from "../../services/MyApplicationService";
import { Button } from "../UI/Button/Button";
import { Input } from "../UI/Input";
import { ButtonText } from "../UI/Text";
import MyRequistes from "../../services/MyRequisitesService";

export const UpdateGlobalCommission: React.FC = () => {
  const { lang } = useContext(ParamContext);
  const dispatch = useAppDispatch();
  const langConstants = useAppSelector(languageConstants);
  const globalPercentDeposit = useAppSelector(paySystemGlobalPercentDepositP2P);
  const globalPercentWithdraw = useAppSelector(
    paySystemGlobalPercentWithdrawP2P
  );
  const depositEnabledStore = useAppSelector(depositEnabled);
  const withdrawEnabledStore = useAppSelector(withdrawEnabled);

  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [depositLoading, setDepositLoading] = useState(false);
  const [globalDeposit, setGlobalDeposit] = useState(
    globalPercentDeposit.toString()
  );
  const [globalWithdraw, setGlobalWithdraw] = useState(
    globalPercentWithdraw.toString()
  );
  const globalDepositHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^0-9.-]/g, "");
    setGlobalDeposit(newValue);
  };

  const globalWithdrawHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^0-9.-]/g, "");
    setGlobalWithdraw(newValue);
  };
  const updateCommission = (orderType: 1 | 2, percent: string) => {
    toast.promise(
      MyApplication.updateGlobalPercent(orderType, percent, lang).then(
        (res) => {
          if (res.data.type === "success") {
            if (orderType === 2)
              dispatch(changeGlobalPercentDepositP2P(+globalDeposit));
            else dispatch(changeGlobalPercentWithdrawP2P(+globalWithdraw));
            return res.data.text;
          } else {
            throw res.data.text;
          }
        }
      ),
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data;
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      }
    );
  };
  const updateDepositCommission = () => {
    updateCommission(2, globalDeposit);
  };
  const updateDepositWithdraw = () => {
    updateCommission(1, globalWithdraw);
  };

  const depositChangeEnable = () => {
    setDepositLoading(true);
    MyRequistes.changeEnable("2", lang).then((res) => {
      if (res.data.type === "success") {
        dispatch(depWithEnableChange(res.data.data));
        setDepositLoading(false);
      }
    });
  };
  const withdrawChangeEnable = () => {
    setWithdrawLoading(true);
    MyRequistes.changeEnable("1", lang).then((res) => {
      if (res.data.type === "success") {
        dispatch(depWithEnableChange(res.data.data));
        setWithdrawLoading(false);
      }
    });
  };
  return (
    <div className="update-global-commission">
      <div className="update-global-commission__col">
        <div className="update-global-commission__group-item">
          <Input
            label={langConstants["L_GLOBAL_SELL_COMMISSION"]}
            name="input_name_glob_com_deposit"
            type="text"
            value={globalDeposit}
            onChange={globalDepositHandler}
            placeholder=""
          />
          <Button onClick={updateDepositCommission}>
            <ButtonText>{langConstants["L_SAVE"]}</ButtonText>
          </Button>
        </div>
      </div>
      <div className="update-global-commission__col">
        <div className="update-global-commission__group-item">
          <Input
            label={langConstants["L_GLOBAL_BUY_COMMISSION"]}
            name="input_name_glob_com_deposit"
            type="text"
            value={globalWithdraw}
            onChange={globalWithdrawHandler}
            placeholder=""
          />
          <Button onClick={updateDepositWithdraw}>
            <ButtonText>{langConstants["L_SAVE"]}</ButtonText>
          </Button>
        </div>
      </div>
      <div className="update-global-commission__col update-global-commission__col--full">
        <div className="update-global-commission__group-item">
          <Button
            variant={depositEnabledStore ? "secondary" : "danger"}
            disabled={depositLoading}
            onClick={depositChangeEnable}
          >
            {langConstants["L_PLACE_REQUISITES_ON_DEPOSIT"]}
          </Button>
          <Button
            variant={withdrawEnabledStore ? "secondary" : "danger"}
            disabled={withdrawLoading}
            onClick={withdrawChangeEnable}
          >
            {langConstants["L_PLACE_REQUISITES_ON_WITHDRAWAL"]}
          </Button>
        </div>
      </div>
    </div>
  );
};
