import React from "react";
import "./modal-body.scss";

type ModalBodyProps = {
  children: React.ReactNode;
};

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
}) => (
  <div className="modal-body">
    <div className="modal-body__content">
      {children}
    </div>
  </div>
);
