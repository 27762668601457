import React/*, { useContext } */from "react";
import "./chat-list.scss";
import classNames from "classnames";
import { TitleChat, BodyText, ButtonText } from "../../../UI/Text";
import { useAppSelector } from "../../../../app/hooks";
import { languageConstants } from "../../../../features/settings/settingsSlice";
import { ButtonFlat } from "../../../UI/Button";
import Support from "../../../../services/SuppotrService";
import { IconFileBlank } from "../../../UI/Icon";
/*import { toast } from "react-toastify";
import {ParamContext} from "../../../../context/paramReducer";*/

type ChatListProps = {
  date: string;
  children: React.ReactNode;
};
// eslint-disable-next-line
const linkRegex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))(.{0,})/;
// eslint-disable-next-line
const linkRegex2 = /^([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*))(.{0,})/

export const ChatList: React.FC<ChatListProps> = ({
  date,
  children,
}) => (
  <div className="chat-list">
    <div className="chat-list__title-date">
      <BodyText color="secondary">{date}</BodyText>
    </div>
    <ul className="chat-list__body">
      {children}
    </ul>
  </div>
);

type ChatListItemProps = {
  title: string;
  time: string;
  message: string;
  messageId: number;
  files: number[];
  support?: boolean;
  user?: boolean;
  updateLinkCallAnAssistant?: any;
  showLinkCallAnAssistantForMessageId?: any;
  botChatEnabled?: any;
  userMark?: any;
};


export const ChatListItem: React.FC<ChatListItemProps> = ({
  title,
  time,
  support = false,
  user = false,
  message,
  messageId,
  files,
  updateLinkCallAnAssistant,
  showLinkCallAnAssistantForMessageId = 0,
  botChatEnabled = false,
  userMark = false
}) => {
  const langConstants = useAppSelector(languageConstants)
  //const { lang } = useContext(ParamContext)

  const getDocument = (item: number) => {
    Support.getDoc(item, messageId)
      .then((res) => {
        let html = '';
        if (res.headers["content-type"] === 'application/pdf') {
          html = `<embed frameBorder="0" width="100%" height="100%" src="data:application/pdf;base64,${res.data}">`;
        } else {
          const image = new Image();
          image.src = `data:image/jpeg;base64,${res.data}`
          html = image.outerHTML;
        }

        const w = window.open("");
        if (w) {
          w.document.write(html);
          w.document.close();
        }
      })
  }

  /*const callAnAssistantRequestHandler = () => {
    toast.promise(Support.callAnAssistant(lang)
      .then(res => {
        if (res.data.type === "success") {
          if (updateLinkCallAnAssistant) {
            updateLinkCallAnAssistant(res.data.userMark);
          }
          return res.data.text
        } else {
          throw res.data.text
        }
      })
    ,
    {
      pending: langConstants["L_LOADING_DATA"],
      success: {
        render({ data }) {
          return data
        }
      },
      error: {
        render({ data }) {
          return data
        }
      }
    })
  }

  const isShowLinkCallAnAssistant = (messageId: number) => {
    return messageId === showLinkCallAnAssistantForMessageId
      && !userMark
      && botChatEnabled
  }

  const isShowLinkEnableBot = (messageId: number) => {
    return (messageId === showLinkCallAnAssistantForMessageId
      && userMark
      && botChatEnabled)
  }*/

  return (
    <li className={`chat-list__item ${classNames(
      support ? "chat-list__item--support" : "",
      user ? "chat-list__item--user" : "",
    )}`}>
      <div className="chat-list__date">
        <TitleChat color="secondary" >{title}</TitleChat>
      </div>
      <div className="chat-list__message">
        <BodyText>
        {message && message.split(" ").map((el) => {
              const firstResult = el.match(linkRegex);
              const secondResult = el.match(linkRegex2);
              if (firstResult && isNaN(Number(firstResult[1]))) {
                return [
                  <a
                    href={firstResult[1]}
                    target={"_blank"}
                    key={el}
                    rel="noreferrer"
                  >
                    {firstResult[1]}
                  </a>,
                  firstResult[2],
                  " ",
                ];
              }

              if (secondResult && isNaN(Number(secondResult[1]))) {
                return [
                  <a
                    href={`https://${secondResult[1]}`}
                    target={"_blank"}
                    key={el}
                    rel="noreferrer"
                  >
                    {secondResult[1]}
                  </a>,
                  secondResult[2],
                  " ",
                ];
              }
              return el + " ";
            })}
        </BodyText>
        {files.length > 0 &&
          <div className="chat-list__files-list">
            {files.map(el => {
              const getDoc = () => getDocument(el)
              return (
                <ButtonFlat key={el} onClick={getDoc}>
                  <IconFileBlank></IconFileBlank>
                  <ButtonText>{langConstants["L_FILE"]}</ButtonText>
                </ButtonFlat>
              )
            })}
          </div>}
        {/*isShowLinkCallAnAssistant(messageId) &&
          <a className="text text--size-body mark mark--primary" onClick={callAnAssistantRequestHandler}>
            {langConstants["L_CALL_AN_ASSISTANT"]}
          </a>*/}
        {/*isShowLinkEnableBot(messageId) &&
            <a className="text text--size-body mark mark--primary" onClick={callAnAssistantRequestHandler}>
              {langConstants["L_CHAT_WITH_BOT_HUB_ASSISTANT"]}
        </a>*/}
      </div>
      <div className="chat-list__time">
        <TitleChat color="secondary">{time}</TitleChat>
      </div>
    </li>
  )
};