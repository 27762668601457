import React from "react";
import "./logo.scss";
import ImgLogo from "../../assets/images/img-logo.svg";

type LogoProps = {
  img?: string;
};

export const Logo: React.FC<LogoProps> = ({ img = ImgLogo }) => {
  return (
    <a className="logo" href="/">
      <img src={img} alt="Matbea" />
    </a>
  );
};
