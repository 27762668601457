import React, { useContext } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ButtonBase, ButtonOutline } from "../../UI/Button";
import { ButtonText } from "../../UI/Text";
import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper"
import MyApplication from "../../../services/MyApplicationService"
import { toast } from "react-toastify";
import { languageConstants } from "../../../features/settings/settingsSlice";
import { ParamContext } from "../../../context/paramReducer";
import { FormModal, FormModalFooter } from "../../Form/FormModal";

type ModalPayActionProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
  id: string;
  getData: () => void;
};

export const ModalPayAction: React.FC<ModalPayActionProps> = ({ isOpen, size, id, getData }) => {
  const { onDismiss } = useContext(ModalContext);
  const { lang } = useContext(ParamContext)
  const langConstants = useAppSelector(languageConstants)

  const sendHandler = () => {
    onDismiss()
    toast.promise(MyApplication.actionToOrderPay(id, "pay", lang)
      .then(res => {
        if (res.data.type === "success") {
          getData()
          return res.data.text
        } else {
          throw res.data.text
        }

      })
      ,
      {
        pending: langConstants["L_LOADING_DATA"],
        success: {
          render({ data }) {
            return data
          }
        },
        error: {
          render({ data }) {
            return data
          }
        }
      })

  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      title={langConstants["L_CONFIRMATION"]}
      size={size}
    >
      <FormModal onSubmit={sendHandler}>
        <FormModalFooter>
          <ButtonOutline onClick={onDismiss}>
            <ButtonText>{langConstants["L_CANCEL"]}</ButtonText>
          </ButtonOutline>

          <ButtonBase type="submit">
            <ButtonText>{langConstants["L_PAYED"]}</ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper >
  )
};
