import React from "react";
import "./user-info-card.scss";
import { CardText, CardBoldText } from "../UI/Text";
import { Tooltips, Placement } from "../UI/Tooltips/";
import { IconCircleCheck, IconHelp } from "../UI/Icon";

type UserInfoCardProps = {
  children: React.ReactNode;
};

export const UserInfoCard: React.FC<UserInfoCardProps> = ({
  children,
}) => (
  <ul className="user-info-card" >
    {children}
  </ul>
);

type UserInfoCardItemProps = {
  name: string;
  value: string;
  tooltips?: {
    placement: Placement;
    content: string;
  };
  confirmed?: boolean;
};

export const UserInfoCardItem: React.FC<UserInfoCardItemProps> = ({
  name,
  value,
  tooltips,
  confirmed = false,
}) => (
  <li className="user-info-card__item" >
    <div className="user-info-card__name">
      <CardText color="secondary">{name}:</CardText>

      {tooltips &&
        <Tooltips
          placement={tooltips.placement}
          content={tooltips.content}
        >
          <IconHelp size="md" />
        </Tooltips>
      }
    </div>

    <div className="user-info-card__value">
      <CardBoldText>{value}</CardBoldText>
      {confirmed &&
        <IconCircleCheck />
      }
    </div>
  </li >
);
