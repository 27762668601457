import React, { useEffect, useState } from "react";

type TimerProps = {
  time: number;
  changeState: (e: boolean) => void
}
export const Timer: React.FC<TimerProps> = ({ time, changeState }) => {
  const [seconds, setSeconds] = useState(time)

  useEffect(() => {
    if (seconds <= 0) changeState(false)
  }, [seconds, changeState])

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(preSeconds => preSeconds - 1)
    }, 1000);
    return () => clearInterval(interval)
  }, [])

  return (
    <>{seconds}</>
  )
}