import React from "react";
import "./text.scss";
import classNames from "classnames";

type TextProps = {
  variant?: Variant;
  color?: Colors;
  children: React.ReactNode;
  tag?: Tag;
  title?: string;
};

type Tag = "H1" | "H2" | "H3" | "H4" | "H5" | "div" | "p" | "span";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "title-modal"
  | "sub-title-modal"
  | "button"
  | "menu"
  | "body-small"
  | "body"
  | "body-light"
  | "table"
  | "title-chat"
  | "chips"
  | "tab"
  | "label-input"
  | "notification-input"
  | "tooltip"
  | "checkbox-label"
  | "status"
  | "title-card"
  | "card"
  | "card-bold"
  | "number-state"

type Colors = "primary" | "secondary" | "white" | "red";

const Text: React.FC<TextProps> = ({ variant, color, tag = "p", children, title }) => (
  <>
    {tag === "H1" && (
      <h1
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : "",
        )}`}
        title={title ? title : ""}
      >
        {children}
      </h1>
    )}
    {tag === "H2" && (
      <h2
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </h2>
    )}
    {tag === "H3" && (
      <h3
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </h3>
    )}
    {tag === "H4" && (
      <h4
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </h4>
    )}
    {tag === "H5" && (
      <h5
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </h5>
    )}
    {tag === "div" && (
      <div
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </div>
    )}
    {tag === "p" && (
      <p
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </p>
    )}
    {tag === "span" && (
      <span
        className={`text ${classNames(
          variant ? `text--size-${variant}` : "",
          color ? `text--color-${color}` : ""
        )}`}
        title={title ? title : ""}
      >
        {children}
      </span>
    )}
  </>
);

type TextIProps = {
  color?: Colors;
  children: React.ReactNode;
  tag?: Tag;
  title?: string
};
type TextUIProps = {
  color?: Colors;
  children: React.ReactNode;
};


export const H1: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h1" color={color} tag="H1">
    {children}
  </Text>
);
export const H2: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h2" color={color} tag="H2">
    {children}
  </Text>
);
export const H3: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="h3" color={color} tag="H3">
    {children}
  </Text>
);
export const TitleModal: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="title-modal" color={color} tag="H2">
    {children}
  </Text>
);
export const SubTitleModal: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="sub-title-modal" color={color} tag="H3">
    {children}
  </Text>
);
export const ButtonText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="button" color={color} tag="span">
    {children}
  </Text>
);
export const MenuText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="menu" color={color} tag="p">
    {children}
  </Text>
);
export const BodySmallText: React.FC<TextIProps> = ({ color, children, tag, title }) => (
  <Text variant="body-small" color={color} tag={tag} title={title ? title : ""}>
    {children}
  </Text>
);
export const BodyText: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyLightText: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-light" color={color} tag={tag}>
    {children}
  </Text>
);
export const TableText: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="table" color={color} tag={tag}>
    {children}
  </Text>
);
export const TitleChat: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="title-chat" color={color} tag="H4">
    {children}
  </Text>
);
export const ChipsText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="chips" color={color} tag="span">
    {children}
  </Text>
);
export const TabText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="tab" color={color} tag="span">
    {children}
  </Text>
);
export const LabelInputText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="label-input" color={color} tag="span">
    {children}
  </Text>
);
export const NotificationInputText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="notification-input" color={color} tag="span">
    {children}
  </Text>
);
export const TooltipText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="tooltip" color={color} tag="p">
    {children}
  </Text>
);
export const CheckboxLabelText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="checkbox-label" color={color} tag="p">
    {children}
  </Text>
);
export const StatusText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="status" color={color} tag="span">
    {children}
  </Text>
);

export const NumberStateText: React.FC<TextUIProps> = ({ color, children }) => (
  <Text variant="number-state" color={color} tag="span">
    {children}
  </Text>
)

export const TitleCardText: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="title-card" color={color} tag="span">
    {children}
  </Text>
);
export const CardText: React.FC<TextIProps> = ({ color, children, tag = "p" }) => (
  <Text variant="card" color={color} tag={tag}>
    {children}
  </Text>
);
export const CardBoldText: React.FC<TextIProps> = ({ color, children, tag = "p" }) => (
  <Text variant="card-bold" color={color} tag={tag}>
    {children}
  </Text>
);