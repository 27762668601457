import { useState } from "react";
import { PageMain } from "./Pages";
import './App.css';
import { ParamContext } from "./context/paramReducer";
import ModalProvider from "./components/Modal/ModalProvider";

function App() {
  const [countInPageMyApplications, setCountInPageMyApplications] = useState({ value: "25", label: 25 })
  const [countInPageRequisite, setCountInPageRequisite] = useState({ value: "25", label: 25 })
  const [countInPageHistory, setCountInPageHistory] = useState({ value: "25", label: 25 })
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [lang, setLang] = useState<"ru"|"en">("ru")
  const [loader, setLoader] = useState(false)
  const [chatBarOpen, setChatBarOpen] = useState(false)

  return (
    <ParamContext.Provider value={{
      countInPageMyApplications,
      setCountInPageMyApplications,
      countInPageRequisite,
      setCountInPageRequisite,
      countInPageHistory,
      setCountInPageHistory,
      sideBarOpen,
      setSideBarOpen,
      lang,
      setLang,
      loader,
      setLoader,
      chatBarOpen, 
      setChatBarOpen
    }}>
      <ModalProvider>
        <PageMain />
      </ModalProvider>
    </ParamContext.Provider>
  );
}

export default App;
