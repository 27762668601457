import React from "react";
import "./form-modal.scss";
import classNames from "classnames";

type FormModalProps = {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  className?: string;
};

export const FormModal: React.FC<FormModalProps> = ({
  children,
  onSubmit,
  className,
}) => (
  <form className={`form-modal ${classNames(className)}`} onSubmit={onSubmit} action="" method="post">
    {children}
  </form>
);

type FormModalBodyProps = {
  children: React.ReactNode;
};

export const FormModalBody: React.FC<FormModalBodyProps> = ({
  children,
}) => (
  <div className="form-modal__body">
    {children}
  </div>
);

type FormModalFooterProps = {
  children: React.ReactNode;
};

export const FormModalFooter: React.FC<FormModalFooterProps> = ({
  children,
}) => (
  <div className="form-modal__footer">
    {children}
  </div>
);
