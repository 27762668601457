// import { useContext } from "react";
import { API_URL } from "../../../api";
// import { useAppSelector } from "../../../app/hooks";
// import { languageConstants } from "../../../features/settings/settingsSlice";
import { FormModal, FormModalBody, FormModalFooter } from "../../Form/FormModal";
import { ButtonBase } from "../../UI/Button";
import { BodyText, ButtonText } from "../../UI/Text";
// import { ModalContext } from "../ModalProvider";
import { ModalWrapper } from "../ModalWrapper"

type ModalRelocationProps = {
  isOpen: boolean;
  title: string;
  size: "base" | "sm";
}
export const ModalRelocation: React.FC<ModalRelocationProps> = ({ isOpen, title, size }) => {
  // const { onDismiss } = useContext(ModalContext);
  // const langConstants = useAppSelector(languageConstants)

  const relocationHandler = (e: React.FormEvent) => {
    e.preventDefault()
    window.location.href = (`${API_URL}`)
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      // onClose={onDismiss}
      title={title}
      size={size}
    >
      <FormModal className="modal-relocation" onSubmit={relocationHandler}>
        <FormModalBody>
          <div className="modal-relocation__content">
            <BodyText>Пользователь не авторизован</BodyText>
          </div>
        </FormModalBody>
        <FormModalFooter>
          <ButtonBase type="submit">
            <ButtonText>Авторизоваться</ButtonText>
          </ButtonBase>
        </FormModalFooter>
      </FormModal>
    </ModalWrapper>
  )
}