import { AxiosResponse } from "axios";
import {api} from "../api";
import Services from "./Services";

type getConstantsRequest = {
    message: {[index: string]: string},
    type: string,
    text: string
}

export default class Language {
    static async getConstants (lang: "en" | "ru"):Promise<AxiosResponse<getConstantsRequest>>{
        const url = Services.getURL("/api/front/get-language-constants",lang)
        return api.get<getConstantsRequest>(url, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          })
    }
}