import React from "react";
import "./list-info.scss";
import { SubTitleModal, CardText, CardBoldText } from "../UI/Text";

type ListInfoProps = {
  title?: string;
  children: React.ReactNode;
};

export const ListInfo: React.FC<ListInfoProps> = ({
  title,
  children,
}) => (
  <div className="list-info">
    {title &&
      <SubTitleModal>{title}</SubTitleModal>
    }
    <ul className="list-info__body">
      {children}
    </ul>
  </div>
);

type ListInfoItemProps = {
  name: string;
  value: string;
};

export const ListInfoItem: React.FC<ListInfoItemProps> = ({
  name,
  value,
}) => (
  <li className="list-info__item">
    <CardText color="secondary">{name}</CardText>
    <CardBoldText>{value}</CardBoldText>
  </li>
);
