import React from "react";
import classNames from "classnames";
import "./container.scss";

type ContainerProps = {
  children: React.ReactNode;
  size?: Size;
};

type Size = "fluid" | "md" | "lg" | "xl" | "xxl";

export const Container: React.FC<ContainerProps> = ({
  children,
  size,
}) => {

  return (
    <div className={`container ${classNames(
      size ? `container--${size}` : "",
    )}`}
    >
      {children}
    </div>
  );
};
