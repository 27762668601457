import React, { useEffect, useState } from "react";
import "./history-table.scss";
import { BodySmallText, ButtonText, TitleCardText } from "../UI/Text";
import { Status } from "../UI/Status";
import { CardText, CardBoldText } from "../UI/Text";
import { ButtonFlatSecondary } from "../UI/Button";
import { IconArrowCaretDown } from "../UI/Icon";
import { ModalHistoryRequisite } from "../Modal/ModalHistoryRequisite";
import useModal from "../../hooks/useModal";
import { IHistoryOrder } from "../../models/IHistory";
import { IPaySystemShort } from "../../models/IPaySystem";
import { ICurrency } from "../../models/ICurrency";
import Services from "../../services/Services";
import { useAppSelector } from "../../app/hooks";
import { languageConstants } from "../../features/settings/settingsSlice";
import { OperationState } from "../OperationState";

type HistoryTableProps = {
  children: React.ReactNode;
};


export const HistoryTable: React.FC<HistoryTableProps> = ({
  children,
}) => (
  <div className="history-table">
    {children}
  </div>
);

type HistoryTableHeaderProps = {

};

export const HistoryTableHeader: React.FC<HistoryTableHeaderProps> = () => {
  const langConstants = useAppSelector(languageConstants)

  return (
    <div className="history-table__header">
      <div className="history-table__header-item">
        <BodySmallText color="secondary">{langConstants["L_OPERATION_№"]}</BodySmallText>
      </div>

      <div className="history-table__header-item">
        <BodySmallText color="secondary">{langConstants["L_INFO"]}</BodySmallText>
      </div>

      <div className="history-table__header-item">
        <BodySmallText color="secondary">{langConstants["L_DATE"]}</BodySmallText>
      </div>

      <div className="history-table__header-item">
        <BodySmallText color="secondary">{langConstants["L_STATUS"]}</BodySmallText>
      </div>
    </div>
  )
};
type StatusType = "L_ORDER_STATUS_2" | "L_ORDER_STATUS_3" | "L_ORDER_STATUS_4" | "L_ORDER_STATUS_5" | "L_ORDER_STATUS_13" | "L_ORDER_STATUS_14";
// type statusType = {
//   value: "done" | "processing" | "cancel" | "checked";
//   name: "Завершена" | "Проверяется" | "Отменена" | "В обработке" | "В арбитраже" | "Обрабатывается платежной системой"
// }

// const objStatus: { [index: string]: statusType } = {
//   2: { value: "processing", name: "В обработке" },
//   3: { value: "done", name: "Завершена" },
//   4: { value: "processing", name: "В арбитраже" },
//   5: { value: "cancel", name: "Отменена" },
//   13: { value: "processing", name: "Проверяется" },
//   14: { value: "processing", name: "Обрабатывается платежной системой" },
// }

type HistoryTableItemProps = {
  item: IHistoryOrder;
  paySystem: IPaySystemShort;
  currency: ICurrency
};

export const HistoryTableItem: React.FC<HistoryTableItemProps> = ({
  item,
  paySystem,
  currency
}) => {
  const user = item.type === 1 ? item.withdrawUserId : item.depositUserId
  const [amount, setAmount] = useState<string>()
  const date = Services.getFormatData(item.timeUpdate)
  const langConstants = useAppSelector(languageConstants)
  const status: StatusType = `L_ORDER_STATUS_${item.status}`

  const totalComms = item.commsTrusted + item.comms;
  let totalCommission = Services.getCurrencyFormat(Math.abs(totalComms), currency.prec)
  const totalPercent = item.commissionsSettings.hasOwnProperty('totalPercent') ? item.commissionsSettings.totalPercent : null;
  const totalCommsResult = { info: totalCommission + " " + currency.shortName, status: langConstants["L_COMMISSION"] }
  if (totalComms < 0) totalCommsResult.status = langConstants["L_OPERATION_BONUS"]
  if (totalPercent) totalCommsResult.info = Math.abs(totalPercent) + "% (" + totalCommission + " " + currency.shortName + ")"


  useEffect(() => {
    if (paySystem && currency && item) {
      const stringAmount = `${item.amount} ${currency.shortName}, ${paySystem.name}`
      setAmount(stringAmount)
    }
  }, [item, currency, paySystem])

  const [requisiteModalOpen] = useModal(
    (
      <ModalHistoryRequisite
        isOpen={false}
        data={item}
        title={langConstants["L_DETAILS"]}
        size="sm"
      />
    ), true, false);

  const openModal = () => requisiteModalOpen()
  return (

    <div className="history-table__item">
      <OperationState state={item.type}>
        <div className="history-table__item-list">
          <div className="history-table__item-list-item">
            <TitleCardText>{item.id}</TitleCardText>
          </div>

          <div className="history-table__item-list-item">
            <div className="history-table__info">
              <div className="history-table__info-item">
                <CardText color="secondary">{langConstants["L_CLIENT"] + ":"}</CardText>
                <CardBoldText>{user}</CardBoldText>
              </div>

              <div className="history-table__info-item">
                <CardText color="secondary">{langConstants["L_AMOUNT"] + ":"}</CardText>
                <CardBoldText>{amount}</CardBoldText>
              </div>

              <div className="history-table__info-item">
                <CardText color="secondary">{totalCommsResult.status + ":"}</CardText>
                <CardBoldText>{totalCommsResult.info}</CardBoldText>
              </div>

              <div className="history-table__info-button history-table__info-button--desctop">
                <ButtonFlatSecondary onClick={openModal}>
                  <ButtonText>{langConstants["L_SHOW_ESSENTIAL_ELEMENTS"]}</ButtonText>
                  <IconArrowCaretDown />
                </ButtonFlatSecondary>
              </div>
            </div>
          </div>

          <div className="history-table__item-list-item">
            <div className="history-table__item-title">
              <CardText color="secondary">{langConstants["L_DATE"] + ":"}</CardText>
            </div>
            <div className="history-table__info-item">
              <CardBoldText>{date.time}</CardBoldText>
              <CardBoldText>{date.day}</CardBoldText>
            </div>
          </div>

          <div className="history-table__item-list-item">
            <div className="history-table__item-title">
              <CardText color="secondary">{langConstants["L_STATUS"] + ":"}</CardText>
            </div>
            <Status status={status}>{langConstants[status]}</Status>
          </div>
        </div>

        <div className="history-table__info-button history-table__info-button--mob">
          <ButtonFlatSecondary onClick={openModal}>
            <ButtonText>{langConstants["L_DETAILS"]}</ButtonText>
            <IconArrowCaretDown />
          </ButtonFlatSecondary>
        </div>
      </OperationState>
    </div>
  )
};
