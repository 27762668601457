import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type languageRequestType = {
    langConstants: {[index: string]: string},
    baseCountry: number,
}
const initialState: languageRequestType = {
    langConstants: {},
    baseCountry: 181
}
export const SettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        updateConstants: (state, action) => {
            if(state.langConstants["L_LANG"] !== action.payload["L_LANG"]){
                state.langConstants = action.payload
            }
        },
        updateCountry: (state, action) => {
            state.baseCountry = action.payload
        }
    }
})

export const {updateConstants, updateCountry} = SettingsSlice.actions

export const languageConstants = (state: RootState) => state.setting.langConstants
export const baseCountry = (state: RootState) => state.setting.baseCountry

export default SettingsSlice.reducer