import { useState } from "react";
import { IconCalendar } from "../Icon";
import Calendar from "react-calendar";
import Services from "../../../services/Services";
import { Input } from "../Input/Input";
import { ButtonFlat } from "../Button";
import "./input-calendar.scss"

type InputCalendarProps = {
  name: string;
  type: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  error?: boolean;
  maxLength?: number;
  notification?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStr: (e: string) => void;
  required?: boolean,
  maxDate?: Date
};

export const InputCalendar: React.FC<InputCalendarProps> = ({
  name,
  value = "",
  placeholder = "Input Text",
  disabled = false,
  label,
  error = false,
  maxLength,
  notification,
  onChange,
  onChangeStr,
  required,
  maxDate
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [lengthBefore, setLengthBefore] = useState(0)

  const openHandler = () => {
    setOpen(!open)
  }

  const onChangeInput = (arg: React.ChangeEvent<HTMLInputElement>) => {
      const caret = arg.target.selectionStart
      const element = arg.target
      window.requestAnimationFrame(() => {
        if (caret && (caret === 3 || caret === 6) && arg.target.value.length > lengthBefore) {
          element.selectionStart = caret + 1
          element.selectionEnd = caret + 1
        } else {
          element.selectionStart = caret
          element.selectionEnd = caret
        }
      })
      arg.target.value = Services.getDataFormatFromStr(arg.target.value)
      setLengthBefore(arg.target.value.length)
      onChange(arg)
  }

  const changeData = (arg: Date) => {
    onChangeStr(Services.getDataForSearch(arg))
    setOpen(false)
  }
  return (
    <div className={"input-calendar"}>
      <Input
        type="text"
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        required={required}
        onChange={onChangeInput}
        error={error}
        notification={notification}
        icon={<ButtonFlat onClick={openHandler}><IconCalendar /></ButtonFlat>}
      />
      {open &&
        <div className="input-calendar__show">
          <Calendar
            className="calendar-custom__calendar"
            onChange={changeData}
            maxDate={maxDate}
            minDate={new Date("1900-01-01")}
          />
        </div>
      }
    </div >
  )
}