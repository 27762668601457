import React, { useCallback, useContext, useEffect, useState } from "react";
import { VerticalOffset } from "../../components/Grid/VerticalOffset";
import { Page } from "../../components/Page";
import { ReportTable } from "../../components/ReportTable";
import { IDateSearch } from "../../models/IGlobe";
import Report from "../../services/ReportService";
import { PageReportsInfo } from "./components/PageReportsInfo";
import Services from "../../services/Services"
import { IReports } from "../../models/IReport";
import { ButtonFlat } from "../../components/UI/Button";
import { IconSlider } from "../../components/UI/Icon";
import { ButtonText } from "../../components/UI/Text";
import { useAppSelector } from "../../app/hooks";
import { languageConstants } from "../../features/settings/settingsSlice";
import { ParamContext } from "../../context/paramReducer";
import { PageReportsOptions } from "./components/PageReportsOptions";

type PageReportsProps = {
};
type reportsKey = "sumDeposit" | "sumCommsTrustedDeposit" | "sumWithdraw" | "sumCommsTrustedWithdraw"

function totalForField(arr: IReports[], fieldName: reportsKey) {
  const answer = arr.map(item => item[fieldName]).reduce((prev, curr) => prev + curr, 0)
  return answer.toString()
}

export const PageReports: React.FC<PageReportsProps> = () => {
  const { lang, setLoader, loader } = useContext(ParamContext)
  const [data, setData] = useState([{}])
  const [total, setTotal] = useState({ sumDeposit: "0", sumCommsTrustedDeposit: "0", sumWithdraw: "0", sumCommsTrustedWithdraw: "0" })
  const [open, setOpen] = useState<boolean>(false)
  const langConstants = useAppSelector(languageConstants)

  useEffect(() => {
    setLoader(true)
    return () => { setLoader(false) }
  }, [setLoader])

  const getData = useCallback((paySystems: string[], checkNames: string[], date: IDateSearch, allPaySystems: boolean, langConstantsInto: { [index: string]: string }) => {
    setLoader(true)
    Report.getData(paySystems, checkNames, date, allPaySystems, lang)
      .then(res => {
        const sumDeposit = totalForField(res.data.data, "sumDeposit")
        const sumCommsTrustedDeposit = totalForField(res.data.data, "sumCommsTrustedDeposit")
        const sumWithdraw = totalForField(res.data.data, "sumWithdraw")
        const sumCommsTrustedWithdraw = totalForField(res.data.data, "sumCommsTrustedWithdraw")
        setTotal({ sumDeposit, sumCommsTrustedDeposit, sumWithdraw, sumCommsTrustedWithdraw })
        const newData = []
        for (const el of res.data.data) {
          const objItem = {
            itemsTd: [
              {
                title: langConstantsInto["L_DATE"] + ":",
                value: Services.getFormatData(el.time).day
              },
              {
                title: langConstantsInto["L_TRANSACTION_TYPE_1"] + " (" + langConstantsInto["L_USER_REPORT_AMOUNT"] + "):",
                value: el.sumDeposit.toString()
              },
              {
                title: langConstantsInto["L_TRANSACTION_TYPE_1"] + " (" + langConstantsInto["L_PROFIT"] + "):",
                value: el.sumCommsTrustedDeposit.toString()
              },
              {
                title: langConstantsInto["L_TRANSACTION_TYPE_2"] + " (" + langConstantsInto["L_USER_REPORT_AMOUNT"] + "):",
                value: el.sumWithdraw.toString()
              },
              {
                title: langConstantsInto["L_TRANSACTION_TYPE_2"] + " (" + langConstantsInto["L_PROFIT"] + "):",
                value: el.sumCommsTrustedWithdraw.toString()
              },
            ]
          }
          newData.push(objItem)
        }
        setData(newData)
        setLoader(false)
      })
  }, [setLoader, lang])

  const onOpen = () => {
    setOpen(!open)
  }
  return (
    <Page currentPage={1} title={langConstants["L_REPORT"]} countInPage={{ value: "10", label: 10 }}>
      <VerticalOffset offset="3m"  >
        <PageReportsOptions>
          <ButtonFlat onClick={onOpen}>
            <IconSlider />
            <ButtonText>{langConstants["L_FIND_PARAMS"]}</ButtonText>
          </ButtonFlat>
        </PageReportsOptions>

        <PageReportsInfo getData={getData} open={open} onOpen={onOpen} />

        {!loader &&
          <ReportTable
            tHead={[
              langConstants["L_DATE"],
              langConstants["L_TRANSACTION_TYPE_1"] + " (" + langConstants["L_USER_REPORT_AMOUNT"] + ")",
              langConstants["L_TRANSACTION_TYPE_1"] + " (" + langConstants["L_PROFIT"] + ")",
              langConstants["L_TRANSACTION_TYPE_2"] + " (" + langConstants["L_USER_REPORT_AMOUNT"] + ")",
              langConstants["L_TRANSACTION_TYPE_2"] + " (" + langConstants["L_PROFIT"] + ")"
            ]}
            tBody={data}
            tBottom={[
              {
                title: langConstants["L_TOTAL"],
              },
              {
                title: langConstants["L_TRANSACTION_TYPE_1"] + " (" + langConstants["L_USER_REPORT_AMOUNT"] + ")",
                value: Services.getCurrencyFormat(+total.sumDeposit, 2),
              },
              {
                title: langConstants["L_TRANSACTION_TYPE_1"] + " (" + langConstants["L_PROFIT"] + ")",
                value: Services.getCurrencyFormat(+total.sumCommsTrustedDeposit, 2),
              },
              {
                title: langConstants["L_TRANSACTION_TYPE_2"] + " (" + langConstants["L_USER_REPORT_AMOUNT"] + ")",
                value: Services.getCurrencyFormat(+total.sumWithdraw, 2),
              },
              {
                title: langConstants["L_TRANSACTION_TYPE_2"] + " (" + langConstants["L_PROFIT"] + ")",
                value: Services.getCurrencyFormat(+total.sumCommsTrustedWithdraw, 2),
              },
            ]}
            tTotal={[{ title: langConstants["L_YOUR_TOTAL_INCOME"] + ": " }, { value: Services.getCurrencyFormat(+total.sumCommsTrustedDeposit + +total.sumCommsTrustedWithdraw, 2) }]}
          />
        }
      </VerticalOffset>
    </Page>
  )
};