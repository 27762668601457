import React from "react";
import "./llst-form.scss";
import { SubTitleModal } from "../UI/Text";

type ListFormProps = {
  title?: string;
  children: React.ReactNode;
};

export const ListForm: React.FC<ListFormProps> = ({
  title,
  children,
}) => (
  <div className="llst-form">
    {title &&
      <div className="llst-form__title">
        <SubTitleModal>{title}</SubTitleModal>
      </div>
    }
    {children}
  </div>
);

type ListFormItemProps = {
  children: React.ReactNode;
};

export const ListFormItem: React.FC<ListFormItemProps> = ({
  children,
}) => (
  <div className="llst-form__item">
    {children}
  </div>
);