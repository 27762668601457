import { AxiosResponse } from "axios";
import {api} from "../api";
import { IHistoryFilterComponents, IHistoryGlobeRequest, IHistoryOperationsRequest } from "../models/IHistory";
import Services from "./Services";


export default class History {
  static async getData(
    filterComponents: IHistoryFilterComponents,
    length: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IHistoryGlobeRequest>> {
    const url = Services.getURL("/api/market/ajax-orders-history-pagination",lang)
    const dataURL = new FormData();
    dataURL.append("draw", filterComponents.draw)
    dataURL.append("start", filterComponents.start)
    dataURL.append("length", length)
    if (filterComponents.typesAll) dataURL.append("typesAll", filterComponents.typesAll)
    for (const el of filterComponents.types) {
      dataURL.append("types[]", el)
    }
    if (filterComponents.ordersGroupFiltersTypesAll) dataURL.append("ordersGroupFiltersTypesAll", filterComponents.ordersGroupFiltersTypesAll)
    for (const el of filterComponents.ordersGroupFiltersTypes) {
      dataURL.append("ordersGroupFiltersTypes[]", el)
    }
    for (const el of filterComponents.paymentsSystem) {
      dataURL.append("paymentsSystem", el)
    }
    if (filterComponents.updsAll) dataURL.append("updsAll", filterComponents.updsAll)
    if (filterComponents.upds && filterComponents.upds.length > 0) {
      for (const el of filterComponents.upds) {
        dataURL.append("upds[]", el)
      }
    }
    if (filterComponents.orderId) dataURL.append("orderId", filterComponents.orderId)
    if (filterComponents.amountMin) dataURL.append("amountMin", filterComponents.amountMin)
    if (filterComponents.amountMax) dataURL.append("amountMax", filterComponents.amountMax)
    if (filterComponents.dateFrom) dataURL.append("dateFrom", filterComponents.dateFrom)
    if (filterComponents.dateTo) dataURL.append("dateTo", filterComponents.dateTo)
    if (filterComponents.client) dataURL.append("client", filterComponents.client)
    return api.post<IHistoryGlobeRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // "access-token": token
      }
    })
  };
  static async getOperationsData(
    filterComponents: IHistoryFilterComponents,
    length: string,
    lang: "en" | "ru"
  ): Promise<AxiosResponse<IHistoryOperationsRequest>> {
    const url = Services.getURL("/api/account/ajax-history-pagination",lang)
    const dataURL = new FormData();
    dataURL.append("start", filterComponents.start)
    dataURL.append("length", length)
    dataURL.append("ajax_data_tables", "false")
    return api.post<IHistoryOperationsRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // "access-token": token
      }
    })
  };
  static async cancelOperation(
    id: number,
    lang: "en" | "ru"
  ): Promise<AxiosResponse> {
    const url = Services.getURL("/api/account/tran-to-cancel",lang)
    const dataURL = new FormData();
    dataURL.append("id", id.toString())
    return api.post<IHistoryOperationsRequest>(url, dataURL, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // "access-token": token
      }
    })
  }
}